/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  NewsPaperSearchCitiesbystateidData,
  NewsPaperSearchCountriesData,
  NewsPaperSearchGetpubtitlebypubtitleurlData,
  NewsPaperSearchNewspaperandpubcountData,
  NewsPaperSearchNewspaperandpubcountParams,
  NewsPaperSearchNewspapersearchData,
  NewsPaperSearchNewspapersearchParams,
  NewsPaperSearchPublicationandpagecountData,
  NewsPaperSearchPublicationdatesData,
  NewsPaperSearchPublicationlocationsData,
  NewsPaperSearchPublicationlocationsParams,
  NewsPaperSearchPublicationmonthsData,
  NewsPaperSearchPublicationyearsData,
  NewsPaperSearchPubtitlebycityidData,
  NewsPaperSearchReloadcitiesbystateidData,
  NewsPaperSearchReloadcountriesData,
  NewsPaperSearchReloadpublicationlocationsData,
  NewsPaperSearchReloadpubtitlebycityidData,
  NewsPaperSearchReloadstatebycountryidData,
  NewsPaperSearchRequiredSubscriptionTypesData,
  NewsPaperSearchStatebycountryidData,
  NewsPaperSearchYearsrangebylocationData,
  NewsPaperSearchYearsrangebylocationParams
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class NewsPaperSearch extends HttpClient {
  /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchCountries
   * @request GET:/api/NewsPaperSearch/countries
   * @secure
   */
  newsPaperSearchCountries = (params: RequestParams = {}) =>
    this.request<NewsPaperSearchCountriesData, any>({
      path: `/api/NewsPaperSearch/countries`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchStatebycountryid
   * @request GET:/api/NewsPaperSearch/statebycountryid/{countryId}
   * @secure
   */
  newsPaperSearchStatebycountryid = (countryId: number, params: RequestParams = {}) =>
    this.request<NewsPaperSearchStatebycountryidData, any>({
      path: `/api/NewsPaperSearch/statebycountryid/${countryId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchCitiesbystateid
   * @request GET:/api/NewsPaperSearch/citiesbystateid/{stateId}
   * @secure
   */
  newsPaperSearchCitiesbystateid = (stateId: number, params: RequestParams = {}) =>
    this.request<NewsPaperSearchCitiesbystateidData, any>({
      path: `/api/NewsPaperSearch/citiesbystateid/${stateId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchPubtitlebycityid
   * @request GET:/api/NewsPaperSearch/pubtitlebycityid/{stateId}/{cityId}
   * @secure
   */
  newsPaperSearchPubtitlebycityid = (stateId: number, cityId: number, params: RequestParams = {}) =>
    this.request<NewsPaperSearchPubtitlebycityidData, any>({
      path: `/api/NewsPaperSearch/pubtitlebycityid/${stateId}/${cityId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchReloadcountries
   * @request GET:/api/NewsPaperSearch/reloadcountries
   * @secure
   */
  newsPaperSearchReloadcountries = (params: RequestParams = {}) =>
    this.request<NewsPaperSearchReloadcountriesData, any>({
      path: `/api/NewsPaperSearch/reloadcountries`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchReloadstatebycountryid
   * @request GET:/api/NewsPaperSearch/reloadstatebycountryid/{countryId}
   * @secure
   */
  newsPaperSearchReloadstatebycountryid = (countryId: number, params: RequestParams = {}) =>
    this.request<NewsPaperSearchReloadstatebycountryidData, any>({
      path: `/api/NewsPaperSearch/reloadstatebycountryid/${countryId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchReloadcitiesbystateid
   * @request GET:/api/NewsPaperSearch/reloadcitiesbystateid/{stateId}
   * @secure
   */
  newsPaperSearchReloadcitiesbystateid = (stateId: number, params: RequestParams = {}) =>
    this.request<NewsPaperSearchReloadcitiesbystateidData, any>({
      path: `/api/NewsPaperSearch/reloadcitiesbystateid/${stateId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchReloadpubtitlebycityid
   * @request GET:/api/NewsPaperSearch/reloadpubtitlebycityid/{stateId}/{cityId}
   * @secure
   */
  newsPaperSearchReloadpubtitlebycityid = (
    stateId: number,
    cityId: number,
    params: RequestParams = {}
  ) =>
    this.request<NewsPaperSearchReloadpubtitlebycityidData, any>({
      path: `/api/NewsPaperSearch/reloadpubtitlebycityid/${stateId}/${cityId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchNewspapersearch
   * @request GET:/api/NewsPaperSearch/newspapersearch
   * @secure
   */
  newsPaperSearchNewspapersearch = (
    query: NewsPaperSearchNewspapersearchParams,
    params: RequestParams = {}
  ) =>
    this.request<NewsPaperSearchNewspapersearchData, any>({
      path: `/api/NewsPaperSearch/newspapersearch`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchYearsrangebylocation
   * @request GET:/api/NewsPaperSearch/yearsrangebylocation
   * @secure
   */
  newsPaperSearchYearsrangebylocation = (
    query: NewsPaperSearchYearsrangebylocationParams,
    params: RequestParams = {}
  ) =>
    this.request<NewsPaperSearchYearsrangebylocationData, any>({
      path: `/api/NewsPaperSearch/yearsrangebylocation`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchNewspaperandpubcount
   * @request GET:/api/NewsPaperSearch/newspaperandpubcount
   * @secure
   */
  newsPaperSearchNewspaperandpubcount = (
    query: NewsPaperSearchNewspaperandpubcountParams,
    params: RequestParams = {}
  ) =>
    this.request<NewsPaperSearchNewspaperandpubcountData, any>({
      path: `/api/NewsPaperSearch/newspaperandpubcount`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchPublicationandpagecount
   * @request GET:/api/NewsPaperSearch/publicationandpagecount
   * @secure
   */
  newsPaperSearchPublicationandpagecount = (params: RequestParams = {}) =>
    this.request<NewsPaperSearchPublicationandpagecountData, any>({
      path: `/api/NewsPaperSearch/publicationandpagecount`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchPublicationyears
   * @request GET:/api/NewsPaperSearch/publicationyears/{pubId}
   * @secure
   */
  newsPaperSearchPublicationyears = (pubId: number, params: RequestParams = {}) =>
    this.request<NewsPaperSearchPublicationyearsData, any>({
      path: `/api/NewsPaperSearch/publicationyears/${pubId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchPublicationmonths
   * @request GET:/api/NewsPaperSearch/publicationmonths/{pubId}/{year}
   * @secure
   */
  newsPaperSearchPublicationmonths = (pubId: number, year: string, params: RequestParams = {}) =>
    this.request<NewsPaperSearchPublicationmonthsData, any>({
      path: `/api/NewsPaperSearch/publicationmonths/${pubId}/${year}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchPublicationdates
   * @request GET:/api/NewsPaperSearch/publicationdates/{pubId}/{year}/{month}
   * @secure
   */
  newsPaperSearchPublicationdates = (
    pubId: number,
    year: string,
    month: string,
    params: RequestParams = {}
  ) =>
    this.request<NewsPaperSearchPublicationdatesData, any>({
      path: `/api/NewsPaperSearch/publicationdates/${pubId}/${year}/${month}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchPublicationlocations
   * @request GET:/api/NewsPaperSearch/publicationlocations
   * @secure
   */
  newsPaperSearchPublicationlocations = (
    query: NewsPaperSearchPublicationlocationsParams,
    params: RequestParams = {}
  ) =>
    this.request<NewsPaperSearchPublicationlocationsData, any>({
      path: `/api/NewsPaperSearch/publicationlocations`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchReloadpublicationlocations
   * @request GET:/api/NewsPaperSearch/reloadpublicationlocations
   * @secure
   */
  newsPaperSearchReloadpublicationlocations = (params: RequestParams = {}) =>
    this.request<NewsPaperSearchReloadpublicationlocationsData, any>({
      path: `/api/NewsPaperSearch/reloadpublicationlocations`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchGetpubtitlebypubtitleurl
   * @request GET:/api/NewsPaperSearch/getpubtitlebypubtitleurl/{pubTitleUrl}
   * @secure
   */
  newsPaperSearchGetpubtitlebypubtitleurl = (pubTitleUrl: string, params: RequestParams = {}) =>
    this.request<NewsPaperSearchGetpubtitlebypubtitleurlData, any>({
      path: `/api/NewsPaperSearch/getpubtitlebypubtitleurl/${pubTitleUrl}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags NewsPaperSearch
   * @name NewsPaperSearchRequiredSubscriptionTypes
   * @request GET:/api/NewsPaperSearch/required-subscription-types/{imageId}
   * @secure
   */
  newsPaperSearchRequiredSubscriptionTypes = (imageId: string, params: RequestParams = {}) =>
    this.request<NewsPaperSearchRequiredSubscriptionTypesData, any>({
      path: `/api/NewsPaperSearch/required-subscription-types/${imageId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    });
}

import { REACT_APP_API, REACT_APP_API_BASEPATH_NEWSPAPER } from "utils/env";

const API_BASEPATH_NEWSPAPER = REACT_APP_API_BASEPATH_NEWSPAPER;
const STORIED_BASE_URL = REACT_APP_API;

export const collectionDropDown = `ContentCatalog`;

export const PLACEAUTHIRITY = `placeauthority/lookup`;
export const userTrees = () => `Users/trees`;
export const deleteTreeApi = (treeId: string) => `Trees/deletetree/${treeId}`;
export const treePeople = (treeId: string) => `Trees/${treeId}/listPeople`;
export const getAllPersons = `Users/listAllPeople`;
export const saveStoryApi = `story`;
export const editStoryApi = `Story/editstory`;
export const autoSavetory = `Story/autosave`;
export const saveStoryApiV2 = `story/createstoryV2`;
export const saveStoryV2 = `v2/Story/create`;
export const editStoryApiV2 = `Story/editstoryV2`;
export const editStoryV2 = `v2/Story/edit`;
export const autoSavetoryV2 = `Story/autosaveV2`;
export const UPLOADSTORYMEDIAURL = `Media/uploadstorymedia`;
export const UPLOADGOOGLEPHOTOSSTORYMEDIAURL = `Media/uploadgooglephotosforstory`;
export const UPLOADGOOGLEPHOTOSSCRAPBOOKMEDIAURL = `Media/uploadusermediafromgooglephotos`;

export const viewStory = ({ storyId }: { storyId: string }) => `Story/${storyId}`;
export const viewStoryCollection = ({ storyId }: { storyId: string }) =>
  `story/storyDetailForCollection/${storyId}`;
export const viewStoryforFeed = ({ storyId }: { storyId: string }) =>
  `story/storyDetailForFeed/${storyId}`;
export const viewStoryforGroupFeed = ({ storyId }: { storyId: string }) =>
  `story/storyDetailForGroupFeed/${storyId}`;
export const viewNonLoggedInStory = ({ storyId }: { storyId: string }) =>
  `story/previewstory/${storyId}`;
export const updateStoryIsLiked = ({ storyId, isLiked }: { storyId: string; isLiked: boolean }) =>
  `Story/${storyId}/${isLiked}`;
export const storylikespersons = ({
  storyId,
  pageNumber = 1,
  pageSize = 30
}: {
  storyId: string;
  pageNumber?: number;
  pageSize?: number;
}) => `Story/storylikesdetail/${storyId}/${pageNumber}/${pageSize}`;
export const getStories = ({
  personId,
  authorId,
  pageNumber = 1,
  pageSize = 10
}: {
  personId: string;
  authorId: string;
  pageNumber?: number;
  pageSize?: number;
}) =>
  `persons/${personId}/${pageNumber}/${pageSize}/PersonStories${
    authorId ? "?authorId=" + authorId : ""
  }`;
export const getMemberStories = ({
  personId,
  pageNumber = 1,
  pageSize = 10
}: {
  personId: string;
  pageNumber?: number;
  pageSize?: number;
}) => `Users/${personId}/${pageNumber}/${pageSize}/memberStories`;
export const getUserGroupsListApi = () => `Users/groups`;
export const getUserGroupsSponsoredApi = () => `Users/sponsoredGroups`;
export const deleteStoryPerson = `Story/removetaggedperson`;
export const deleteStory = (StoryId: string) => `Story/${StoryId}/delete`;
export const getLeftPanelDetails = ({
  personId,
  authorId
}: {
  personId: string;
  authorId: string;
}) =>
  `persons/${personId}/personstoriescountbycategories${authorId ? "?authorId=" + authorId : ""}`;
export const getLeftPanelDetailsOwner = () => `Users/authorstoriescountbycategories`;
export const getLeftPanelDetailsMember = ({ memberId }: { memberId: string }) =>
  `Users/${memberId}/memberStoriesCountByCategory`;
export const getLeftPanelDetailsGroup = ({ groupId }: { groupId: string }) =>
  `group/${groupId}/storyCategoriesWithCount`;
export const getLeftPanelDetailsGroupPerson = ({
  groupId,
  personId
}: {
  groupId: string;
  personId: string;
}) => `Group/${groupId}/${personId}/grouppersonstoriescategorieswithcount`;

export const getImmediateFamily = ({ treeId, personId }: { treeId: string; personId: string }) =>
  `Persons/immediatefamily?treeId=${treeId}&personId=${personId}`;
export const getHomepageStories = ({ pageNumber = 1, pageSize = 10 }) =>
  `users/stories/${pageNumber}/${pageSize}`;
export const getFeedpageStories = ({
  groupId,
  pageNumber = 1,
  pageSize = 10
}: {
  groupId: string;
  pageNumber?: number;
  pageSize?: number;
}) => `group/${groupId}/groupStories/${pageNumber}/${pageSize}`;
export const getFeedpageNonLoggedInStories = ({
  invitationId,
  pageNumber = 1,
  pageSize = 10
}: {
  invitationId: string;
  pageNumber?: number;
  pageSize?: number;
}) => `group/${invitationId}/nonLoggedInStories/${pageNumber}/${pageSize}`;
export const getTreeUserpageStories = ({ groupId, treeId }: { groupId: string; treeId: string }) =>
  `Trees/treePersonStories?groupId=${groupId}&treeId=${treeId}`;
export const getFeedPersonpageStories = ({
  groupId,
  personId,
  pageNumber = 1,
  pageSize = 10
}: {
  groupId: string;
  personId: string;
  pageNumber?: number;
  pageSize?: number;
}) => `Group/${groupId}/${personId}/${pageNumber}/${pageSize}/grouppersonstories`;
export const getOwnStories = ({ pageNumber = 1, pageSize = 10 }) =>
  `users/${pageNumber}/${pageSize}/authorstories`;
export const getStoriesCount = () => `Users/authorstoriescount `;
export const getStoriesCountWithStatus = (status: string) => `Users/storiescountbystatus/${status}`;
export const getTreesList = `Users/treesWithHomePersonAssigned?includePersonCount=false`;
export const getNewsPaperFreeViewsCount = () => `UserViews/newspaperfreeviewscount`;
export const registerUpdateNewsPaperView = (pageURL: string) =>
  `UserViews/registernewspaperview/${pageURL}`;
export const getTreesASYNC = () => `Users/trees`;
export const getRecentPeople = () => `Users/recentpeoplecard`;
export const getHintListApi = () => `Users/homepagehints`;
export const updateHintStatusApi = ({ hintId, status }: { hintId: string; status: string }) =>
  `hint/${hintId}/${status}`;
export const getNewspaperMatchesApi = (personId: string) => `Persons/${personId}/newspaper-matches`;
export const getRecentNewspaperMatchesApi = (userId: string, recordCount: number) =>
  `Users/newspaper-matches/recent?userId=${userId}&recordCount=${recordCount}`;
export const getMedia = ({ mediaId }: { mediaId: string }) => `media/${mediaId} `;
export const getMediaV2 = ({ mediaId, mediaType }: { mediaId: string; mediaType: string }) =>
  `v2/media?MediaId=${mediaId}&MediaType=${mediaType}`;
export const saveMediaApi = () => `media/uploadusermedia`;
export const getUserMediaListV2 = ({ page }: { page: number }) => `v2/Users/${page}/list-media`;
export const saveUserImageApi = `media/uploadusermedia`;
export const saveUserAudioApi = `media/upload-user-audio`;
export const saveUserPdfApi = `media/upload-user-pdf`;
export const checkMediaExistApi = () => `Users/checkmediaexist`;
export const getPersonMediaListV2 = ({
  page,
  personId,
  includeRecords
}: {
  page: number;
  personId: string;
  includeRecords: boolean;
}) => {
  const queryParams = new URLSearchParams({
    personId,
    ...(typeof includeRecords === "boolean" && { includeRecords: String(includeRecords) })
  });
  return `v2/Persons/${page}/person-media-list?${queryParams.toString()}`;
};
export const getSearchPersonMediaListV2 = ({
  page,
  personId
}: {
  page: number;
  personId: string;
}) => `v2/Users/${page}/search-person-media?personId=${personId}`;
export const getExternalMedia = ({ mediaId }: { mediaId: string }) =>
  `Media/naclippingdetail/${mediaId}`;
export const getNaClipMedia = ({ mediaId }: { mediaId: string }) => `Media/naclipdetail/${mediaId}`;
export const UPDATEMEDIAMETADATA = `media/updatemediametadata`;
export const getUserDetail = ({ userId }: { userId: string }) => `v2/Contributor/${userId}/detail`;
export const ADDPERSONTOMEDIA = `media/addpersonstomedia`;
export const ADDPERSONORPETMEDIA = `media/addpersonorpettomedia`;
export const REMOVEPERSONFROMMEDIA = `media/removepersonsfrommedia`;
export const getNotifications = `Users/notifications`;
export const getMemberInfo = (userid: string) => `Users/${userid}/memberDetail`;
export const getMemberInfoLOHP = ({
  memberId,
  invitationId
}: {
  memberId: string;
  invitationId: string;
}) => `Users/${invitationId}/${memberId}/nonLoggedInmemberDetail`;
export const MarkedRead = `User/marknotificationread`;
export const SubmitSubscriptionInfo = `User/subscriptioninfo`;
export const SUBMITCARDDETAILS = `User/subscribe`;
export const TAXAPIDETAILS = `Users/calculateTax`;
export const PERSONHINTAPI = ({ personId }: { personId: string }) =>
  `persons/${personId}/personhint`;
export const COUNTRY = `NewsPaperSearch/countries`;
export const getState = (countryId: string) => `NewsPaperSearch/statebycountryid/${countryId}`;
export const getCity = (stateId: string) => `NewsPaperSearch/citiesbystateid/${stateId}`;
export const getPublication = (cityId: string, stateId: string) =>
  `NewsPaperSearch/pubtitlebycityid/${stateId}/${cityId}`;
export const getADB2CUserInfo = (userid: string) => `Users/${userid}/authproviderinfo`;
export const getPublications = `NewsPaperSearch/newspapersearch`;
export const GetPubTitleByUrl = (url: string) => `NewsPaperSearch/getpubtitlebypubtitleurl/${url}`;
export const updateLastTimeUserSawNotification = `User/updatelasttimeusersawnotification`;
export const getFollowers = `Users/followers`;
export const getFollowings = `Users/following`;
export const setfollowUser = `User/follow`;
export const setUnfollowUser = `User/unfollow`;
export const getFollowUnfollowCount = `Users/followingfollowersCount`;
export const getFollowUnfollowDetail = `Users/followingstatusInfo`;
export const SEARCHPEOPLE = (
  reqId: string,
  page: number,
  str: string,
  isLiving: boolean,
  includePets: boolean,
  includePerson: boolean
) =>
  `users/typeahead/search/${reqId}/page/${page}/${str}?fetchOnlyLiving=${isLiving}&fetchAllPets=${includePets}&fetchAllPeople=${includePerson}`;
export const personInformation = (personId: string) => `Persons/${personId}/info`;
export const assignTopicToStory = ({ storyId, topicId }: { storyId: string; topicId: string }) =>
  `admin/Story/${storyId}/assign/${topicId}`;
export const removeTopicFromStory = ({ storyId, topicId }: { storyId: string; topicId: string }) =>
  `admin/Story/${storyId}/remove/${topicId}`;
export const getAdminStories = ({ pageNumber = 1, pageSize = 10 }) =>
  `admin/Story/allstories/${pageNumber}/${pageSize}`;
export const viewAdminStory = ({ storyId }: { storyId: string }) => `Story/${storyId}`;
export const getAdminStoriesCount = `admin/Story/adminstoriescount `;
export const GetTopics = () => `topic`;
export const GetStoriesByTopic = ({
  topicId,
  requestId,
  pageNumber = 1,
  pageSize = 10
}: {
  topicId: string;
  requestId: string;
  pageNumber?: number;
  pageSize?: number;
}) => `topic/${topicId}/stories/${requestId}/${pageNumber}/${pageSize}`;
export const GetPublishedTitleByGUID = (GUID: string) =>
  `${API_BASEPATH_NEWSPAPER}/getpublicationtitle/?topicGuid=${GUID}`;
export const followTopic = (topicId: string) => `user/followtopic/${topicId}`;
export const unFollowTopic = (topicId: string) => `user/unfollowtopic/${topicId}`;
export const getFollowedTopics = `users/followingtopics`;
export const getTopicbyId = (topicId: string) => `topic/id/${topicId}`;
export const milopreferences = `User/milopreferences`;
export const milopreferencesupdate = `User/milopreferencesupdate`;
export const ccpa = `User/ccpa`;
export const yearRange = `NewsPaperSearch/yearsrangebylocation`;
export const getComments = (pageNumber: number, pageSize: number, storyId: string) =>
  `Story/${storyId}/comments/${pageNumber}/${pageSize}`;
export const addComments = () => `Story/comment`;
export const countcomments = (storyId: string) => `Story/${storyId}/commentcount`;

export const publicationCount = `NewsPaperSearch/newspaperandpubcount`;
export const publicationYears = `NewsPaperSearch/publicationyears`;
export const publicationYearsMonth = `NewsPaperSearch/publicationmonths`;
export const publicationYearsMonthDate = `NewsPaperSearch/publicationdates`;
export const publicationLocationSearch = `NewsPaperSearch/publicationlocations`;

export const searchFlagNespaper = `Users/checkNewspaperAccess`;
export const searchFlagHistory = `Users/checkNewspaperAccess`;

export const CalculateRefundAmount = (planID: string, startDate: string) =>
  `Users/calculateRefund?planId=${planID}&subscriptionStartDate=${startDate}`;
export const ChangeSubscription = `User/ChangeSubscription`;
export const GetBillingInformation = (recurlyUuid: string) =>
  `Users/userSubscriptionDetails?recurlySubscriptionId=${recurlyUuid}`;
export const cancelSubscription = () => `User/CancelSubscription`;
export const UpdatePaymentCard = () => `User/UpdatePaymentCard`;

export const shareStoriesViaEmail = `Story/sharestory`;
export const shareStoryViewStatus = (invitationId: string, visitorId: string) =>
  `ShareStory/previewstatus?invitationId=${invitationId}&visitorId=${visitorId}`;
export const addToStoryPreviewers = `ShareStory/addpreviewer`;
export const assignUserToStory = (storyId: string) => `Story/${storyId}/addassociation`;
export const previewStoryDetails = (storyId: string) => `Story/previewstory/${storyId}`;
export const addEmailsToWhiteList = `Whitelist/AddToWhitelist`;
export const checkVisitorStoryPermission = (visitorId: string, storyId: string) =>
  `ShareStory/checkvisitorstorypermission?visitorId=${visitorId}&storyId=${storyId}`;
export const checkUserAssociation = (storyId: string) => `Story/${storyId}/checkuserassociation`;
export const viewStoryViaInvitation = (storyId: string) => `Story/viewstory/${storyId}`;
export const nonLoginUserDetails = (userID: string) => `Users/${userID}/detail`;
export const getGroupDetails = (groupUrl: string) => `group/${groupUrl} `;
export const CreateGroupApi = `Group`;
export const UpdateGroupApi = `Group/update`;
export const LeaveGroupApi = (groupId: string) => `group/leaveGroup?groupId=${groupId}`;
export const CancelGroupSponsorShipApi = (groupId: string) =>
  `group/cancelSponsorship?groupId=${groupId}`;
export const checkExistingGroup = (groupUrl: string) => `Group/checkUrlExistence/${groupUrl}`;
export const reportStory = `story/report`;
export const reportComment = `Story/reportcomment`;
export const reportMedia = `Media/reportmedia`;
export const reportStoryList = (page: number, perPage: number, decision: any) =>
  `admin/Story/reported/${page}/${perPage}?decision=${decision}`;
export const reportImagesList = (page: number, perPage: number, decision: any) =>
  `admin/Media/reported/${page}/${perPage}?decision=${decision}`;
export const completeAdminReviewAPI = `admin/Story/completeadminreview`;
export const completeAdminReviewCommentAPI = `admin/Story/completeAdminReviewForComment`;
export const completeAdminReviewMediaAPI = `admin/Media/completeadminreview`;
export const createInvite = `Group/invite`;
export const isGroupInvitationValid = (invitationId: string) =>
  `Group/checkInvitationExpiration?invitationId=${invitationId}`;
export const getGroupInfo = (groupUrl: string) => `Group/Info/${groupUrl}`;
export const getGroupUrl = (invitationId: string) => `Group/${invitationId}/getUrl`;
export const getGroupMembers = (groupId: string) => `Group/getMembers?groupId=${groupId}`;
export const getPendingInvitations = (groupId: string) => `Group/${groupId}/pendingInvitations`;
export const acceptGroupInviteApi = (inviteId: string) =>
  `Group/acceptinvite?invitationId=${inviteId}`;
export const acceptGroupJoinApi = (inviteId: string) => `Group/join?invitationId=${inviteId}`;
export const ClaimSponsorshipApi = (groupId: string) => `group/claimSponsorship?groupId=${groupId}`;
export const getUserRole = (groupId: string) => `Group/GetUserRole?groupId=${groupId}`;
export const adminViewStory = ({ storyId }: { storyId: string }) => `admin/Story/${storyId}`;
export const followUser = (userId: string) => `User/follow/${userId}`;
export const unfollowUser = (userId: string) => `User/unfollow/${userId}`;
export const RemoveFromGroupApi = ({ groupId, memberId }: { groupId: string; memberId: string }) =>
  `Group/removeMember?groupId=${groupId}&memberId=${memberId}`;
export const getGroupByIds = `Group/groupDetails`;
export const CheckGroupCreationRightsApi = () => `Users/checkGroupCreationRights`;
export const getPendingInvites = `Users/pendingGroupInvites`;
export const addRecentlyViewedGroup = (groupId: string) =>
  `user/add-recently-viewed-group/${groupId}`;
export const getInviterInfo = (inviteId: string) => `Group/getInviterInfo?invitationId=${inviteId}`;
export const GroupImportStoriesApi = ({ groupId, treeId }: { groupId: string; treeId: string }) =>
  `Group/importStories/${groupId}/${treeId}`;
export const GroupImportStoriesDismisApi = ({ groupId }: { groupId: string }) =>
  `Group/importStories/${groupId}/dismissPrompt`;
export const AddStoryCitationApi = () => `Story/citation`;
export const getGroupMediaListV2 = ({
  page,
  groupId,
  groupTreeId
}: {
  page: number;
  groupId: string;
  groupTreeId: string;
}) => `v2/Group/${groupId}/${groupTreeId}/${page}/group-media-list`;
export const saveGroupImageApi = `Media/uploadgroupimage`;
export const saveGroupAudioApi = `Media/upload-group-audio`;
export const saveGroupPdfApi = `Media/upload-group-pdf`;
export const savePersonImageApi = `Media/uploadpersonmedia`;
export const savePersonAudioApi = `Media/upload-person-audio`;
export const savePersonPdfApi = `Media/upload-person-pdf`;
export const getCommentListingAPI = (pageNum: number, pageLength: number) =>
  `admin/Story/reportedcomments/${pageNum}/${pageLength}`;
export const cancelInvite = (invitationId: string) =>
  `group/cancelInvite?invitationId=${invitationId}`;
export const dismissTopicPromptAPI = `user/dismissTopicPrompt`;
export const dismissStoryPromptAPI = `user/dismissStoryPrompt`;
export const dismissGroupPromptAPI = `user/dismissGroupPrompt`;
export const dismissSearchPromptAPI = `user/dismissSearchPrompt`;
export const deletePdfMedia = (mediaId: string) => `media/pdf/${mediaId}`;
export const deleteMedia = (mediaId: string) => `media/${mediaId}`;
export const deleteAudioMedia = (mediaId: string) => `media/audio/${mediaId}`;
export const dismissFreeTrialPromptAPI = `user/dismissFreeTrialPrompt`;
export const getStoryStartedTexts = `Story/storyPrompts`;
export const savePetIndividual = `Persons/pet`;
export const registerPetOwnership = `Persons/registerPetOwnership`;
export const getPet = (petId: string) => `Pet/${petId}`;
export const getPetRelationShip = (petId: string) => `Pet/petrelationships?petId=${petId}`;
export const deletePet = (ownerId: string, petId: string) =>
  `Persons/deletepet/${ownerId}/${petId}`;
export const getPersonName = (personId: string) => `Persons/${personId}/treePersonInfo`;
export const getRecordPersonInfo = (recordId: string) => `Persons/${recordId}/recordPersonInfo`;
export const getMergeRecord = (personId: string, recordId: string) =>
  `Persons/${personId}/${recordId}`;
export const getParentMergeRecord = (
  personId: string,
  recordId: string,
  parentRecordPersonId: string
) => `Persons/${personId}/${recordId}?parentRecordPersonId=${parentRecordPersonId}`;
export const saveMergeRecord = () => `Persons/saveRecordPersonMerge`;
export const getHintRecords = ({ primaryPersonId }: { primaryPersonId: string }) =>
  `persons/${primaryPersonId}/pendingignoredhints`;
export const getSavedHintRecords = ({ primaryPersonId }: { primaryPersonId: string }) =>
  `Persons/${primaryPersonId}/savedrecords`;
export const getStoryDescription = `Story/assistant`;
export const addPetToMedia = `media/addpettomedia`;
export const addPersonToAudio = `Media/attach-person-audio`;
export const addPersonToPdf = `Media/attach-person-pdf`;
export const addPetToAudio = `Media/attach-pet-audio`;
export const addPetToPdf = `Media/attach-pet-pdf`;
export const removePersonFromAudio = `Media/detach-person-audio`;
export const removePersonFromPdf = `Media/detach-person-pdf`;
export const removePetFromAudio = `Media/detach-pet-audio`;
export const removePetFromPdf = `Media/detach-pet-pdf`;
export const personAndRecordPersonAssociation = `Persons/personAndRecordPersonAssociation`;
export const getPetMediaListV2 = ({ page, petId }: { page: number; petId: string }) =>
  `v2/Pet/${page}/pet-media-list?petId=${petId}`;
export const savePetImageApi = `Media/uploadpetmedia`;
export const savePetAudioApi = `Media/upload-pet-audio`;
export const savePetPdfApi = `Media/upload-pet-pdf`;
export const getPetStoriesOnPetsPage = (petId: string) =>
  `Pet/${petId}/petstoriescountbycategories`;
export const checkAssociation = (postData: any) =>
  `persons/${postData?.personId}/checkAssociation/${postData.recordId}/${postData.partitionKey}`;
export const getPetStories = ({
  petId,
  pageNumber = 1,
  pageSize = 10
}: {
  petId: string;
  pageNumber?: number;
  pageSize?: number;
}) => `Pet/${petId}/${pageNumber}/${pageSize}/PetStories`;
export const getStoryViaShareableLink = (shareableID: string) =>
  `ShareStory/storysharelinkinfobylinkid?linkId=${shareableID}`;
export const REMOVEPETFROMMEDIA = `media/removepetsfrommedia`;
// print book
export const GRANTUSERCOUPON = "UserCoupon/grant";
export const GETUSERCOUPONSTATUS = "Users/couponstatus";
export const GETPRINTBOOKSTORY = "Users/StoryListForBook";
export const GETSTORYBOOKINFO = (bookId: string) => `StoryBook/${bookId}`;
export const ORDERADDITIONALBOOK = "StoryBook/order-additional";
export const CREATESTORYBOOK = "StoryBook/createStoryBook";
export const UPDATESTORYBOOK = (bookId: string) => `StoryBook/${bookId}/updateStories`;
export const GETSELECTEDSTORIES = (bookId: string) => `StoryBook/${bookId}/selectedStoriesForBook`;
export const saveReorderStory = (bookId: string) => `StoryBook/${bookId}/setStoriesOrder`;
export const updateStoryBookCoverDetails = () => `StoryBook/savestorybookcoverdetails`;
export const GETPRINTBOOKLIST = "Users/booklist";
export const ORDERPRINTBOOK = "PrintOrder/createorder";
export const HINT_GENERATION = "HintGeneration/start";
export const TRYGRANTCOUPON = "usercoupon/grant";

// QR codes
const GENERATE_QRCODE_IMAGE = "qrcode/png";
export const generateQRCodeImage = (target: string) =>
  `${STORIED_BASE_URL}/api/${GENERATE_QRCODE_IMAGE}?target=${target}`;

// Public redirect
export const PUBLIC_REDIRECT = "rd/r";
export const getPublicRedirect = (target: string) =>
  `${STORIED_BASE_URL}/api/${PUBLIC_REDIRECT}?url=${target}`;

import { Suspense, useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

//Components
import UnAuthorizedHeader from "components/Header/LoggedOutHeader";
import Loader from "components/Loader";
import SnackbarComponent from "components/Snackbar";

//Pages
import App from "pages/MainEntry/App";
import { publicRoutes } from "pages/Routes";
import { ResourcesPage } from "pages/Routes/components/publicPages";

//Hooks
import { useLoggedOutHeader } from "components/Header/hooks/useLoggedOutHeader";
import useInit from "pages/MainEntry/hooks/useInit";

//Services
import SearchFooter from "components/SearchFooter";
import useAuthUtils from "hooks/useAuthUtils";
import RedirectToLogin from "pages/RedirectToLogin";
import { getSearchRoutes } from "pages/Routes/routes/searchRoutes";
import { removeCookies } from "services";

function PublicRoutes({ isLoading, isAccessToken }) {
  const { handleSignUpAuth, handleSignInAuth } = useLoggedOutHeader();

  if (isLoading) {
    return (
        <Loader />
    );
  }
  return (
    <>
      {!isAccessToken && (
          <UnAuthorizedHeader handleSignup={handleSignUpAuth} handleLogin={handleSignInAuth} />
      )}
      <Switch>
        {getSearchRoutes().map((ele, idx) => (
          <Route
            key={idx}
            exact
            path={ele.path}
            render={(props) => (
              <>
                <ele.component {...props} {...ele.props} />
                {ele.path.includes(":collection") && <SearchFooter />}
              </>
            )}
          />
        ))}
        {publicRoutes.map((ele, idx) => (
          <Route
            key={idx}
            exact
            path={ele.path}
            render={(props) => <ele.component {...props} {...ele.props} />}
          />
        ))}
        <Redirect from="/recipes" to="/" />
        <Route path="/resources" component={ResourcesPage} />
        {!isAccessToken && <Route component={RedirectToLogin} />}
      </Switch>
    </>
  );
}

const MainEntry = ({ appErrorState }) => {
  const { showBillingToast, isAccessed, isAccessToken, isLoading } = useInit();
  const { logoutWithRedirect } = useAuthUtils();
  const handleLogout = useCallback(() => {
    logoutWithRedirect();
    removeCookies();
  }, [logoutWithRedirect]);

  const getBillingToastClass = (isShow) => (isShow ? "mt-32 lg:mt-16" : "");

  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen absolute top-0 left-0">
          <Loader />
        </div>
      }
    >
      <div className={`application-wrapper ${getBillingToastClass(showBillingToast)}`}>
        <App
          appErrorState={appErrorState}
          isAccessed={isAccessed}
          isAccessToken={isAccessToken}
          allRoutes={<PublicRoutes isAccessToken={isAccessToken} isLoading={isLoading} />}
          isLoading={isLoading}
          handleLogout={handleLogout}
        />
      </div>
      <SnackbarComponent />
    </Suspense>
  );
};

export default MainEntry;

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

const createActionTypes = <T extends string>(
  base: T
): {
  [REQUEST]: `${T}_${typeof REQUEST}`;
  [SUCCESS]: `${T}_${typeof SUCCESS}`;
  [FAILURE]: `${T}_${typeof FAILURE}`;
} => {
  return {
    [REQUEST]: `${base}_${REQUEST}`,
    [SUCCESS]: `${base}_${SUCCESS}`,
    [FAILURE]: `${base}_${FAILURE}`
  };
};

export const UPDATE_TREENAME_SUCCESS = "UPDATE_TREENAME_SUCCESS";
export const UPDATE_TREENAME_FAILURE = "UPDATE_TREENAME_FAILURE";
export const USERTREES = createActionTypes("USERTREE");
export const TREEPEOPLE = createActionTypes("TREEPEOPLE");
export const SAVETOTREE = createActionTypes("SAVETOTREE");
export const GETSTORY = createActionTypes("GETSTORY");
export const POSTSTORY = createActionTypes("POSTSTORY");
export const UPDATESTORY = createActionTypes("UPDATESTORY");
export const POSTMEDIA = createActionTypes("POSTMEDIA");
export const VIEWSTORY = { ...createActionTypes("VIEWSTORY"), RESET: "VIEWSTORY.RESET" };
export const UPDATEPRIVACYSTATUS = createActionTypes("UPDATEPRIVACYSTATUS");
export const DELETESTORYPERSON = createActionTypes("DELETESTORYPERSON");
export const COLEECTIONDROPDOWN = createActionTypes("COLEECTIONDROPDOWN");
export const DELETESTORY = createActionTypes("DELETESTORY");
export const GETLEFTPANELDETAILS = createActionTypes("GETLEFTPANELDETAILS");
export const GETRIGHTPANELDETAILS = createActionTypes("GETRIGHTPANELDETAILS");
export const SPOUSESWITHCHILDREN = createActionTypes("SPOUSESWITHCHILDREN");
export const GETSTORYPAGINATION = createActionTypes("GETSTORYPAGINATION");
export const GETSTORIES = createActionTypes("GETSTORIES");
export const GETSTORYFEEDPAGINATION = createActionTypes("GETSTORYFEEDPAGINATION");
export const GETSTORIESFEED = createActionTypes("GETSTORIESFEED");
export const GROUPID = createActionTypes("GROUPID");
export const GETFEEDSTORYANDUPDATELIST = createActionTypes("GETFEEDSTORYANDUPDATELIST");
export const GETHOMESTORYANDUPDATELIST = createActionTypes("GETHOMESTORYANDUPDATELIST");
export const GROUP_FEED_UPDATED_STORIES = createActionTypes("GROUP_FEED_UPDATED_STORIES");
export const HOME_FEED_UPDATED_STORIES = createActionTypes("HOME_FEED_UPDATED_STORIES");
export const GETOWNSTORYANDUPDATELIST = createActionTypes("GETOWNSTORYANDUPDATELIST");
export const GETGROUPSTORYANDUPDATELIST = createActionTypes("GETGROUPSTORYANDUPDATELIST");
export const GETSTORYANDUPDATELIST = createActionTypes("GETSTORYANDUPDATELIST");
export const GETSTORIESCOUNT = createActionTypes("GETSTORIESCOUNT");
export const GETFEEDSTORIESCOUNT = createActionTypes("GETFEEDSTORIESCOUNT");
export const GETSTORIESPAGINATION = createActionTypes("GETSTORIESPAGINATION");
export const GETTREESLIST = createActionTypes("GETTREESLIST");
export const GETUSERGROUPSLIST = createActionTypes("GETUSERGROUPSLIST");
export const GETUSERGROUPSSPONSOREDLIST = createActionTypes("GETUSERGROUPSSPONSOREDLIST");
export const RECENTPEOPLE = createActionTypes("RECENTPEOPLE");
export const HINTLIST = createActionTypes("HINTLIST");
export const UPDATEHINTSTATUS = createActionTypes("UPDATEHINTSTATUS");
export const NEWSPAPER_MATCHES = createActionTypes("NEWSPAPER_MATCHES");
export const RECENT_NEWSPAPER_MATCHES = createActionTypes("RECENT_NEWSPAPER_MATCHES");
export const ADDTMESSAGE = "ADDTMESSAGE";
export const REMOVETMESSAGE = "REMOVETMESSAGE";
export const MEDIA = createActionTypes("MEDIA");
export const MEDIALIST = {
  ...createActionTypes("MEDIALIST"),
  RESET: "MEDIALIST.RESET",
  SET: "MEDIALIST.SET"
};
export const ADDPETFORMEDIA = createActionTypes("ADDPETFORMEDIA");
export const MEDIALISTPAGINATION = createActionTypes("MEDIALISTPAGINATION");
export const UPDATESTORYISLIKED = createActionTypes("UPDATESTORYISLIKED");
export const STORYLIKESPERSONS = createActionTypes("STORYLIKESPERSONS");
export const UPDATEFEEDSTORYISLIKED = createActionTypes("UPDATEFEEDSTORYISLIKED");
export const UPDATEMEDIAMETADATA = createActionTypes(`UPDATEMEDIAMETADATA`);
export const GETUSERDETAILS = createActionTypes(`GETUSERDETAILS`);
export const GETMEMBERDETAILS = createActionTypes(`GETMEMBERDETAILS`);
export const ADDPERSONTOMEDIA = createActionTypes("ADDPERSONTOMEDIA");
export const REMOVEPERSONFROMMEDIA = createActionTypes("REMOVEPERSONFROMMEDIA");
export const SUBMITCARDDETAILS = {
  ...createActionTypes("SUBMITCARDDETAILS"),
  RESET: "SUBMITCARDDETAILS.RESET",
  SHOWBILLINGTOAST: "SUBMITCARDDETAILS.SHOWBILLINGTOAST",
  HIDEBILLINGTOAST: "SUBMITCARDDETAILS.HIDEBILLINGTOAST"
};
export const TAXAPIDETAILS = createActionTypes("TAXAPIDETAILS");
export const STORENEWTAXAPIDETAILS = createActionTypes("STORENEWTAXAPIDETAILS");
export const STOREOLDTAXAPIDETAILS = createActionTypes("STOREOLDTAXAPIDETAILS");
export const REVERTOLDTAXAPIDETAILS = createActionTypes("REVERTOLDTAXAPIDETAILS");
export const RESETTAXAPIDETAILS = createActionTypes("RESETTAXAPIDETAILS");
export const BILLINGDETAILS = createActionTypes("BILLINGDETAILS");
export const GETNOTIF = createActionTypes("GETNOTIF");
export const GETACTUALNOTIF = createActionTypes("GETACTUALNOTIF");
export const PERSONCLUE = createActionTypes("PERSONCLUE");
export const LOCATION = {
  COUNTY: createActionTypes("COUNTY"),
  STATE: { ...createActionTypes("STATE"), RESET: "LOCATION.STATE.RESET" },
  STATEINITIAL: createActionTypes("STATEINITIAL"),
  STATEBROWSE: createActionTypes("STATEBROWSE"),
  CITY: { ...createActionTypes("CITY"), RESET: "LOCATION.CITY.RESET" },
  CITYBROWSE: createActionTypes("CITYBROWSE"),
  PUBLICATION: { ...createActionTypes("PUBLICATION"), RESET: "LOCATION.PUBLICATION.RESET" },
  PUBLICATIONBROWSE: createActionTypes("PUBLICATIONBROWSE"),
  DATERANGE: { ...createActionTypes("DATERANGE"), RESET: "LOCATION.DATERANGE.RESET" },
  DATERANGEBROWSE: createActionTypes("DATERANGEBROWSE"),
  BROWSECOUNT: createActionTypes("BROWSECOUNT"),
  PUBLICATIONYEAR: {
    ...createActionTypes("PUBLICATIONYEAR"),
    SET: "CONSTANTS.LOCATION.PUBLICATIONYEAR.SET"
  },
  PUBLICATIONYEARMONTH: createActionTypes("PUBLICATIONYEARMONTH"),
  PUBLICATIONYEARMONTHDATE: createActionTypes("PUBLICATIONYEARMONTHDATE"),
  PUBLICATIONLOCATIONSEARCH: {
    ...createActionTypes("PUBLICATIONLOCATIONSEARCH"),
    RESET: "LOCATION.PUBLICATIONLOCATIONSEARCH.RESET"
  },
  PUBLICATIONLOCATIONSEARCHPAGI: createActionTypes("PUBLICATIONLOCATIONSEARCHPAGI")
};
export const PERSONRECORDSEARCH = createActionTypes("PERSONRECORDSEARCH");
export const GETPUBLICTAION = createActionTypes("GETPUBLICTAION");
export const GETPUBLICTAIONPAGINATION = createActionTypes("GETPUBLICTAIONPAGINATION");
export const UPDATENOTIFCOUNT = createActionTypes("UPDATENOTIFCOUNT");
export const REFRESHNOTIF = createActionTypes("REFRESHNOTIF");
export const GETFOLLOWER = createActionTypes("GETFOLLOWER");
export const GETFOLLOWING = createActionTypes("GETFOLLOWING");
export const GETFOLLOWUNFOLLOWCOUNT = createActionTypes("GETFOLLOWUNFOLLOWCOUNT");
export const SETFOLLOWUNFOLLOWMODAL = createActionTypes("SETFOLLOWUNFOLLOWMODAL");
export const SETFOLLOWUNFOLLOWLIKE = createActionTypes("SETFOLLOWUNFOLLOWLIKE");
export const GETFOLLOWUNFOLLOWDETAIL = createActionTypes("GETFOLLOWUNFOLLOWDETAIL");
export const GETFOLLOWUNFOLLOWDETAILCLEAR = createActionTypes("GETFOLLOWUNFOLLOWDETAILCLEAR");
export const SEARCHPEOPLE = createActionTypes("SEARCHPEOPLE");
export const SEARCHPEOPLEPAGINATE = createActionTypes("SEARCHPEOPLEPAGINATE");
export const SETFOLLOWUNFOLLOW = createActionTypes("SETFOLLOWUNFOLLOW");
export const SETFOLLOWUNFOLLOWCOUNTUPDATE = createActionTypes("SETFOLLOWUNFOLLOWCOUNTUPDATE");
export const TOPICS = createActionTypes("TOPICS");
export const ASSIGNTOPICTOSTORY = createActionTypes("ASSIGNTOPICTOSTORY");
export const REMOVETOPICFROMSTORY = createActionTypes("REMOVETOPICFROMSTORY");
export const GETADMINSTORIES = createActionTypes("GETADMINSTORIES");
export const GETADMINSTORIESPAGINATION = createActionTypes("GETADMINSTORIESPAGINATION");
export const GETADMINSTORYANDUPDATELIST = createActionTypes("GETADMINSTORYANDUPDATELIST");
export const GETADMINSTORIESCOUNT = createActionTypes("GETADMINSTORIESCOUNT");
export const TOPICBYID = createActionTypes("TOPICBYID");
export const GETNEWSPAPERFREEVIEWSCOUNT = createActionTypes("GETNEWSPAPERFREEVIEWSCOUNT");
export const UPDATENEWSPAPERVIEWSPAGEURL = createActionTypes("UPDATENEWSPAPERVIEWSPAGEURL");
export const FOLLOWTOPIC = createActionTypes("FOLLOWTOPIC");
export const UNFOLLOWTOPIC = createActionTypes("UNFOLLOWTOPIC");
export const GETFOLLOWEDTOPICS = createActionTypes("GETFOLLOWEDTOPICS");
export const GETTOPICBYID = createActionTypes("GETTOPICBYID");
export const CLEARTOPICLIST = createActionTypes("CLEARTOPICLIST");
export const ADDMILODETAILS = createActionTypes("ADDMILODETAILS");
export const UPDATEMILODETAILS = createActionTypes("UPDATEMILODETAILS");
export const CCPA = createActionTypes("CCPA");
export const SETFOLLOWUNFOLLOWVIEWSTORY = createActionTypes("SETFOLLOWUNFOLLOWVIEWSTORY");
export const UPDATEVIEWSTORYISLIKED = createActionTypes("UPDATEVIEWSTORYISLIKED");
export const ISIMGVALID = createActionTypes("ISIMGVALID");
export const GETCOMMENTS = createActionTypes("GETCOMMENTS");
export const ADDCOMMENTS = createActionTypes("ADDCOMMENTS");
export const COUNTCOMMENTS = createActionTypes("COUNTCOMMENTS");
export const SHARESTORYSTATUS = createActionTypes("SHARESTORYSTATUS");
export const GETOWNSTORIES = createActionTypes("GETOWNSTORIES");
export const GETGROUPSTORIES = createActionTypes("GETGROUPSTORIES");
export const GETOWNSTORIESPAGINATION = createActionTypes("GETOWNSTORIESPAGINATION");
export const GETGROUPSTORIESPAGINATION = createActionTypes("GETGROUPSTORIESPAGINATION");

export const SET_ELASTIC_QUERY = "SET_ELASTIC_QUERY";
export const REMOVE_ELASTIC_QUERY = "REMOVE_ELASTIC_QUERY";
export const SET_COLLECTION_NAME = "SET_COLLECTION_NAME";
export const VIEWSTORYEMPTY = "VIEWSTORYEMPTY";
export const REPORTSTORY = createActionTypes("REPORTSTORY");

export const GROUPDETAILS = createActionTypes("GROUPDETAILS");
export const CREATEGROUP = createActionTypes("CREATEGROUP");
export const LEAVEGROUP = createActionTypes("LEAVEGROUP");
export const CHECKEXISTINGROUP = createActionTypes("CHECKEXISTINGROUP");
export const ADDRECENTLYVIEWEDGROUP = createActionTypes("ADDRECENTLYVIEWEDGROUP");

export const SKIPINVITE = createActionTypes("SKIPINVITE");
export const CREATEINVITE = createActionTypes("CREATEINVITE");
export const RESETINVITE = createActionTypes("RESETINVITE");
export const ISGROUPINVITATIONVALID = createActionTypes("ISGROUPINVITATIONVALID");
export const GETGROUPINFO = createActionTypes("GETGROUPINFO");
export const GETGROUPURL = createActionTypes("GETGROUPURL");
export const GETGROUPMEMBERS = createActionTypes("GETGROUPMEMBERS");
export const ACCEPTGROUPINVITE = createActionTypes("ACCEPTGROUPINVITE");
export const ACCEPTGROUPJOIN = createActionTypes("ACCEPTGROUPJOIN");
export const CLAIMSPONSORSHIP = createActionTypes("CLAIMSPONSORSHIP");
export const GETUSERROLE = createActionTypes("GETUSERROLE");
export const FOLLOWUSER = createActionTypes("FOLLOWUSER");
export const UNFOLLOWUSER = createActionTypes("UNFOLLOWUSER");
export const UPGRADEMEMBERROLE = createActionTypes("UPGRADEMEMBERROLE");
export const REMOVEFROMGROUP = createActionTypes("REMOVEFROMGROUP");
export const GETTREESLISTFORGROUP = createActionTypes("GETTREESLISTFORGROUP");
export const GETPENDINGINVITES = createActionTypes("GETPENDINGINVITES");
export const GETINVITERINFO = createActionTypes("GETINVITERINFO");
export const UPDATEGROUPDETAILS = createActionTypes("UPDATEGROUPDETAILS");
export const CANCELINVITE = createActionTypes("CANCELINVITE");

export const GET_CITATION = createActionTypes("GET_CITATION");
export const ADD_CITATION = createActionTypes("ADD_CITATION");
export const UPDATE_CITATION = createActionTypes("UPDATE_CITATION");
export const REMOVE_CITATION = createActionTypes("REMOVE_CITATION");

export const UPLOAD_GROUP_IMAGE = createActionTypes("UPLOAD_GROUP_IMAGE");
export const DELETE_GROUP_IMAGE = createActionTypes("DELETE_GROUP_IMAGE");

export const CHECK_TREE_PERSON_STATUS = createActionTypes("CHECK_TREE_PERSON_STATUS");
export const CONFIRM_MEMBER_STATUS = createActionTypes("CONFIRM_MEMBER_STATUS");
export const DELETESTORYID = createActionTypes("DELETESTORYID");
export const FIRST_STORY_PROMPT_OPTION_SELECTED = createActionTypes(
  "FIRST_STORY_PROMPT_OPTION_SELECTED"
);
export const DELETEMEDIA = createActionTypes("DELETEMEDIA");
export const PET_INFO = createActionTypes("PET_INFO");
export const GET_MERGE_RECORD = createActionTypes("GET_MERGE_RECORD");
export const GET_TREE_PERSON_INFO = createActionTypes("GET_TREE_PERSON_INFO");
export const GET_RECORD_PERSON_INFO = createActionTypes("GET_RECORD_PERSON_INFO");
export const HINTRECORDS = createActionTypes("HINTRECORDS");
export const UPDATEHINTRECORDS = createActionTypes("UPDATEHINTRECORDS");
export const SAVEDHINTRECORDS = createActionTypes("SAVEDHINTRECORDS");
export const GET_STORY_ASSIST = createActionTypes("GET_STORY_ASSIST");
export const UPDATE_STORY_ASSIST = createActionTypes("UPDATE_STORY_ASSIST");
export const GET_STORY = createActionTypes("GET_STORY");
export const SAVE_MERGE_RECORD = createActionTypes("SAVE_MERGE_RECORD");
export const SAVE_PERSOANDRECORD_ASSOCIATIO = createActionTypes("SAVE_PERSOANDRECORD_ASSOCIATIO");
export const CHECK_ASSOCIATION = createActionTypes("CHECK_ASSOCIATION");
export const SHAREABLE_STORY = createActionTypes("SHAREABLE_STORY");
// print book
export const GET_PRINT_BOOK_STORY = {
  ...createActionTypes("GET_PRINT_BOOK_STORY"),
  RESET: "GET_PRINT_BOOK_STORY.RESET"
};
export const GET_STORY_BOOK_INFO = createActionTypes("GET_STORY_BOOK_INFO");
export const SET_STORY_BOOK_INFO = createActionTypes("SET_STORY_BOOK_INFO");
export const CREATE_STORY_BOOK = createActionTypes("CREATE_STORY_BOOK");
export const UPDATE_STORY_BOOK = createActionTypes("UPDATE_STORY_BOOK");

export const ORDER_PRINT_BOOK = createActionTypes("ORDER_PRINT_BOOK");

export const GET_SELECTED_STORIES = createActionTypes("GET_SELECTED_STORIES");
export const GET_STORY_BOOK_LIST = createActionTypes("GET_STORY_BOOK_LIST");
export const REQUEST_HINT_GENERATION = createActionTypes("REQUEST_HINT_GENERATION");
export const USER_COUPON_STATUS = createActionTypes("USER_COUPON_STATUS");
export const TRY_GRANT_COUPON = createActionTypes("TRY_GRANT_COUPON");
export const SET_STORY_BOOK_DETAIL = createActionTypes("SET_STORY_BOOK_DETAIL");
export const GETSPECIFICSTORYCOUNT = createActionTypes("GETSPECIFICSTORYCOUNT");
export const SET_CURRENT_INDEX = createActionTypes("SET_CURRENT_INDEX");
export const SET_GALLERY_TYPE = createActionTypes("SET_GALLERY_TYPE");
export const SET_GALLERY_ID = createActionTypes("SET_GALLERY_ID");

// SET_MEDIA_ITEMS
export const SET_MEDIA_ITEMS = createActionTypes("SET_MEDIA_ITEMS");

import { GET_ALL_GROUPS, UPDATE_ORDER_USER_GROUP, GET_ALL_TREES, SET_IMPORTING_INPROGRESS, GROUP_PROMPT_HOMEPAGE, LEAVE_GROUP, SET_ALL_USER_TREES, GET_ALL_PROJECTS } from "../constants";

const initialState = {
  isImportingInProgress: false,
  allUserTrees: null,
  userGroups: null,
  userProjects: null,
  triggerGroupViaHomepage: false
};

const leftSideNav = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_TREES:
      return {
        ...state,
        allUserTrees: payload?.data,
        ownTrees: payload?.ownUserTrees
      };
    case GET_ALL_GROUPS: {
      return {
        ...state,
        userGroups: payload
      };
    }
    case LEAVE_GROUP: {
      return {
        ...state,
        userGroups: state.userGroups.filter((group) => group.groupId !== payload.groupId)
      };
    }
    case GET_ALL_PROJECTS: {
      return {
        ...state,
        userProjects: payload
      };
    }
    case GROUP_PROMPT_HOMEPAGE: {
      return {
        ...state,
        triggerGroupViaHomepage: payload
      };
    }
    case SET_ALL_USER_TREES:
      return {
        ...state,
        allUserTrees: payload
      };
    case UPDATE_ORDER_USER_GROUP: 
      const tempGroup = state.userGroups?.find((group) => group.groupId === payload.groupId);
      const array = (state.userGroups?.filter((group) => group.groupId !== payload.groupId));

      if (!tempGroup) return state;

      return {
        ...state,
        userGroups: [tempGroup, ...array]
      };
    case SET_IMPORTING_INPROGRESS:
      return {
        ...state,
        isImportingInProgress: payload
      };
    default:
      return state;
  }
};

export default leftSideNav;
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  V2UserMediaVideoData,
  V2UserMediaVideoMetadataData,
  V2UserMediaVideoMetadataPayload,
  V2UserMediaVideoUploadCompleteData,
  V2UserMediaVideoUploadCompletePayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class UserMedia extends HttpClient {
  /**
   * No description
   *
   * @tags UserMedia
   * @name V2UserMediaVideoUploadComplete
   * @request POST:/api/v2/UserMedia/video/upload-complete
   * @secure
   */
  v2UserMediaVideoUploadComplete = (
    data: V2UserMediaVideoUploadCompletePayload,
    params: RequestParams = {}
  ) =>
    this.request<V2UserMediaVideoUploadCompleteData, any>({
      path: `/api/v2/UserMedia/video/upload-complete`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags UserMedia
   * @name V2UserMediaVideo
   * @request DELETE:/api/v2/UserMedia/video/{videoId}
   * @secure
   */
  v2UserMediaVideo = (videoId: string, params: RequestParams = {}) =>
    this.request<V2UserMediaVideoData, any>({
      path: `/api/v2/UserMedia/video/${videoId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags UserMedia
   * @name V2UserMediaVideoMetadata
   * @request POST:/api/v2/UserMedia/video/metadata
   * @secure
   */
  v2UserMediaVideoMetadata = (data: V2UserMediaVideoMetadataPayload, params: RequestParams = {}) =>
    this.request<V2UserMediaVideoMetadataData, any>({
      path: `/api/v2/UserMedia/video/metadata`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    });
}

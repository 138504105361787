import logoImg from "assets/images/logo.svg";
import logoBlackImg from "assets/images/logoBlack.svg";
import logoBlackSymbol from "assets/images/logoBlackSymbol.svg";
import logoBlueImg from "assets/images/logoBlue.svg";
import logoBlueSymbol from "assets/images/logoBlueSymbol.svg";
import logoSymbol from "assets/images/logoSymbol.svg";
import { useCouponCode } from "hooks/useCouponCode";
import useWindowDimensions from "hooks/useWindowDimensions";
import { getAccessToken } from "services";
import { REACT_APP_ENV } from "utils/env";

const appEnv = REACT_APP_ENV?.toLowerCase() || "default";
const logos = {
  production: {
    default: logoImg,
    mobile: logoSymbol
  },
  automation: {
    default: logoBlueImg,
    mobile: logoBlueSymbol
  },
  test: {
    default: logoBlackImg,
    mobile: logoBlackSymbol
  },
  default: {
    default: logoBlackImg,
    mobile: logoBlackSymbol
  }
};
const HeaderLogo = () => {
  const isLoggedIn = getAccessToken();
  const { width } = useWindowDimensions();
  // store coupon code in cookie
  useCouponCode();

  const isMobileScreen = width < 512;
  const environmentLogos = logos[appEnv] || logos.default;
  const logoSrc = isMobileScreen && isLoggedIn ? environmentLogos.mobile : environmentLogos.default;
  return (
    <img
      src={logoSrc}
      fetchPriority="high"
      alt="Storied"
      width={isMobileScreen && isLoggedIn ? 20 : 94}
      height={20}
    />
  );
};

export default HeaderLogo;

import * as CONSTANTS from "../constants/actionTypes";

const initialState = {
    isLoading: false,
    bilingLoading: false,
    data: 0,
    oldTaxData: 0,
    billingInfo: null
}

const paymenttax = (state = initialState, { type = null, payload = null } = {}) => {
    switch (type) {
        case CONSTANTS.STORENEWTAXAPIDETAILS.REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case CONSTANTS.STORENEWTAXAPIDETAILS.SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
                value: true
            }
        case CONSTANTS.STORENEWTAXAPIDETAILS.FAILURE:
            return {
                ...state,
                isLoading: false,
                value: false
            }
        case CONSTANTS.BILLINGDETAILS.REQUEST:
            return {
                ...state,
                bilingLoading: true,
                billingInfo: null
            }
        case CONSTANTS.BILLINGDETAILS.SUCCESS:
            return {
                ...state,
                bilingLoading: false,
                billingInfo: payload
            }
        case CONSTANTS.BILLINGDETAILS.FAILURE:
            return {
                ...state,
                bilingLoading: false,
            }
        case CONSTANTS.STOREOLDTAXAPIDETAILS.REQUEST:
            return {
                ...state,
                oldTaxData: payload,
            }
        case CONSTANTS.REVERTOLDTAXAPIDETAILS.REQUEST:
            return {
                ...state,
                data: state.oldTaxData,
                }
        case CONSTANTS.RESETTAXAPIDETAILS.REQUEST:
        return {
            ...state,
            data: 0,
            oldTaxData: 0
            }
        default:
            return state;
    }
}

export default paymenttax

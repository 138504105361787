import { apiSalesForceRequest } from "redux/requests/salesforce";
import { getOwner } from "services";
import { v4 as uuidv4 } from "uuid";

export const subscriptionConfirmationTriggerEmail = (
  subData,
  formData,
  plan,
  tax,
  totalAmount,
  userId
) => {
  const endDate = new Date(subData.startDate);
  endDate.setMonth(endDate.getMonth() + plan[0].timePeriod);
  const emailAddress = formData.UserInformation.EmailAddress;
  const startDate = subData.startDate;
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  };
  const attributes = {
    FirstName: formData.UserInformation.Firstname,
    Subscription: plan[0].planName,
    Price: plan[0].amount,
    Tax: tax,
    TotalPrice: totalAmount,
    CouponDiscount: formData.CouponDiscount,
    SubStartDate: new Intl.DateTimeFormat("en-US", options).format(startDate),
    SubRenewDate: new Intl.DateTimeFormat("en-US", options).format(endDate),
    SubRenewAmount: plan[0].actualAmount || plan[0].amount
  };
  const firstRecipient = {
    contactKey: userId,
    messageKey: uuidv4(),
    to: emailAddress,
    attributes
  };
  const recipients = [firstRecipient];
  const accountCreationPayload = {
    definitionKey: "st-sub-confirmation",
    recipients
  };
  apiSalesForceRequest(
    "POST",
    `definitionKey=st-sub-confirmation&websiteId=4670&emailId=${emailAddress}`,
    accountCreationPayload,
    null,
    null
  ).then((y) => y);
};

export const subscriptionCancelTriggerEmail = (values) => {
  const endDate = new Date(values.PlanDetails.SubscriptionEndDate);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  };
  const emailAddress = values.UserInformation.Email;
  let userid = getOwner();
  const attributes = {
    FirstName: values.UserInformation.Firstname,
    LastName: values.UserInformation.Lastname,
    OldPlanName: values.PlanDetails.OldPlanName,
    NewPlanName: "Storied Free",
    OldPrice: values.PlanDetails.OldPrice,
    NewPrice: "0.00",
    SubscriptionEndDate: new Intl.DateTimeFormat("en-US", options).format(endDate)
  };
  const firstRecipient = {
    contactKey: userid,
    messageKey: uuidv4(),
    to: emailAddress,
    attributes
  };
  const recipients = [firstRecipient];
  const accountCreationPayload = {
    definitionKey: "st-subscription-cancelation",
    recipients
  };
  apiSalesForceRequest(
    "POST",
    `definitionKey=st-subscription-cancelation&websiteId=4670&emailId=${emailAddress}`,
    accountCreationPayload,
    null,
    null
  ).then((y) => y);
};

export const subscriptionUpdateTriggerEmail = (formData, tax, totalAmount) => {
  const renewalDate = new Date(formData?.UpgradePlanInformation?.renewalDate);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  };
  const emailAddress = formData.UserInformation.EmailAddress;
  const attributes = {
    FirstName: formData.UserInformation.Firstname,
    LastName: formData.UserInformation.Lastname,
    OldPlanName: formData.UpgradePlanInformation.OldPlanName,
    NewPlanName: formData.UpgradePlanInformation.NewPlanName,
    OldPrice: formData.UpgradePlanInformation.OldPrice,
    NewPrice: formData.UpgradePlanInformation.NewPlanAmount,
    ProratedRefund: formData.UpgradePlanInformation.RefundAmount,
    RenewalDate: new Intl.DateTimeFormat("en-US", options).format(renewalDate),
    Tax: tax,
    TotalPrice: totalAmount
  };
  const firstRecipient = {
    contactKey: formData.UserInformation.userId,
    messageKey: uuidv4(),
    to: emailAddress,
    attributes
  };
  const recipients = [firstRecipient];
  const accountCreationPayload = {
    definitionKey: "st-subscription-upgrade",
    recipients
  };
  apiSalesForceRequest(
    "POST",
    `definitionKey=st-subscription-upgrade&websiteId=4670&emailId=${emailAddress}`,
    accountCreationPayload,
    null,
    null
  ).then((y) => y);
};

import {
    GET,
    USER_ERROR,
    GET_ALL_TREES,
    SET_ALL_USER_TREES,
    GET_ALL_GROUPS,
    GET_ALL_PROJECTS,
    LEAVE_GROUP,
    CLEAR_GEDCOM,
    SET_HOMEPERSON_MODAL,
    UPDATE_ORDER_USER_GROUP,
    SET_IMPORTING_INPROGRESS
} from "../constants";
import { apiRequest } from "../requests";

export const getAllUserTreesList = (id, apiUrl = `Users/trees?numberOfTrees=5`) => async (dispatch) => {
    try {
        let requestData = { authorId: id };
        const response = await apiRequest(GET, apiUrl, requestData);
        const ownTrees = response?.data?.filter((tree) => tree.ownerId === id);
        response.ownUserTrees = ownTrees;

        dispatch({
            type: GET_ALL_TREES,
            payload: response
        });
    } catch (err) {
        dispatch({
            type: USER_ERROR,
            payload: { msg: err }
        });
    }
};

export const addToRecentTrees = (trees, treeId, startNewTree) => (dispatch) => {
    let foundTree = trees?.filter(ele => ele.treeId === treeId);
    if (foundTree?.length) {
      let remainingTrees = trees.filter(ele => ele.treeId !== treeId);
      let firstPositionedTree = [...foundTree, ...remainingTrees];
      dispatch({
        type: GET_ALL_TREES,
        payload: firstPositionedTree
      });
    }
    else {
      let newTree = [{
        treeId: startNewTree?.treeId,
        homePersonId: startNewTree?.primaryPersonId,
        treeName: startNewTree?.treeName
      }];
      let newAddedTrees = [...newTree, ...trees];
      dispatch({
        type: GET_ALL_TREES,
        payload: newAddedTrees
      });
      dispatch({
        type: CLEAR_GEDCOM
      });
    }
  };

  export const showSetHomePersonModal = (person) => (dispatch) => {
    dispatch({
      type: SET_HOMEPERSON_MODAL,
      payload: person,
    });
  };

  export const setImportingInProgress = (inProgress) => (dispatch) => {
    dispatch({
      type: SET_IMPORTING_INPROGRESS,
      payload: inProgress
    });
  };
  
  const getProjectsWithBookInfo = async (projects) => {
    const results = await Promise.allSettled(
      projects.map(async (item) => {
        if (!item.bookId) {
          return {
            ...item
          };
        }
        try {
          if(item.bookId.id !== '00000000-0000-0000-0000-000000000000') {
            const bookDetails = await apiRequest(GET, `StoryBook/${item.bookId.id}`).then((res) => {
              return res.data;
            });
            return {
              ...item,
              name: item.name ||
              bookDetails.storyBookDetails?.title ||
              bookDetails.storyBookDetails?.coverDetails?.title,
              bookInfo: bookDetails,
              projectBackgroundImageUrl: bookDetails.storyBookDetails?.coverDetails?.coverImageUrl
            };
          } else {
            return {
              ...item
            };
          }
        } catch (error) {
          return {
            ...item
          };
        }
      })
    );
    const respWithAuthUser = results.map((result) =>
      result.status === "fulfilled" ? result.value : result.reason
    );
    return respWithAuthUser;
  };

  export const getUserGroups = () => async (dispatch) => {
    try {
      const response = await apiRequest(GET, `Users/groups`);

      dispatch({
          type: GET_ALL_GROUPS,
          payload: response.data
      });
  } catch (err) {
      dispatch({
          type: USER_ERROR,
          payload: { msg: err }
      });
  }
  };

  export const getUserProjects = () => async (dispatch) => {
    try {
      const response = await apiRequest(GET, `Users/booklist`).then((res) => {
        return res.data.map(
          (book) =>
            ({
              url: book.bookURL
                ? book.bookURL
                : `/storiedbook/edit/${book.storyBookId}/select-stories`,
              name: book.coverDetails?.title || "",
              projectBackgroundImageUrl: book.coverDetails?.coverImageUrl
            })
        );
      });
      dispatch({
          type: GET_ALL_PROJECTS,
          payload: response
      });
    } catch (err) {
        dispatch({
            type: USER_ERROR,
            payload: { msg: err }
        });
    }
  };
  export const getUserProjectsV2 = () => async (dispatch) => {
    try {
      const projectRecentlyViewed = await apiRequest(GET, `v2/Project/recently-viewed`).then((res) => {
        return res.data.map((project) => project.id?.id);
      });
      const projectsInfo = await apiRequest(GET, `v2/Users/projects`).then((res) => {
        return res.data.map(
          (project) =>
            ({
              ...project,
              url: `/project/view/${project.projectId}`
            })
        );
      });
      const sortedUserProjects = projectsInfo.sort((a, b) => {
        const aIsRecentlyViewed = projectRecentlyViewed.includes(a.projectId);
        const bIsRecentlyViewed = projectRecentlyViewed.includes(b.projectId);
  
        if (aIsRecentlyViewed && !bIsRecentlyViewed) {
          return -1;
        } else if (!aIsRecentlyViewed && bIsRecentlyViewed) {
          return 1;
        } else {
          return 0;
        }
      });
      const projectsWithBookInfo = await getProjectsWithBookInfo(sortedUserProjects);

      dispatch({
          type: GET_ALL_PROJECTS,
          payload: projectsWithBookInfo
      });
    } catch (err) {
        dispatch({
            type: USER_ERROR,
            payload: { msg: err }
        });
    }
  };

  export const updateOrderUserGroup = (groupId, order = 0) => (dispatch) => {
    dispatch({
      type: UPDATE_ORDER_USER_GROUP,
      payload: {groupId, order},
    });
  };

  export const leaveUserGroup = (payload) => (dispatch) => {
    dispatch({
      type: LEAVE_GROUP,
      payload: payload,
    });
  };

  export const setAllUserTreesList = (payload) => (dispatch) => {
    try {
      dispatch({
        type: SET_ALL_USER_TREES,
        payload: payload
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: { msg: err }
      });
    }
  };
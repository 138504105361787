/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdminRecordPersonUpdaterecordpersonnameData,
  AdminRecordPersonUpdaterecordpersonnameParams,
  RecordPersonData,
  RecordPersonParams
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class RecordPerson extends HttpClient {
  /**
   * No description
   *
   * @tags RecordPerson
   * @name RecordPerson
   * @request POST:/api/RecordPerson
   * @secure
   */
  recordPerson = (query: RecordPersonParams, params: RequestParams = {}) =>
    this.request<RecordPersonData, any>({
      path: `/api/RecordPerson`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags RecordPerson
   * @name AdminRecordPersonUpdaterecordpersonname
   * @request POST:/api/admin/RecordPerson/updaterecordpersonname
   * @secure
   */
  adminRecordPersonUpdaterecordpersonname = (
    query: AdminRecordPersonUpdaterecordpersonnameParams,
    params: RequestParams = {}
  ) =>
    this.request<AdminRecordPersonUpdaterecordpersonnameData, any>({
      path: `/api/admin/RecordPerson/updaterecordpersonname`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

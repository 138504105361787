/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { RecorddetailData, RecorddetailV1Data } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class RecordDetail extends HttpClient {
  /**
   * No description
   *
   * @tags RecordDetail
   * @name Recorddetail
   * @request GET:/api/recorddetail/{recordId}/{partitionKey}
   * @secure
   */
  recorddetail = (recordId: string, partitionKey: string, params: RequestParams = {}) =>
    this.request<RecorddetailData, any>({
      path: `/api/recorddetail/${recordId}/${partitionKey}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags RecordDetail
   * @name RecorddetailV1
   * @request GET:/api/recorddetail/{recordId}/{partitionKey}/V1
   * @secure
   */
  recorddetailV1 = (recordId: string, partitionKey: string, params: RequestParams = {}) =>
    this.request<RecorddetailV1Data, any>({
      path: `/api/recorddetail/${recordId}/${partitionKey}/V1`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

export const dataLayerPayment = (actionField, products) => {
  console.log("old-event: purchase");
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'eec.purchase',
    ecommerce: {
      currencyCode: 'USD',
      purchase: {
        actionField: actionField,
      },
      products: products,
    }
  });
};
import { getOwner } from "../../services";
import { CLEARGROUPPAGESTORIES, HOMESCROLLCORDFEED, REMOVE_MEDIA_FROM_STORIES } from "../constants";
import * as CONSTANTS from "../constants/actionTypes";
import { removeMediaFromStories } from "../helpers";
const initialState = {
  isLoading: false,
  stories: [],
  storiesCount: 0,
  isPaginationLoading: false,
  error: false,
  groupId: null,
  scrollCord: { x: 0, y: 0 }
};

const feeds = (state = initialState, { type = null, payload = null } = {}) => {
  const loadingPaginateFail = {
      ...state,
      isPaginationLoading: false
    },
    loadingPaginateRequest = {
      ...state,
      isPaginationLoading: true
    },
    failure = {
      ...state,
      isLoading: false,
      error: true
    };
  switch (type) {
    case CONSTANTS.GETSTORIESFEED.REQUEST:
      return {
        ...state,
        isLoading: true,
        stories: [],
        error: false
      };
    case CONSTANTS.GETSTORIESFEED.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stories: payload,
        error: false
      };
    }
    case CLEARGROUPPAGESTORIES: {
      return {
        ...state,
        stories: []
      };
    }
    case CONSTANTS.GETSTORIESFEED.FAILURE:
      return failure;
    case CONSTANTS.GETFEEDSTORYANDUPDATELIST.SUCCESS:
      return {
        ...state,
        stories: state.stories.map((item) =>
          typeof item === "string" && item === payload.storyId ? payload : item
        )
      };
    case CONSTANTS.GETFEEDSTORYANDUPDATELIST.FAILURE:
      const filterData = state.stories.filter(
        (item) => !(typeof item === "string" && item === payload)
      );
      return {
        ...state,
        stories: filterData
      };
      case CONSTANTS.GROUP_FEED_UPDATED_STORIES.SUCCESS:
        return {
          ...state,
          stories: state.stories.map((item) =>
            item.storyId === payload.storyId ? {...item, privacy: payload.privacy} : item
          )
      };
    case CONSTANTS.GETSTORYFEEDPAGINATION.REQUEST:
      return loadingPaginateFail;
    case CONSTANTS.GETSTORYFEEDPAGINATION.SUCCESS:
      return {
        ...state,
        stories: [...state.stories, ...payload],
        isPaginationLoading: false
      };
    case CONSTANTS.GROUPID.SUCCESS:
      return {
        ...state,
        groupId: payload
      };
    case CONSTANTS.GETSTORYFEEDPAGINATION.FAILURE:
      return loadingPaginateRequest;
    case HOMESCROLLCORDFEED: {
      return {
        ...state,
        scrollCord: payload
      };
    }
    case CONSTANTS.UPDATEFEEDSTORYISLIKED.SUCCESS: {
      let storyFeedList;
      let storyIndex = state.stories.findIndex((item) => item.storyId === payload.storyId);
      if (payload.isLiked === "like") {
        storyFeedList = [...state.stories];
        storyFeedList[storyIndex] = {
          ...storyFeedList[storyIndex],
          likes: [...(storyFeedList[storyIndex]?.likes || []), { userId: getOwner() }]
        };
      } else if (payload.isLiked === "unlike") {
        storyFeedList = [...state.stories];
        storyFeedList[storyIndex] = {
          ...storyFeedList[storyIndex],
          likes: storyFeedList[storyIndex]?.likes.filter((value) => value.userId !== getOwner())
        };
      }
      return {
        ...state,
        stories: [...storyFeedList]
      };
    }
    case REMOVE_MEDIA_FROM_STORIES: {
      return removeMediaFromStories(state, payload, ["stories"]);
    }
    default:
      return state;
  }
};

export default feeds;

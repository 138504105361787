import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { getPersonInformation } from "redux/actions/person";

export function useAllUsersTypeaheadCache() {
  const queryClient = useQueryClient();
  const treeName = useSelector((state) => state.family?.family?.treeInfo?.treeName);

  const addUserToCache = (newUser) => {
    const name = `${newUser.givenName} ${newUser.surname}`;
    const normalizedUserForTypeahead = {
      ...newUser,
      name
    };
    queryClient.setQueryData(["allUsersTypeahead"], (previous) => {
      const previousUsers = previous ?? [];
      return previousUsers.concat(normalizedUserForTypeahead);
    });
  };

  const invalidateAllUsersTypeaheadQuery = () => {
    return queryClient.invalidateQueries({ queryKey: ["allUsersTypeahead"], refetchType: "all" });
  };

  const addNewPersonToCache = (newUser) => {
    const name = `${newUser.firstName} ${newUser.lastName}`;
    const normalizedUserForTypeahead = {
      ...newUser,
      name,
      givenName: newUser.firstName || "",
      surname: newUser.lastName || "",
      treeName: `${newUser.lastName} Family Tree`,
      id: newUser.primaryPersonId,
      ownerId: newUser.id,
      birthDate: {
        year: newUser.birthDate,
      }
    };
    queryClient.setQueryData(["allUsersTypeahead"], (previous) => {
      const previousUsers = previous ?? [];
      return previousUsers.concat(normalizedUserForTypeahead);
    });
  };

  const editUserInCache = (updatedUser) => {
    queryClient.setQueryData(["allUsersTypeahead"], (previous) => {
      if (previous == null) return previous;
      return previous.map((user) => {
        if (user.id === updatedUser.id) {
          user.birthDate.year = updatedUser.birth;
          user.birthDate.rawDate = updatedUser.birth;
          user.deathDate.year = updatedUser.death;
          user.deathDate.rawDate = updatedUser.death;
          return {
            ...user,
            ...updatedUser
          };
        }
        return user;
      });
    });
  };

  const deleteUserFromCache = (deletedUserId) => {
    queryClient.setQueryData(["allUsersTypeahead"], (previous) => {
      if (previous == null) return previous;
      return previous.filter((user) => user.id !== deletedUserId);
    });
  };

  const fetchPersonInfoAndAddToCache = (personId) => {
    getPersonInformation(personId).then((userData) => {
      normalizeAndAddPersonToCache(userData);
    });
  };

  const normalizeAndAddPersonToCache = (userData) => {
    const normalizedUser = {
      ...userData,
      givenName: userData?.givenName?.givenName || "",
      surname: userData?.surname?.surname || "",
      name: `${userData?.givenName?.givenName} ${userData?.surname?.surname}`,
      treeName
    };

    addUserToCache(normalizedUser);
  };

  const updateTreeNameInCache = (treeId, newTreeName) => {
    queryClient.setQueryData(["allUsersTypeahead"], (previous) => {
      if (previous == null) return previous;
      return previous.map((user) => {
        if (user.treeId === treeId) {
          return {
            ...user,
            treeName: newTreeName
          };
        }
        return user;
      });
    });
  };

  //To update pet details in cache optimistically
  const updatePetDetailsInCache = (updatedPet) => {
    queryClient.setQueryData(["allUsersTypeahead"], (previous) => {
      const petPayload = {
        ...updatedPet,
        id: updatedPet?.petId,
        treeId: "00000000-0000-0000-0000-000000000000",
        givenName: updatedPet?.name
      };
      if (previous == null) return previous;
      previous.push(petPayload);
      return previous;
    });
  };

  return {
    addUserToCache,
    invalidateAllUsersTypeaheadQuery,
    editUserInCache,
    deleteUserFromCache,
    normalizeAndAddPersonToCache,
    fetchPersonInfoAndAddToCache,
    updateTreeNameInCache,
    updatePetDetailsInCache,
    addNewPersonToCache
  };
}

import { REACT_APP_MEDIA_URL } from "./env";

export const IMG_API_URL = "https://imgapi.storied.com"
export const IMAGE_WRAPPER_URL = "https://imgwrapper.storied.com";
export const MEDIA_URL = REACT_APP_MEDIA_URL;
export const MEDIA_URL_PATH = "/storied-user-content";
export const NEWSPAPER_URL = "https://newspaperarchive.com";
export const API_FAMILY_SEARCH_URL = "https://api.familysearch.org";
export const FAMILY_SEARCH_URL = "https://www.familysearch.org/en/";
export const IMAGEPATH = "/thumbimage.ashx?i=";
export const EARLIER_PLAN_ID = "19578";
export const BUNDLE_PLAN_ID = "19579";
export const BUNDLE_PLAN_ID_YEARLY = "19582";
export const BUNDLE_PLAN_ID_HALF_YEARLY = "19581";
export const BUNDLE_PLAN_ID_MONTHLY = "19580";
export const BUNDLE_PLAN_ID_HARDMONTHLY = "19580";
export const STAR_PLUS_PLAN_ID = "19583"; //In case user click on subscribed button
export const NEW_PLUS_PLAN_MONTHLY = "19650";
export const NEW_PLUS_PLAN_YEARLY = "19651";
export const NEW_PLUS_PLAN_BOOK_YEARLY = "19652";
// Todo: move this logic to airtable and can be handled by bussiness team
export const UNIQUE_IMAGE_COLLECTIONS = ["1cb1a706-c256-4af2-9529-8b25d9ca3fb7"];

export const ULTIMATE_FREE = "19586";
export const ULTIMATE_FREE_15 = "19587";
export const STORIED_PLUS_FREE_TRIAL_7 = "19619";
export const STORYBOOK_STUDIO_PLAN_ID = "19685";
export const STORYBOOK_GIFT_STUDIO_PLAN_ID = "19684";

export const STORIED_LDS_ACCOUNT_PLAN_ID = 19681;

export const ULTIMATE_PLAN_ID_MONTHLY = "19655";
export const ULTIMATE_PLAN_ID_SEMIANNUAL = "19656";
export const ULTIMATE_PLAN_ID_ANNUAL = "19657";
export const ULTIMATE_PLAN_ID_SEMIANNUAL_FREE_TRIAL = "19658";
export const ULTIMATE_PLAN_ID_ANNUAL_FREE_TRIAL = "19659";
export const ULTIMATE_PLAN_ID_ANNUAL_WITH_BOOK = "19653";
export const ULTIMATE_PLAN_ID_ANNUAL_BLACK_FRIDAY_CYBER_MONDAY = "19662";
export const ULTIMATE_PLAN_ID_ANNUAL_CYBER_MONDAY = "19663";
export const ULTIMATE_PLAN_ID_ANNUAL_ANNIVERSARY = "19668";
export const ULTIMATE_PLAN_ID_ROOTS_TECH_24 = "19671";

export const BUNDLE_FREE_TRIAL_PLUS_PLAN_ID_YEARLY = "19672";
export const BUNDLE_FREE_TRIAL_PLAN_ID_YEARLY = "19624";
export const BUNDLE_FREE_TRIAL_PLAN_ID_HALF_YEARLY = "19623";
export const BUNDLE_FREE_TRIAL_PLAN_ID_MONTHLY = "19622";
export const ULTIMATE_OFFER_ROOT_TECH_OFFER_ID = "19648";

export const GIFT_PLUS_PLAN_ID = "19682";
export const GIFT_ULTIMATE_PLAN_ID_ANNUAL = "19683";
export const GIFT_STUDIO_PLAN_ID_ANNUAL = "19684";

// plan ids for offer plans
export const OFFER_STORIED_ESSENTIAL = "19693";
export const OFFER_STORIED_PLUS = "19694";
export const OFFER_STORIED_ULTIMATE = "19695";
export const TRIAL_OFFER_STORIED_ESSENTIAL = "19690";
export const TRIAL_OFFER_STORIED_PLUS = "19691";
export const TRIAL_OFFER_STORIED_ULTIMATE = "19692";

export const BUNDLEPLAN = "bundle";
export const BUNDLEPLUSPLAN = "bundleplus";
export const FREE_TRIALPLAN = "trial";
export const PLAN = "plan";
export const ULTIMATE_PLAN = "ultimate";
export const FREE_PLAN = "free";
export const PLUSPLAN = "plus";
export const ANNUALLY = "annually";
export const ESSENTIAL = "essential";
export const EARLY = "early";
export const SEMIANNUALLY = "semiannually";
export const SEMI = "semi";
export const MONTHLY = "monthly";
export const TWO_FOR_ONE_USERTYPE = "twoforone";
export const CREATOR_PLAN = "creator";
export const FREE_TRIAL = "free trial";
export const PAID_SUBSCRIPTION = "paid subscription";
export const OFFER_PLAN_TYPES = {
  PLUS_ANNUAL: "plus-annually",
  ULTIMATE_ANNUAL: "ultimate-annually",
  PLUS_MONTHLY: "plus-monthly",
  ULTIMATE_MONTHLY: "ultimate-monthly",
  ULTIMATE_SIX_MONTHLY: "semiannually"
};
export const ULTIMATE = "ULTIMATE";
export const PLUS = "PLUS";

export const DISMISSAL = "Dismiss";
export const VIEW = "View";
export const INTERACTION = "Click";
export const GROUP_CREATION = "group creation";

export const TWO_IN_ONE = "two_for_one_module";
export const AB_MODULE = "ab_test_module";
export const GROUPMODULE = "group_module";
export const TREEMODULE = "tree_module";
export const STORYMODULE = "story_module";
export const RECORDMODULE = "record_module";
export const FREE_TRIAL_MODULE = "free_trial_module";
export const MO = "mo";
export const USD = "USD";
export const STORIED = "storied";
export const ECOMMERCE = "ecommerce";
export const BACK_END = "back_end";
export const NO_ACCOUNT = "no_account";
export const FIRST_HINT = "first_hint";
export const UPDATE_USER_BITHDATE = "UPDATE_USER_BITHDATE";

// data-layer-event
export const ADD_PAYMENT_INFO = "add_payment_info";
export const BEGIN_CHECKOUT = "begin_checkout";
export const PURCHASE_FAILURE = "purchase_failure";
export const PURCHASE_ATTEMPT = "purchase_attempt";
export const PURCHASE = "purchase";
export const NAV_PLANS_AND_PRICING = "nav - plans and pricing";
export const NAV_SUBSCRIBE_BUTTON = "nav - subscribe button";
export const SELF_SELECT = "self-select";
export const HOME_PAGE_MODULE = "home page module";
export const MY_ACCOUNT = "my account";
export const DENY = "deny";
export const CREDIT_CARD = "credit card";
export const GIFT = "gift";
export const VISITOR = "visitor";
export const REGISTRANT = "registrant";
export const TRIALER = "trialer";
export const SUBSCRIBER = "subscriber";
export const NA_SUBSCRIBER = "na_subscriber";
export const LDS_SUBSCRIBER = "lds_subscriber";
export const NON_RECURRING_SUBSCRIBER = "non-recurring_subscriber";
export const API_ERROR = "ApiError";
export const HINTS = {
  NEW_PERSON_HINT: "new-person hint",
  RECORD_HINT: "record hint",
  HINT: "hint"
};

//merge-record
export const PREFEREED = "Preferred";
export const ALTERNATE = "Alternate";
export const CITATIONONLY = "citationonly";
export const KEEPASALTERNATE = "keepasalternate";
export const DISCARD = "discard";
export const PREVIOUSLY = "Previously";
export const DIFFERENT = "Different";
export const NEW = "New";
export const NONE = "None";

export const GENDER = "Gender";
export const RACE = "Race";
export const NAME = "Name";
export const BIRTHDATE = "Birth Date";
export const BIRTH = "BIRTH";
export const RESIDENCE = "Residence";
export const BIRTHLOCATION = "Birth Location";
export const RESIDENCEDATE = "Residence Date";
export const RESIDENCELOCATION = "Residence Location";
export const DEATHDATE = "Death Date";
export const DEATHLOCATION = "Death Location";

export const planDetail = {
  [EARLIER_PLAN_ID]: {
    planid: EARLIER_PLAN_ID,
    amount: 4.99,
    planName: "Storied Plus",
    basicName: "Storied Plus",
    amountText: "Monthly - $4.99/mo",
    prefix: "mo",
    timePeriod: 1,
    durationText: "Monthly"
  },
  [STAR_PLUS_PLAN_ID]: {
    planid: STAR_PLUS_PLAN_ID,
    amount: 4.99,
    planName: "Storied Early Access",
    basicName: "Storied Early Access",
    amountText: "Monthly - $4.99/mo",
    prefix: "mo",
    timePeriod: 2,
    durationText: "Monthly"
  },
  [BUNDLE_PLAN_ID_YEARLY]: {
    planid: BUNDLE_PLAN_ID_YEARLY,
    amount: 139.99,
    planName: "Storied Ultimate - Annual",
    basicName: "Storied Ultimate",
    amountText: "12 Month - $11.66/mo",
    prefix: "yr",
    timePeriod: 12
  },
  [BUNDLE_PLAN_ID_HALF_YEARLY]: {
    planid: BUNDLE_PLAN_ID_HALF_YEARLY,
    amount: 79.99,
    planName: "Storied Ultimate - Semi-Annual",
    basicName: "Storied Ultimate",
    amountText: "6 Month - $13.33/mo",
    prefix: "6 mo",
    timePeriod: 6
  },
  [BUNDLE_PLAN_ID_MONTHLY]: {
    planid: BUNDLE_PLAN_ID_MONTHLY,
    amount: 19.99,
    planName: "Storied Ultimate - Monthly",
    basicName: "Storied Ultimate",
    amountText: "1 Month - $19.99/mo",
    prefix: "mo",
    timePeriod: 1
  },
  [STORIED_PLUS_FREE_TRIAL_7]: {
    planid: STORIED_PLUS_FREE_TRIAL_7,
    amount: 4.99,
    planName: "Storied Plus - 7 Day Trial",
    basicName: "Storied Plus - 7 Day Trial",
    amountText: "1 Month - $4.99/mo",
    prefix: "mo",
    timePeriod: 1
  },
  [BUNDLE_FREE_TRIAL_PLAN_ID_YEARLY]: {
    planid: BUNDLE_FREE_TRIAL_PLAN_ID_YEARLY,
    amount: 139.99,
    planName: "Storied Ultimate (Annual) 7-Day Free Trial",
    basicName: "Storied Ultimate - 7 Day Trial",
    amountText: "12 Month - $11.66/mo",
    prefix: "yr",
    timePeriod: 12
  },
  [BUNDLE_FREE_TRIAL_PLAN_ID_HALF_YEARLY]: {
    planid: BUNDLE_FREE_TRIAL_PLAN_ID_HALF_YEARLY,
    amount: 79.99,
    planName: "Storied Ultimate (Semi-Annual) 7-Day Free Trial",
    basicName: "Storied Ultimate - 7 Day Trial",
    amountText: "6 Month - $13.33/mo",
    prefix: "6 mos",
    timePeriod: 6
  },
  [BUNDLE_FREE_TRIAL_PLAN_ID_MONTHLY]: {
    planid: BUNDLE_FREE_TRIAL_PLAN_ID_MONTHLY,
    amount: 19.99,
    planName: "Storied Ultimate (Monthly) 7-Day Free Trial",
    basicName: "Storied Ultimate - 7 Day Trial",
    amountText: "1 Month - $19.99/mo",
    prefix: "mo",
    timePeriod: 1
  },
  [ULTIMATE_OFFER_ROOT_TECH_OFFER_ID]: {
    planid: ULTIMATE_OFFER_ROOT_TECH_OFFER_ID,
    amount: 99.99,
    actualAmount: 139.99,
    planName: "Storied Ultimate (Annual),RootsTech Special Offer",
    basicName: "Storied Ultimate - Special Offer",
    amountText: "12 Month - $11.66/mo",
    prefix: "yr",
    timePeriod: 12
  },
  [NEW_PLUS_PLAN_MONTHLY]: {
    planid: NEW_PLUS_PLAN_MONTHLY,
    amount: 9.99,
    planName: "Storied Plus",
    basicName: "Storied Plus",
    amountText: "Monthly - $9.99/mo",
    prefix: "mo",
    timePeriod: 1,
    durationText: "Monthly"
  },
  [NEW_PLUS_PLAN_YEARLY]: {
    planid: NEW_PLUS_PLAN_YEARLY,
    amount: 83.88,
    planName: "Storied Plus",
    basicName: "Storied Plus",
    amountText: "12 Month - $6.99/mo",
    prefix: "yr",
    timePeriod: 12,
    durationText: "Annual"
  },
  [NEW_PLUS_PLAN_BOOK_YEARLY]: {
    planid: NEW_PLUS_PLAN_BOOK_YEARLY,
    amount: 83.88,
    planName: "Storied Plus",
    basicName: "Storied Plus",
    amountText: "12 Month - $6.99/mo",
    prefix: "yr",
    timePeriod: 12,
    durationText: "Annual"
  },
  [ULTIMATE_PLAN_ID_MONTHLY]: {
    planid: ULTIMATE_PLAN_ID_MONTHLY,
    amount: 22.99,
    planName: "Storied Ultimate - Monthly",
    basicName: "Storied Ultimate",
    amountText: "Monthly - $22.99/mo",
    prefix: "mo",
    timePeriod: 1
  },
  [ULTIMATE_PLAN_ID_SEMIANNUAL]: {
    planid: ULTIMATE_PLAN_ID_SEMIANNUAL,
    amount: 95.94,
    planName: "Storied Ultimate - Semi-Annual",
    basicName: "Storied Ultimate",
    amountText: "6 Month - $15.99/mo",
    prefix: "6 mo",
    timePeriod: 6
  },
  [ULTIMATE_PLAN_ID_ANNUAL]: {
    planid: ULTIMATE_PLAN_ID_ANNUAL,
    amount: 155.88,
    planName: "Storied Ultimate - Annual",
    basicName: "Storied Ultimate",
    amountText: "12 Month - $12.99/mo",
    prefix: "yr",
    timePeriod: 12
  },
  [ULTIMATE_PLAN_ID_ANNUAL_FREE_TRIAL]: {
    planid: ULTIMATE_PLAN_ID_ANNUAL_FREE_TRIAL,
    amount: 155.88,
    planName: "Storied Ultimate (Annual) 7-Day Free Trial",
    basicName: "Storied Ultimate - 7 Day Trial",
    amountText: "12 Month - $12.99/mo",
    prefix: "yr",
    timePeriod: 12
  },
  [ULTIMATE_PLAN_ID_SEMIANNUAL_FREE_TRIAL]: {
    planid: ULTIMATE_PLAN_ID_SEMIANNUAL_FREE_TRIAL,
    amount: 95.94,
    planName: "Storied Ultimate (Semi-Annual) 7-Day Free Trial",
    basicName: "Storied Ultimate - 7 Day Trial",
    amountText: "6 Month - $15.99/mo",
    prefix: "6 mos",
    timePeriod: 6
  },
  [ULTIMATE_PLAN_ID_ANNUAL_BLACK_FRIDAY_CYBER_MONDAY]: {
    planid: ULTIMATE_PLAN_ID_ANNUAL_BLACK_FRIDAY_CYBER_MONDAY,
    actualAmount: 155.88,
    amount: 95.88,
    planName: "Storied Ultimate (Annual) - Black Friday/Cyber Monday w/ StoriedBook™",
    basicName: "Storied Ultimate w/ StoriedBook™",
    amountText: "12 Month - $7.99/mo",
    prefix: "yr",
    timePeriod: 12
  },
  [ULTIMATE_PLAN_ID_ANNUAL_CYBER_MONDAY]: {
    planid: ULTIMATE_PLAN_ID_ANNUAL_CYBER_MONDAY,
    actualAmount: 83.88,
    amount: 59.88,
    planName: "Storied Plus (Annual) - Cyber Monday w/ StoriedBook™",
    basicName: "Storied Plus w/ StoriedBook™",
    amountText: "12 Month - $4.99/mo",
    prefix: "yr",
    timePeriod: 12
  },
  [ULTIMATE_PLAN_ID_ANNUAL_ANNIVERSARY]: {
    planid: ULTIMATE_PLAN_ID_ANNUAL_ANNIVERSARY,
    actualAmount: 155.88,
    amount: 95.88,
    planName: "Storied Ultimate (Annual)- 1-Year Anniversary w/ StoriedBook™",
    basicName: "1-Year Anniversary w/ StoriedBook™",
    amountText: "12 Month - $7.99/mo",
    prefix: "yr",
    timePeriod: 12
  },
  [ULTIMATE_PLAN_ID_ROOTS_TECH_24]: {
    planid: ULTIMATE_PLAN_ID_ROOTS_TECH_24,
    actualAmount: 155.88,
    amount: 99,
    planName: "Storied Ultimate (Annual) w/ StoriedBook™, RootsTech Offer",
    basicName: "1-Year rootsTech Offer",
    amountText: "12 Month - $99/yr",
    prefix: "yr",
    timePeriod: 12
  },
  [BUNDLE_FREE_TRIAL_PLUS_PLAN_ID_YEARLY]: {
    planid: BUNDLE_FREE_TRIAL_PLUS_PLAN_ID_YEARLY,
    amount: 83.88,
    planName: "Storied Plus (Annual) 7-Day Free Trial",
    basicName: "Storied Plus - 7 Day Trial",
    amountText: "12 Month - $6.99/mo",
    prefix: "yr",
    timePeriod: 12
  },
  [STORYBOOK_STUDIO_PLAN_ID]: {
    planid: STORYBOOK_STUDIO_PLAN_ID,
    amount: 79.99,
    planName: "StoriedBook™ Studio",
    basicName: "StoriedBook™ Studio",
    amountText: "12 Month access",
    prefix: "yr",
    timePeriod: 13
  },
  [OFFER_STORIED_ESSENTIAL]: {
    planid: OFFER_STORIED_ESSENTIAL,
    amount: 19.99,
    planName: "Storied Essential",
    basicName: "Storied Essential",
    amountText: "",
    prefix: "yr",
    timePeriod: 12
  },
  [OFFER_STORIED_PLUS]: {
    planid: OFFER_STORIED_PLUS,
    amount: 49.99,
    planName: "Storied Plus",
    basicName: "Storied Plus",
    amountText: "",
    prefix: "yr",
    timePeriod: 12
  },
  [OFFER_STORIED_ULTIMATE]: {
    planid: OFFER_STORIED_ULTIMATE,
    amount: 99.99,
    planName: "Storied Ultimate",
    basicName: "Storied Ultimate",
    amountText: "",
    prefix: "yr",
    timePeriod: 12,
    durationText: "Annual"
  },
  [TRIAL_OFFER_STORIED_ESSENTIAL]: {
    planid: TRIAL_OFFER_STORIED_ESSENTIAL,
    amount: 19.99,
    planName: "Storied Essential (Annual) 7-Day Free Trial",
    basicName: "Storied Essential - 7 Day Trial",
    amountText: "",
    prefix: "yr",
    timePeriod: 12
  },
  [TRIAL_OFFER_STORIED_PLUS]: {
    planid: TRIAL_OFFER_STORIED_PLUS,
    amount: 49.99,
    planName: "Storied Plus (Annual) 7-Day Free Trial",
    basicName: "Storied Plus - 7 Day Trial",
    amountText: "",
    prefix: "yr",
    timePeriod: 12
  },
  [TRIAL_OFFER_STORIED_ULTIMATE]: {
    planid: TRIAL_OFFER_STORIED_ULTIMATE,
    amount: 99.99,
    planName: "Storied Ultimate (Annual) 7-Day Free Trial",
    basicName: "Storied Ultimate - 7 Day Trial",
    amountText: "",
    prefix: "yr",
    timePeriod: 12
  }
};

export const giftPlanDetail = {
  [GIFT_PLUS_PLAN_ID]: {
    planid: GIFT_PLUS_PLAN_ID,
    planName: "Gift Redemption: Storied Plus (12-Month)",
    amount: 0.0
  },
  [GIFT_ULTIMATE_PLAN_ID_ANNUAL]: {
    planid: GIFT_ULTIMATE_PLAN_ID_ANNUAL,
    planName: "Gift Redemption: Storied Ultimate (12-Month)",
    amount: 0.0
  },
  [GIFT_STUDIO_PLAN_ID_ANNUAL]: {
    planid: GIFT_STUDIO_PLAN_ID_ANNUAL,
    planName: "Gift Redemption: Storied Ultimate (12-Month)",
    amount: 0.0
  }
};

export const planDesc = {
  1: "Monthly Plan",
  6: "Semi-annual plan",
  12: "Annual Plan"
};
export const planPeriodIntext = {
  1: "Monthly",
  6: "every 6 months",
  12: "every 12 months"
};
export const planPeriodDesc = {
  1: "1 Month",
  6: "6 Months",
  12: "12 Months"
};
export const HOMEPAGE_FEATURED_TOPIC_ID = "53c02936-6416-4cca-8788-56ec531641aa";
export const TEST_STORIED_EXAMPLE_STORY_URL =
  "/stories/view/0/9f5e0b12-6474-4850-86e2-e42a508e8f87";
export const STORIED_EXAMPLE_STORY_URL = "/stories/view/1/2409884f-ea3b-4773-a830-ea87050aaa7e";
export const SSDI = "us-social-security-death-index";
export const UNITED_STATES_FEDERAL_CENSUS = "1940-united-states-federal-census";

export const pathTypes = {
  stories: "story",
  groups: "group creation",
  "historical-record-search": "historical record",
  "newspaper-search": "newspaper record",
  "family-trees": "tree",
  "story-preview": "story share",
  invitation: "story share",
  "story-shared": "story share",
  sharestorylink: "story share",
  "group-invitation": "group invitee",
  lp: "newspaper record"
};
export const offerTypes = {
  ...pathTypes,
  plans: "nav - plans and pricing"
};

export const planNameArr = [
  "bundle",
  "trial",
  "plus-v1-monthly",
  "plus-v1-annually",
  "plus-v2-monthly",
  "plus-v2-annually",
  "bundleplus"
];

export const annualPlanIds = [
  ULTIMATE_OFFER_ROOT_TECH_OFFER_ID,
  NEW_PLUS_PLAN_YEARLY,
  NEW_PLUS_PLAN_BOOK_YEARLY,
  ULTIMATE_PLAN_ID_ANNUAL_WITH_BOOK,
  ULTIMATE_PLAN_ID_ANNUAL,
  ULTIMATE_PLAN_ID_ANNUAL_BLACK_FRIDAY_CYBER_MONDAY,
  ULTIMATE_PLAN_ID_ANNUAL_CYBER_MONDAY,
  ULTIMATE_PLAN_ID_ANNUAL_ANNIVERSARY,
  BUNDLE_FREE_TRIAL_PLAN_ID_YEARLY,
  ULTIMATE_PLAN_ID_ANNUAL_FREE_TRIAL,
  BUNDLE_PLAN_ID_YEARLY,
  BUNDLE_FREE_TRIAL_PLAN_ID_YEARLY
];

/**
 * Returns true if the specified plan ID represents an annual plan.
 */
export function isAnnualPlan(planId: string) {
  return annualPlanIds.includes(planId);
}

export enum PlanType {
  Plus,
  Ultimate
}

export enum PlanDuration {
  Month,
  Semiannual,
  Annual,
  Unknown
}
export type PlanInfo = {
  planId: string;
  planType: PlanType;
  planDuration: PlanDuration;
  planName: string;
};

/**
 * https://docs.google.com/spreadsheets/d/1DUCS3mG-cjMpY4MlzD1znrqoYSQCyhmT3sxBEKbXRGo/edit#gid=1837691539
 * TODO: Go to Recurly for this information.
 */
export const currentPlans: PlanInfo[] = [
  {
    planName: "Storied Plus (Monthly)",
    planId: "19578",
    planDuration: PlanDuration.Month,
    planType: PlanType.Plus
  },
  {
    planName: "Storied Ultimate (Monthly)",
    planId: "19580",
    planDuration: PlanDuration.Month,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Semi-Annual)",
    planId: "19581",
    planDuration: PlanDuration.Semiannual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Annual) w/ StoriedBook™",
    planId: "19582",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Plus (Early Access, 2-for-1 Monthly)",
    planId: "19583",
    planDuration: PlanDuration.Month,
    planType: PlanType.Plus
  },
  {
    planName: "Storied Ultimate (Storied Select - 15 Months Free)",
    planId: "19586",
    planDuration: PlanDuration.Unknown,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Free)",
    planId: "19587",
    planDuration: PlanDuration.Unknown,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Plus (Monthly) 7-Day Free Trial",
    planId: "",
    planDuration: PlanDuration.Month,
    planType: PlanType.Plus
  },
  {
    planName: "Storied Ultimate (Monthly) 7-Day Free Trial",
    planId: "19622",
    planDuration: PlanDuration.Month,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Semi-Annual) 7-Day Free Trial",
    planId: "19623",
    planDuration: PlanDuration.Semiannual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Annual) w/ StoriedBook™ 7-Day Free Trial",
    planId: "19624",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Annual), RootsTech Special Offer",
    planId: "19648",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Plus (Monthly)",
    planId: "19650",
    planDuration: PlanDuration.Month,
    planType: PlanType.Plus
  },
  {
    planName: "Storied Plus (Annual)",
    planId: "19651",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Plus
  },
  {
    planName: "Storied Plus with StoriedBook™ (Annual)",
    planId: "19652",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Plus
  },
  {
    planName: "Storied Ultimate (Annual) w/ StoriedBook™",
    planId: "19653",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Monthly)",
    planId: "19655",
    planDuration: PlanDuration.Month,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Semi-Annual)",
    planId: "19656",
    planDuration: PlanDuration.Semiannual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Annual) w/ StoriedBook™",
    planId: "19657",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Semi-Annual) 7-Day Free Trial",
    planId: "19658",
    planDuration: PlanDuration.Semiannual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Annual) w/ StoriedBook™ 7-Day Free Trial",
    planId: "19659",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Ultimate (Annual) - Black Friday/Cyber Monday w/ StoriedBook™",
    planId: "19662",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Ultimate
  },
  {
    planName: "Storied Plus (Annual) - Cyber Monday w/ StoriedBook™",
    planId: "19663",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Plus
  },
  {
    planName: "Storied Ultimate (Annual)- 1-Year Anniversary w/ StoriedBook™",
    planId: "19668",
    planDuration: PlanDuration.Annual,
    planType: PlanType.Ultimate
  }
];

export const planByPlanId = Object.fromEntries(currentPlans.map((plan) => [plan.planId, plan]));

export const IMAGE_SIZE_LIMIT_MB = 15;
export const IMAGE_SIZE_LIMIT_MB_FOR_UPLOAD = 50;
export const AUDIO_SIZE_LIMIT_MB = 50;
export const PDF_SIZE_LIMIT_MB = 50;

export const USER_ONBOARDING_CHECKLIST = [
  {
    key: "AccountCreated",
    title: "Create your account",
    status: true,
    topPriority: false,
    priority: 1,
    icon: "checkCircle"
  },
  {
    key: "TreeCreated",
    title: "Start your tree",
    status: false,
    topPriority: false,
    priority: 2,
    icon: "tree"
  },
  {
    key: "SearchCompleted",
    title: "Search the newspapers",
    status: false,
    topPriority: false,
    priority: 3,
    icon: "mIconNewspaper"
  },
  {
    key: "StoryCreated",
    title: "Tell your first story",
    status: false,
    topPriority: false,
    priority: 4,
    icon: "mIconBookPersion"
  },
  {
    key: "StoryBookVisited",
    title: "Check out StoriedBooks™",
    status: false,
    topPriority: false,
    priority: 5,
    icon: "iconOpenBook"
  }
];

export const RECORD_SEARCH_MONTHLY_PLANS = [
  {
    id: NEW_PLUS_PLAN_MONTHLY,
    planName: "Storied Plus",
    slug: "storied-plus",
    category: PLUSPLAN,
    amount: 9.99,
    duration: MONTHLY
  },
  {
    id: ULTIMATE_PLAN_ID_MONTHLY,
    planName: "Storied Ultimate",
    slug: "storied-ultimate",
    category: ULTIMATE_PLAN,
    amount: 22.99,
    duration: MONTHLY
  }
];
export const RECORD_SEARCH_YEARLY_PLANS = [
  {
    id: NEW_PLUS_PLAN_YEARLY,
    planName: "Storied Plus",
    slug: "storied-plus",
    category: PLUSPLAN,
    amount: 83.88,
    duration: ANNUALLY,
    discountAmount: 0
  },
  {
    id: ULTIMATE_PLAN_ID_ANNUAL,
    planName: "Storied Ultimate",
    slug: "storied-ultimate",
    category: ULTIMATE_PLAN,
    amount: 155.88,
    duration: ANNUALLY,
    discountAmount: 0
  }
];

export const NEWSPAPER_SEARCH_ULTIMATE_PLANS = [
  {
    id: ULTIMATE_PLAN_ID_MONTHLY,
    planName: "Storied Ultimate",
    category: ULTIMATE_PLAN,
    amount: 22.99,
    duration: MONTHLY,
    discountAmount: 0
  },
  {
    id: ULTIMATE_PLAN_ID_SEMIANNUAL,
    planName: "Storied Ultimate",
    category: ULTIMATE_PLAN,
    amount: 95.94,
    duration: SEMIANNUALLY,
    discountAmount: 0
  },
  {
    id: ULTIMATE_PLAN_ID_ANNUAL_FREE_TRIAL,
    planName: "Storied Ultimate",
    category: ULTIMATE_PLAN,
    amount: 155.88,
    duration: ANNUALLY,
    discountAmount: 0
  }
];

export const OfferPlanTypesList = [
  OFFER_STORIED_ESSENTIAL,
  OFFER_STORIED_PLUS,
  OFFER_STORIED_ULTIMATE,
  TRIAL_OFFER_STORIED_ESSENTIAL,
  TRIAL_OFFER_STORIED_PLUS,
  TRIAL_OFFER_STORIED_ULTIMATE
];

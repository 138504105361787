import {
  BUNDLEPLUSPLAN,
  BUNDLE_FREE_TRIAL_PLAN_ID_HALF_YEARLY,
  BUNDLE_FREE_TRIAL_PLAN_ID_YEARLY,
  BUNDLE_PLAN_ID_MONTHLY,
  ULTIMATE_PLAN,
  ULTIMATE_PLAN_ID_ANNUAL_FREE_TRIAL,
  ULTIMATE_PLAN_ID_MONTHLY,
  ULTIMATE_PLAN_ID_SEMIANNUAL_FREE_TRIAL,
} from "./constant";
import {
  decodeJWTtoken,
  getSubscriptionDetails,
} from "services";

export const PLUS_PLAN = "plus";
export const BUNDLE_PLAN = "bundle";
export const TRIAL_PLAN = "trial";

export const getRedirectURI = (planName, ultimateOfferPageUrl = "") => {
  switch (planName) {
    case "annually":
      return "/payment/bundle/trial/annually";
    case "semiannually":
      return "/payment/bundle/trial/semiannually";
    case "monthly":
      return "/payment/bundle/monthly";
    case "trial":
      return ultimateOfferPageUrl || "/payment/bundle/trial";
    case "bundle":
    case "bundleplus":
      return `/payment/${planName}`;
    default:
      return "/payment";
  }
};

export const getRedirectFreeTral = (planName) => {
  switch (planName) {
    case BUNDLE_PLAN:
    case ULTIMATE_PLAN:
      return "/payment/bundle";
    case TRIAL_PLAN:
      return "/payment/trial";
    case ULTIMATE_PLAN_ID_ANNUAL_FREE_TRIAL:
    case BUNDLE_FREE_TRIAL_PLAN_ID_YEARLY:
      return "/payment/bundle/trial/annually";
    case ULTIMATE_PLAN_ID_SEMIANNUAL_FREE_TRIAL:
    case BUNDLE_FREE_TRIAL_PLAN_ID_HALF_YEARLY:
      return "/payment/bundle/trial/semiannually";
    case ULTIMATE_PLAN_ID_MONTHLY:
    case BUNDLE_PLAN_ID_MONTHLY:
      return "/payment/bundle/monthly";
    case PLUS_PLAN:
      return "/payment";
    case BUNDLEPLUSPLAN:
      return `/payment/${planName}`;
    default:
      return "/payment";
  }
};

export const getActivePlanId = () => {
  const decodedToken = decodeJWTtoken();
  const subscriptionDetails = getSubscriptionDetails();
  if (subscriptionDetails?.planId) {
    return subscriptionDetails.planId;
  }
  if (
    decodedToken?.BookSubscriptionStatus?.toLowerCase() !== "expired" &&
    decodedToken?.BookPlanId
  ) {
    return decodedToken.BookPlanId;
  }
  if (
    decodedToken?.extension_SubscriptionStatus?.toLowerCase() !== "expired" &&
    decodedToken?.extension_PlanId
  ) {
    return decodedToken.extension_PlanId;
  }
  return null;
};

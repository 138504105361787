/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  QrCodePngData,
  QrCodePngParams,
  QrCodeQrCodeUrlData,
  QrCodeQrCodeUrlParams,
  QrCodeSvgData,
  QrCodeSvgParams
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class QrCode extends HttpClient {
  /**
   * No description
   *
   * @tags QRCode
   * @name QrCodePng
   * @request GET:/api/QRCode/png
   * @secure
   */
  qrCodePng = (query: QrCodePngParams, params: RequestParams = {}) =>
    this.request<QrCodePngData, any>({
      path: `/api/QRCode/png`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags QRCode
   * @name QrCodeSvg
   * @request GET:/api/QRCode/svg
   * @secure
   */
  qrCodeSvg = (query: QrCodeSvgParams, params: RequestParams = {}) =>
    this.request<QrCodeSvgData, any>({
      path: `/api/QRCode/svg`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags QRCode
   * @name QrCodeQrCodeUrl
   * @request GET:/api/QRCode/qr-code-url
   * @secure
   */
  qrCodeQrCodeUrl = (query: QrCodeQrCodeUrlParams, params: RequestParams = {}) =>
    this.request<QrCodeQrCodeUrlData, any>({
      path: `/api/QRCode/qr-code-url`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    });
}

import {
    ASSIGN_HOMEPERSON,
    ASSIGN_ERROR,
    SELECT_HOMEPERSON,
    SELECT_HOMEPERSON_ERROR,
    IMPORT_IN_PROGRESS,
    PENDING_NOTIFICATION_SNACKBAR,
    IMPORT_GEDCOM,
    INVALID_GEDCOM_FILE,
    GEDCOM_ERROR,
    SELECT_USER,
    PROGRESS_BAR,
    IMPORT_STATUS_ERROR,
    ADD_TREE,
    CLEAR_GEDCOM,
    CLOSE_HOMEPERSON_MODAL,
    SET_START_TREE_REQUEST_STATUS,
    REVERT_START_TREE_REQUEST_STATUS,
    IS_GEDCOM_UPLOADED,
    SET_IS_GEDCOM_UPLOADED,
    SURNAME_DISTRIBUTION,
    SURNAME_MEANING,
    RENDER_NEW_TREE,
    AUTH_WINDOW_CLOSED
} from "../constants";

const initialState = {
    newTreeId: null,
    startTree: null,
    selectedHomePerson: null,
    homePersons: [],
    progress: 0,
    importStatus: null,
    assigned: null,
    importInProgress: false,
    pendingNotificationSnackbar: false,
    startTreeRequestStatus: false,
    isGedcomUploaded: false,
    surnameDistribution: [],
    surnameMeaning: {},
    authWindowClosed: false,
    isInvalidGedcom: false,
};

const gedcom = (state = initialState, action = {}) => {
    const { type, payload } = action;
    const mapping = {
        [IMPORT_GEDCOM]: {
            ...state,
            homePersons:payload?.persons,
            newTreeId: payload?.treeId,
            selectedHomePerson: null
        },
        [ASSIGN_HOMEPERSON]: {
            ...state,
            assigned: true,
            importInProgress: false
        },
        [ASSIGN_ERROR]: {
            ...state,
            assigned: null
        },
        [IMPORT_IN_PROGRESS]: {
            ...state,
            importInProgress: true
        },
        [SELECT_HOMEPERSON]: {
            ...state,
            selectedHomePerson: payload
        },
        [SELECT_HOMEPERSON_ERROR]: {
            ...state,
            selectedHomePerson: null
        },
        [PENDING_NOTIFICATION_SNACKBAR]: {
            ...state,
            pendingNotificationSnackbar: payload
        },
        [GEDCOM_ERROR]: {
            ...state,
            homePersons: [],
            newTreeId: null,
            selectedHomePerson: null,
            progress: 0,
            importStatus: null,
            assigned: null,
        },
        [SELECT_USER]: {
            ...state,
            loading: false,
            startTree: null,
            homePersons: [],
            selectedHomePerson: null,
            progress: 0,
            importStatus: null,
            assigned: null,
        },
        [PROGRESS_BAR]: {
            ...state,
            progress: payload
        },
        [IMPORT_STATUS_ERROR]: {
            ...state,
            importStatus: null
        },
        [ADD_TREE]: {
            ...state,
            loading: false,
            startTree: payload,
            pendingNotificationSnackbar: false,
            homePersons: [],
            selectedHomePerson: null,
            progress: 0,
            importStatus: null,
            assigned: null,
        },
        [RENDER_NEW_TREE]: {
            ...state,
            loading: false,
            pendingNotificationSnackbar: false,
            homePersons: [],
            selectedHomePerson: null,
            progress: 0,
            importStatus: null,
            assigned: null,
        },
        [CLEAR_GEDCOM]: {
            ...state,
            startTree: null
        },
        [IS_GEDCOM_UPLOADED]: {
            ...state,
            isGedcomUploaded: true,
            treeProgressPayload: null
        },
        [SET_IS_GEDCOM_UPLOADED]: {
            ...state,
            isGedcomUploaded: false,
        },
        [CLOSE_HOMEPERSON_MODAL]: {
            ...state,
            selectedHomePerson: null,
        },
        [SET_START_TREE_REQUEST_STATUS]: {
            ...state,
            startTreeRequestStatus: true
        },
        [REVERT_START_TREE_REQUEST_STATUS]: {
            ...state,
            startTreeRequestStatus: false,
            startTree: null
        },
        [SURNAME_DISTRIBUTION]: {
            ...state,
            surnameDistribution: payload
        },
        [SURNAME_MEANING]: {
            ...state,
            surnameMeaning: payload
        },
        [AUTH_WINDOW_CLOSED]: {
            ...state,
            authWindowClosed: payload
        },
        [INVALID_GEDCOM_FILE]: {
            ...state,
            isInvalidGedcom: payload
        }
    };
    return mapping[type] || state;
};

export default gedcom;
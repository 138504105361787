import {
  decodeJWTtoken,
  getLdsSubscriptionFlag,
  getOwner,
  getSubscriptionDetails,
  removeGroupPage,
  removeOfferEvent,
  removeRegisterType,
  removeSearchCollectionId
} from "services";
import {
  BACK_END,
  CREATOR_PLAN,
  EARLY,
  ECOMMERCE,
  ESSENTIAL,
  FREE_PLAN,
  FREE_TRIAL,
  GIFT,
  giftPlanDetail,
  LDS_SUBSCRIBER,
  NA_SUBSCRIBER,
  NO_ACCOUNT,
  NON_RECURRING_SUBSCRIBER,
  NONE,
  PAID_SUBSCRIPTION,
  planDetail,
  PLUSPLAN,
  PURCHASE,
  PURCHASE_FAILURE,
  REGISTRANT,
  STORIED,
  STORIED_LDS_ACCOUNT_PLAN_ID,
  SUBSCRIBER,
  TRIALER,
  TWO_FOR_ONE_USERTYPE,
  ULTIMATE_PLAN,
  USD,
  VISITOR
} from "utils/constant";

const noneVal = NONE.toLowerCase();

export const dataLayerregistration = (userId, regType) => {
  window.dataLayer = window.dataLayer || [];
  // dataLayerregistration
  window.dataLayer.push({
    event: "registration",
    regType,
    user_id: userId
  });
  removeRegisterType();
  removeGroupPage();
};

export const dataLayerOffer = (
  source,
  type,
  userId = false,
  denyContentId = null,
  isNPAPAnalyticsEnabled = false
) => {
  if (isNPAPAnalyticsEnabled) {
    console.log(" new-event: 'view_offer'", {
      event_type: type,
      event_source: source,
      ...(userId && { user_id: userId }),
      ...(denyContentId && { deny_content_id: denyContentId })
    });
  } else {
    console.log("old-event: offer");
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: isNPAPAnalyticsEnabled ? "view_offer" : "offer",
    event_category: "ecommerce",
    event_type: type,
    event_source: source,
    ...(userId && { user_id: userId }),
    ...(denyContentId && { deny_content_id: denyContentId })
  });
  removeOfferEvent();
  removeSearchCollectionId();
};

export const dataLayerGroup = (userId, userType) => {
  console.log("old-event: group_creation");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "group_creation",
    eventCategory: "engagement",
    eventAction: BACK_END,
    user_id: userId,
    brand: STORIED,
    userType: userType
  });
};

export const dataLayerOfferModal = (userId, planname, userType) => {
  console.log("old-event: eec.offer_modal");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eec.offer_modal",
    eventCategory: ECOMMERCE,
    eventAction: BACK_END,
    ecommerce: {
      currencyCode: USD,
      products: [
        {
          brand: "storied",
          name: planname,
          userType: userType,
          ...(userId && { user_id: userId })
        }
      ]
    }
  });
};

export const dataLayerOfferPage = (userId, userType) => {
  console.log("old-event: eec.offer_page");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eec.offer_page",
    eventCategory: ECOMMERCE,
    eventAction: BACK_END,
    ecommerce: {
      currencyCode: USD,
      products: [
        {
          brand: STORIED,
          userType: userType,
          ...(userId && { user_id: userId })
        }
      ]
    }
  });
};

export const dataLayerDurationPage = (userId, userType) => {
  console.log("old-event: eec.duration_page");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eec.duration_page",
    eventCategory: ECOMMERCE,
    eventAction: BACK_END,
    ecommerce: {
      currencyCode: USD,
      products: [
        {
          brand: STORIED,
          userType: userType ? userType : NO_ACCOUNT,
          ...(userId && { user_id: userId })
        }
      ]
    }
  });
};

export const dataLayerPaymentDetails = (
  userId,
  planId,
  name,
  amount,
  duration,
  userType,
  planName
) => {
  console.log("old-event: eec.payment_page");
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eec.payment_page",
    eventCategory: ECOMMERCE,
    eventAction: BACK_END,
    ecommerce: {
      currencyCode: USD,
      products: [
        {
          id: planId,
          ...(name && { name: name }),
          brand: STORIED,
          quantity: 1,
          ...(amount && { price: amount }),
          ...(duration && { duration: duration }),
          userType: planName === EARLY ? TWO_FOR_ONE_USERTYPE : userType,
          user_id: userId
        }
      ]
    }
  });
};

export const dataLayerOrderSummery = (
  userId,
  planId,
  selectPlanName,
  price,
  duration,
  userType,
  planName
) => {
  console.log("old-event: eec.order_summary_page");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eec.order_summary_page",
    eventCategory: ECOMMERCE,
    eventAction: BACK_END,
    ecommerce: {
      currencyCode: USD,
      products: [
        {
          id: planId,
          ...(selectPlanName && { name: selectPlanName }),
          brand: STORIED,
          quantity: 1,
          ...(price && { price: price }),
          ...(duration && { duration: duration }),
          userType: planName === EARLY ? TWO_FOR_ONE_USERTYPE : userType,
          ...(userId && { user_id: userId })
        }
      ]
    }
  });
};

export const getPlanName = (basicPlan) => {
  let planName;
  const token = decodeJWTtoken();
  if (token) {
    const { extension_WebsiteId, extension_UserType } = token;
    switch (basicPlan) {
      case "Storied Essential":
        planName = ESSENTIAL;
        break;
      case "Storied Plus":
        planName = PLUSPLAN;
        break;
      case "Storied Ultimate":
        planName = ULTIMATE_PLAN;
        break;
      default:
        if (
          extension_WebsiteId === "1" &&
          (extension_UserType === "Free" ||
            extension_UserType === "free" ||
            extension_UserType === "Paid")
        ) {
          planName = "newspaperarchive";
        } else {
          planName = FREE_PLAN;
        }
    }
  }
  return planName;
};

export const basicPlanName = (plans, planDetails) => {
  let getPlan;
  if (plans !== null) {
    plans?.find((basicPlan) => {
      if (basicPlan?.planid === planDetails?.planId) getPlan = basicPlan?.basicName;
      return getPlan;
    });
  }
  return getPlan;
};

export const getDataLayerDetails = () => {
  const subscriptionDetails = getSubscriptionDetails();
  const planObj = Object?.values(planDetail);
  const basicName = basicPlanName(planObj, subscriptionDetails);
  const planName = getPlanName(basicName);
  return { basicName, planName };
};

const isLDSUser = (planId) => {
  const isLdsAccountPlanActive =
    getLdsSubscriptionFlag() || Number(planId) === STORIED_LDS_ACCOUNT_PLAN_ID;
  return isLdsAccountPlanActive;
};

const getContentSubType = (planNameVal) => {
  let contentSubType = noneVal;
  const planName = planNameVal.toLowerCase();
  if (planName.includes("storied essential")) {
    contentSubType = ESSENTIAL;
  } else if (planName.includes("storied plus")) {
    contentSubType = PLUSPLAN;
  } else if (planName.includes("storied ultimate")) {
    contentSubType = ULTIMATE_PLAN;
  }

  return contentSubType;
};

export const getUserDetails = (currentPlanName) => {
  let contentSubType = noneVal;
  let featureSubType = noneVal;
  let userType = VISITOR;
  const token = decodeJWTtoken();
  const userId = getOwner();

  if (token) {
    const {
      extension_WebsiteId,
      extension_UserType,
      extension_SubscriptionStatus,
      extension_PlanId,
      BookSubscriptionStatus,
      BookPlanId
    } = token;
    const EXPIRED = "Expired";
    const isStudioPlanExpired = BookSubscriptionStatus === EXPIRED;
    featureSubType = isStudioPlanExpired || !BookPlanId ? noneVal : CREATOR_PLAN;

    if (!currentPlanName) {
      const planObj = Object?.values(planDetail);
      const currentPlanDetails = planObj.find((plan) => plan?.planid === extension_PlanId);
      currentPlanName = currentPlanDetails?.planName;
    }

    const isContentPlanExpired = extension_SubscriptionStatus === EXPIRED;
    const isFreePlan = extension_UserType === "Free" || extension_UserType === FREE_PLAN;
    const isTrialPlan = currentPlanName?.toLowerCase().includes("trial");
    const giftPlanObj = Object?.values(giftPlanDetail);
    const currentGiftPlanDetails = giftPlanObj.find((plan) => plan?.planid === extension_PlanId);
    const isLDSAccount = isLDSUser(extension_PlanId);

    if (!isContentPlanExpired && extension_PlanId && isLDSAccount) {
      contentSubType = FREE_PLAN;
      userType = LDS_SUBSCRIBER;
    } else {
      if (currentPlanName && !isContentPlanExpired) {
        contentSubType = getContentSubType(currentPlanName);
      }

      if (!isContentPlanExpired || !isStudioPlanExpired) {
        if (isTrialPlan) {
          userType = TRIALER;
        } else if (!isContentPlanExpired && currentGiftPlanDetails) {
          contentSubType = getContentSubType(currentGiftPlanDetails?.planName);
          userType = NON_RECURRING_SUBSCRIBER;
        } else if (BookPlanId && !isStudioPlanExpired && !currentGiftPlanDetails && !currentPlanName) {
          userType = NON_RECURRING_SUBSCRIBER;
        } else if (
          !isContentPlanExpired &&
          !isStudioPlanExpired &&
          extension_WebsiteId === "1" &&
          (isFreePlan || extension_UserType === "Paid")
        ) {
          userType = NA_SUBSCRIBER;
        } else if (
          (isContentPlanExpired || !extension_PlanId) &&
          (isStudioPlanExpired || !BookPlanId)
        ) {
          userType = REGISTRANT;
        } else {
          userType = SUBSCRIBER;
        }
      } else {
        userType = REGISTRANT;
      }
    }
  }

  return {
    contentSubType,
    userType,
    userId,
    featureSubType
  };
};

const commonItemDetails = {
  item_brand: STORIED,
  currency: USD
};

export const getItemCategory = (slug) => {
  let category = slug ? slug.toLowerCase() : "";
  if (category) {
    if (category.includes(PLUSPLAN)) {
      category = PLUSPLAN;
    } else if (category.includes(ESSENTIAL)) {
      category = ESSENTIAL;
    } else if (category.includes(ULTIMATE_PLAN)) {
      category = ULTIMATE_PLAN;
    }
  }

  return category;
};

export const getItemVarient = (timePeriod) =>
  timePeriod === 12 ? "annual" : timePeriod === 6 ? "semi-annual" : "monthly";

const getItemListName = (type) => {
  let itemListName = "Grid";
  switch (type) {
    case "toggle":
      itemListName = "Toggle";
      break;
    case "Offer_page_202501":
      itemListName = "Offer Page Jan 2025";
      break;
    case GIFT:
      itemListName = "Gift Products";
      break;
    default:
      itemListName = "Grid";
      break;
  }

  return itemListName;
};

export const dataLayerViewItemListPage = ({
  itemList,
  itemSlug,
  category2 = FREE_TRIAL,
  itemType = null
}) => {
  const { userId, userType, contentSubType, featureSubType } = getUserDetails();
  let filteredItemList = itemList;
  if (itemSlug) {
    filteredItemList = itemList.filter((item) => item.slug === itemSlug);
  }

  const itemListCommonData = !itemSlug && {
    item_list_id: category2 === GIFT ? "gift_products" : itemType,
    item_list_name: getItemListName(category2 === GIFT ? GIFT : itemType)
  };

  const items = filteredItemList.map((item) => {
    const category = getItemCategory(item.planName);
    const itemObj = {
      item_id: category2 === GIFT ? item.couponItemCode : item.id,
      item_name: item.planName,
      item_category: item.category ?? category,
      item_category2: category2,
      item_variant: item.duration ?? "annual",
      price: category2 === FREE_TRIAL ? 0 : item.amount,
      discount: item.discountAmount ?? 0,
      quantity: 1,
      ...commonItemDetails,
      ...itemListCommonData
    };
    return itemObj;
  });

  const event = {
    event: itemSlug ? "view_item" : "view_item_list",
    user_type: userType,
    content_sub_type: contentSubType,
    feature_sub_type: featureSubType,
    ...(userId && { user_id: userId }),
    ecommerce: {
      ...itemListCommonData,
      items: items
    }
  };

  console.log("event", event);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(event);
};

export const dataLayerCheckoutPage = ({ item, category, eventType, category2 = FREE_TRIAL }) => {
  /* NOTE: This method will cover below purchase flow events
  // "add_to_cart" and "begin_checkout" */
  const { userId, userType, contentSubType, featureSubType } = getUserDetails();
  const itemCategory = getItemCategory(item.name) ?? null;
  const itemObj = {
    item_id: item.id,
    item_name: item.name,
    item_category: category ?? itemCategory,
    item_category2: category2,
    item_variant: item.duration ?? "annual",
    price: category2 === FREE_TRIAL ? 0 : item.price,
    discount: item.discount ?? 0,
    quantity: 1,
    ...commonItemDetails
  };

  const event = {
    event: eventType,
    user_type: userType,
    content_sub_type: contentSubType,
    feature_sub_type: featureSubType,
    ...(userId && { user_id: userId }),
    ecommerce: {
      currency: USD,
      value: itemObj.price,
      items: [itemObj]
    }
  };

  console.log("event", event);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(event);
};

export const dataLayerPurchaseFlowEvents = ({
  item,
  category = null,
  category2 = null,
  paymentType,
  eventType,
  transactionId = "",
  failureReason = "",
  orderCouponCode,
  taxAmt,
  recurlySubscriptionId
}) => {
  /* NOTE: This method will cover below purchase flow events
  "add_shipping_info", "add_payment_info", "purchase_attempt", "purchase_failure", "purchase" */
  const planName = eventType === PURCHASE ? item.name : null;
  const { userId, userType, contentSubType, featureSubType } = getUserDetails(planName);
  const itemCategory = getItemCategory(item.name);
  const category2Val = item.name.toLowerCase().includes("trial") ? FREE_TRIAL : PAID_SUBSCRIPTION;
  const isFreeTrial = category2 === FREE_TRIAL || category2Val === FREE_TRIAL;
  const transaction_id = (isFreeTrial ? recurlySubscriptionId : transactionId) || null;

  const itemObj = {
    item_id: item.id,
    item_name: item.name,
    item_category: category ?? itemCategory,
    item_category2: category2 ?? category2Val,
    item_variant: item.duration ?? "annual",
    price: isFreeTrial ? 0 : item.price,
    discount: item.discount ?? 0,
    coupon: item.couponCode ?? null,
    quantity: 1,
    ...commonItemDetails
  };

  const event = {
    event: eventType,
    user_type: userType,
    content_sub_type: contentSubType,
    feature_sub_type: featureSubType,
    ...(userId && { user_id: userId }),
    ...(eventType === PURCHASE_FAILURE && { failure_reason: failureReason }),
    ...(eventType === PURCHASE && { recurly_subscription_id: recurlySubscriptionId ?? null }),
    ecommerce: {
      ...(eventType === PURCHASE && { transaction_id }),
      currency: USD,
      value: itemObj.price,
      coupon: orderCouponCode || null,
      tax: taxAmt || 0,
      payment_type: paymentType,
      items: [itemObj]
    }
  };

  console.log("event", event);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(event);
};

import { ForwardedRef, forwardRef } from "react";
import { Link } from "react-router-dom";
import Translator from "../Translator";
import "./index.css";

export type TextColor =
  | "text-blue-4"
  | "gray-8"
  | "default"
  | "primary"
  | "secondary"
  | "danger"
  | "brand-color"
  | "white-color"
  | "black-color"
  | "gray-1"
  | "gray-2"
  | "gray-3"
  | "gray-4"
  | "gray-5"
  | "gray-6"
  | "gray-7"
  | "text-gray-2"
  | "text-gray-3"
  | "text-gray-4"
  | "text-gray-5"
  | "text-gray-6"
  | "text-gray-7"
  | "skyblue-1"
  | "text-skyblue-1"
  | "text-skyblue-5"
  | "text-skyblue-6"
  | "text-skyblue-7"
  | "blue-1"
  | "blue-2"
  | "blue-3"
  | "blue-4"
  | "blue-5"
  | "blue-6"
  | "green-5"
  | "green-6"
  | "text-purple-5"
  | "text-purple-6"
  | "text-purple-7"
  | "text-purple-8"
  | "text-maroon-5"
  | "text-orange-6"
  | "text-orange-7";

type TypographyType = "defaultText" | "link" | undefined;

export type TypographyProps<T extends TypographyType> = {
  extraClass?: string;
  type?: T;
  text?: TextColor;
  size?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 28 | "2xl" | 32 | 40 | 48 | 50;
  weight?: "regular" | "light" | "medium" | "bold" | "lyon-regular" | "lyon-medium";
  children?: any;
  href?: string;
  target?: string;
  tkey?: string;
};

const Typography = forwardRef(
  <T extends TypographyType>(
    {
      type,
      text = "default",
      size = 16,
      weight = "regular",
      children = "",
      href = "https://www.storied.com/",
      target,
      tkey = "",
      extraClass = ""
    }: TypographyProps<T>,
    ref: ForwardedRef<T extends "defaultText" | undefined ? HTMLSpanElement : HTMLAnchorElement>
  ) => {
    const CheckVarient = () => {
      switch (type) {
        case "defaultText":
        default:
          return "defaultText";
        case "link":
          return "link-style";
      }
    };
    const varient = CheckVarient();

    const CheckFontColor = () => {
      switch (text) {
        case "default":
          return "default-color";
        case "primary":
          return "primary-color";
        case "secondary":
          return "secondary-color";
        case "danger":
          return "danger-color";
        case "brand-color":
          return "brand-color";
        case "white-color":
          return "white-color";
        case "black-color":
          return "black-color";
        case "green-5":
          return "text-green-5";
        case "green-6":
          return "text-green-6";
        case "blue-1":
          return "text-blue-1";
        case "blue-2":
          return "text-blue-2";
        case "blue-3":
          return "text-blue-3";
        case "blue-4":
          return "text-blue-4";
        case "blue-5":
          return "text-blue-5";
        case "blue-6": 
          return "text-blue-6";
        case "gray-1":
          return "gray-1";
        case "gray-2":
          return "text-gray-2";
        case "gray-3":
          return "text-gray-3";
        case "gray-4":
          return "gray-4";
        case "gray-5":
          return "gray-5";
        case "gray-6":
          return "gray-6";
        case "gray-7":
          return "gray-7";
        case "gray-8":
          return "gray-8";
        case "text-gray-2":
          return "text-gray-2";
        case "text-gray-3":
          return "text-gray-3";
        case "text-gray-4":
          return "text-gray-4";
        case "text-gray-5":
          return "text-gray-5";
        case "text-gray-6":
          return "text-gray-6";
        case "text-gray-7":
          return "text-gray-7";
        case "text-skyblue-1":
          return "text-skyblue-1";
        case "text-skyblue-5":
          return "text-skyblue-5";
        case "text-skyblue-6":
          return "text-skyblue-6";
        case "text-skyblue-7":
          return "text-skyblue-7";
        case "text-blue-4":
          return "text-blue-4";
        case "text-purple-5":
          return "text-purple-5";
        case "text-purple-6":
          return "text-purple-6";
        case "text-purple-7":
          return "text-purple-7";
        case "text-purple-8":
          return "text-purple-8";
        case "text-maroon-5":
          return "text-maroon-5";
        case "text-orange-6":
          return "text-orange-6";
        case "text-orange-7":
          return "text-orange-7";

        default:
          return "";
      }
    };
    const fontColor = CheckFontColor();

    const CheckTypoSize = () => {
      switch (size) {
        case 10:
          return "text-vs";
        case 12:
          return "text-xs";
        case 14:
          return "text-sm";
        case 16:
          return "text-base";
        case 18:
          return "text-lg";
        case 20:
          return "text-xl";
        case 24:
          return "text-lg md:text-2xl";
        case "2xl":
          return "text-2xl";
        case 28:
          return "text-1.75";
        case 32:
          return "text-4xl";
        case 40:
          return "text-4.5xl";
        case 48:
          return "text-2xl md:text-4xl xl:text-5xl";
        case 50:
          return "text-5xl";
        default:
          return "";
      }
    };
    const typoSize = CheckTypoSize();

    const CheckTypoWeight = () => {
      switch (weight) {
        case "regular":
          return "typo-font-regular";
        case "light":
          return "typo-font-light";
        case "medium":
          return "typo-font-medium";
        case "bold":
          return "typo-font-bold";
        case "lyon-regular":
          return "lyon-font-regular";
        case "lyon-medium":
          return "lyon-font-medium";
        default:
          return "typo-font-regular";
      }
    };
    const typoWeight = CheckTypoWeight();

    const getText = () => {
      return tkey !== "" ? <Translator tkey={tkey} /> : children;
    };

    if (type === "link") {
      const isInternalLink = href.startsWith("/");
      if (isInternalLink) {
        return (
          <Link
            ref={ref as ForwardedRef<HTMLAnchorElement>}
            to={href}
            target={target}
            className={`${varient} ${fontColor} ${typoSize} ${typoWeight} ${extraClass}`}
          >
            {getText()}
          </Link>
        );
      } else {
        return (
          <a
            ref={ref as ForwardedRef<HTMLAnchorElement>}
            href={href}
            target={target}
            className={`${varient} ${fontColor} ${typoSize} ${typoWeight} ${extraClass}`}
          >
            {getText()}
          </a>
        );
      }
    } else
      return (
        <span
          ref={ref as ForwardedRef<HTMLSpanElement>}
          className={`${varient} ${fontColor} ${typoSize} ${typoWeight} ${extraClass}`}
        >
          {getText()}
        </span>
      );
  }
);

export default Typography;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  V2VideoReportData,
  V2VideoReportPayload,
  V2VideoUploadUrlData,
  V2VideoUploadUrlPayload,
  V2VideoValidateData,
  V2VideoValidatePayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Video extends HttpClient {
  /**
   * No description
   *
   * @tags Video
   * @name V2VideoValidate
   * @request POST:/api/v2/Video/validate
   * @secure
   */
  v2VideoValidate = (data: V2VideoValidatePayload, params: RequestParams = {}) =>
    this.request<V2VideoValidateData, any>({
      path: `/api/v2/Video/validate`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Video
   * @name V2VideoUploadUrl
   * @request GET:/api/v2/Video/upload-url
   * @secure
   */
  v2VideoUploadUrl = (data: V2VideoUploadUrlPayload, params: RequestParams = {}) =>
    this.request<V2VideoUploadUrlData, any>({
      path: `/api/v2/Video/upload-url`,
      method: "GET",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Video
   * @name V2VideoReport
   * @request POST:/api/v2/Video/report
   * @secure
   */
  v2VideoReport = (data: V2VideoReportPayload, params: RequestParams = {}) =>
    this.request<V2VideoReportData, any>({
      path: `/api/v2/Video/report`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    });
}

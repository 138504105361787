/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdminStoryAdminstoriescountData,
  AdminStoryAllstoriesData,
  AdminStoryAssignData,
  AdminStoryCompleteAdminReviewForCommentData,
  AdminStoryCompleteAdminReviewForCommentPayload,
  AdminStoryCompleteadminreviewData,
  AdminStoryCompleteadminreviewPayload,
  AdminStoryData,
  AdminStoryRemoveData,
  AdminStoryReportedData,
  AdminStoryReportedParams,
  AdminStoryReportedcommentsData,
  Story2Data,
  StoryAddassociationData,
  StoryAssistantData,
  StoryAssistantParams,
  StoryAttachAudioData,
  StoryAttachAudioPayload,
  StoryAutosaveData,
  StoryAutosavePayload,
  StoryAutosaveV1Data,
  StoryAutosaveV1Payload,
  StoryAutosaveV2Data,
  StoryAutosaveV2Payload,
  StoryAutosaveV3Data,
  StoryAutosaveV3Payload,
  StoryAutosavestorypetinfoData,
  StoryAutosavestorypetinfoPayload,
  StoryCheckstorypermissionData,
  StoryCheckstorypermissionvaluesData,
  StoryCheckuserassociationData,
  StoryCitation2Data,
  StoryCitationData,
  StoryCitationPayload,
  StoryCommentData,
  StoryCommentPayload,
  StoryCommentcountData,
  StoryCommentsData,
  StoryCreatestoryV1Data,
  StoryCreatestoryV1Payload,
  StoryCreatestoryV2Data,
  StoryCreatestoryV2Payload,
  StoryData,
  StoryDeleteData,
  StoryEditcitationData,
  StoryEditcitationPayload,
  StoryEditstoryData,
  StoryEditstoryPayload,
  StoryEditstoryV1Data,
  StoryEditstoryV1Payload,
  StoryEditstoryV2Data,
  StoryEditstoryV2Payload,
  StoryEditstoryV3Data,
  StoryEditstoryV3Payload,
  StoryInferredlocationidforstoryandmediaData,
  StoryInferredlocationidforstoryandmediaParams,
  StoryLikeData,
  StoryMiloData,
  StoryMiloPayload,
  StoryPayload,
  StoryPreviewstoryData,
  StoryPublishOldStoriesData,
  StoryPublishOneOldStoryData,
  StoryReloadStoryPromptsData,
  StoryRemoveCitationData,
  StoryRemovetaggedpersonData,
  StoryRemovetaggedpersonPayload,
  StoryReportData,
  StoryReportPayload,
  StoryReportcommentData,
  StoryReportcommentPayload,
  StorySharestoryData,
  StorySharestoryPayload,
  StorySmartPromptsData,
  StorySmartPromptsParams,
  StoryStoryDetailForCollectionData,
  StoryStoryDetailForFeedData,
  StoryStoryDetailForGroupFeedData,
  StoryStoryPromptsData,
  StoryStorydetailforbookpreviewData,
  StoryStorylikesdetailData,
  StoryUnlikeData,
  StoryUpdateprivacyData,
  StoryUpdateprivacyPayload,
  StoryViewstoryData,
  V2StoryCollectionDetailData,
  V2StoryCreateData,
  V2StoryCreatePayload,
  V2StoryData,
  V2StoryDetailData,
  V2StoryEditData,
  V2StoryEditPayload,
  V2StoryFeedDetailData,
  V2StoryGroupFeedDetailData,
  V2StoryPromptCategoriesData,
  V2StoryPromptsData,
  V2StoryPromptsParams,
  V2StoryReducedDetailData,
  V2StorySearchAggregateData,
  V2StorySearchAggregatePayload,
  V2StorySearchData,
  V2StorySearchDetailsData,
  V2StorySearchDetailsPayload,
  V2StorySearchPayload,
  V2StorySearchPeopleData,
  V2StorySearchPeoplePayload,
  V2StoryStoriesByPromptData,
  V3Story2Data,
  V3Story2Payload,
  V3Story3Data,
  V3Story4Data,
  V3Story4Params,
  V3StoryData,
  V3StoryGroups2Data,
  V3StoryGroups2Payload,
  V3StoryGroupsData,
  V3StoryGroupsPayload,
  V3StoryMedia2Data,
  V3StoryMedia2Payload,
  V3StoryMediaCropData,
  V3StoryMediaCropPayload,
  V3StoryMediaData,
  V3StoryMediaOrderData,
  V3StoryMediaOrderPayload,
  V3StoryMediaPayload,
  V3StoryPayload,
  V3StoryPeople2Data,
  V3StoryPeople2Payload,
  V3StoryPeopleData,
  V3StoryPeoplePayload,
  V3StoryPets2Data,
  V3StoryPets2Payload,
  V3StoryPetsData,
  V3StoryPetsPayload,
  V3StorySaveData,
  V3StorySaveParams
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Story extends HttpClient {
  /**
   * No description
   *
   * @tags Story
   * @name Story
   * @request POST:/api/Story
   * @deprecated
   * @secure
   */
  story = (data: StoryPayload, params: RequestParams = {}) =>
    this.request<StoryData, any>({
      path: `/api/Story`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryAutosave
   * @request PUT:/api/Story/autosave
   * @deprecated
   * @secure
   */
  storyAutosave = (data: StoryAutosavePayload, params: RequestParams = {}) =>
    this.request<StoryAutosaveData, any>({
      path: `/api/Story/autosave`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryEditstory
   * @request PUT:/api/Story/editstory
   * @deprecated
   * @secure
   */
  storyEditstory = (data: StoryEditstoryPayload, params: RequestParams = {}) =>
    this.request<StoryEditstoryData, any>({
      path: `/api/Story/editstory`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryCreatestoryV1
   * @request POST:/api/Story/createstoryV1
   * @deprecated
   * @secure
   */
  storyCreatestoryV1 = (data: StoryCreatestoryV1Payload, params: RequestParams = {}) =>
    this.request<StoryCreatestoryV1Data, any>({
      path: `/api/Story/createstoryV1`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryAutosaveV1
   * @request PUT:/api/Story/autosaveV1
   * @deprecated
   * @secure
   */
  storyAutosaveV1 = (data: StoryAutosaveV1Payload, params: RequestParams = {}) =>
    this.request<StoryAutosaveV1Data, any>({
      path: `/api/Story/autosaveV1`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryAutosavestorypetinfo
   * @request PUT:/api/Story/autosavestorypetinfo
   * @secure
   */
  storyAutosavestorypetinfo = (
    data: StoryAutosavestorypetinfoPayload,
    params: RequestParams = {}
  ) =>
    this.request<StoryAutosavestorypetinfoData, any>({
      path: `/api/Story/autosavestorypetinfo`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryEditstoryV1
   * @request PUT:/api/Story/editstoryV1
   * @deprecated
   * @secure
   */
  storyEditstoryV1 = (data: StoryEditstoryV1Payload, params: RequestParams = {}) =>
    this.request<StoryEditstoryV1Data, any>({
      path: `/api/Story/editstoryV1`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryCreatestoryV2
   * @request POST:/api/Story/createstoryV2
   * @deprecated
   * @secure
   */
  storyCreatestoryV2 = (data: StoryCreatestoryV2Payload, params: RequestParams = {}) =>
    this.request<StoryCreatestoryV2Data, any>({
      path: `/api/Story/createstoryV2`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryEditstoryV2
   * @request PUT:/api/Story/editstoryV2
   * @deprecated
   * @secure
   */
  storyEditstoryV2 = (data: StoryEditstoryV2Payload, params: RequestParams = {}) =>
    this.request<StoryEditstoryV2Data, any>({
      path: `/api/Story/editstoryV2`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryAutosaveV2
   * @request PUT:/api/Story/autosaveV2
   * @deprecated
   * @secure
   */
  storyAutosaveV2 = (data: StoryAutosaveV2Payload, params: RequestParams = {}) =>
    this.request<StoryAutosaveV2Data, any>({
      path: `/api/Story/autosaveV2`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryEditstoryV3
   * @request PUT:/api/Story/editstoryV3
   * @deprecated
   * @secure
   */
  storyEditstoryV3 = (data: StoryEditstoryV3Payload, params: RequestParams = {}) =>
    this.request<StoryEditstoryV3Data, any>({
      path: `/api/Story/editstoryV3`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryAutosaveV3
   * @request PUT:/api/Story/autosaveV3
   * @deprecated
   * @secure
   */
  storyAutosaveV3 = (data: StoryAutosaveV3Payload, params: RequestParams = {}) =>
    this.request<StoryAutosaveV3Data, any>({
      path: `/api/Story/autosaveV3`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryAttachAudio
   * @request PUT:/api/Story/attach-audio
   * @secure
   */
  storyAttachAudio = (data: StoryAttachAudioPayload, params: RequestParams = {}) =>
    this.request<StoryAttachAudioData, any>({
      path: `/api/Story/attach-audio`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryMilo
   * @request POST:/api/Story/milo
   * @secure
   */
  storyMilo = (data: StoryMiloPayload, params: RequestParams = {}) =>
    this.request<StoryMiloData, any>({
      path: `/api/Story/milo`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryComment
   * @request POST:/api/Story/comment
   * @secure
   */
  storyComment = (data: StoryCommentPayload, params: RequestParams = {}) =>
    this.request<StoryCommentData, any>({
      path: `/api/Story/comment`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryLike
   * @request POST:/api/Story/{storyId}/like
   * @secure
   */
  storyLike = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryLikeData, any>({
      path: `/api/Story/${storyId}/like`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryUnlike
   * @request POST:/api/Story/{storyId}/unlike
   * @secure
   */
  storyUnlike = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryUnlikeData, any>({
      path: `/api/Story/${storyId}/unlike`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryRemovetaggedperson
   * @request PUT:/api/Story/removetaggedperson
   * @secure
   */
  storyRemovetaggedperson = (data: StoryRemovetaggedpersonPayload, params: RequestParams = {}) =>
    this.request<StoryRemovetaggedpersonData, any>({
      path: `/api/Story/removetaggedperson`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryDelete
   * @request DELETE:/api/Story/{storyId}/delete
   * @deprecated
   * @secure
   */
  storyDelete = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryDeleteData, any>({
      path: `/api/Story/${storyId}/delete`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryUpdateprivacy
   * @request PUT:/api/Story/updateprivacy
   * @secure
   */
  storyUpdateprivacy = (data: StoryUpdateprivacyPayload, params: RequestParams = {}) =>
    this.request<StoryUpdateprivacyData, any>({
      path: `/api/Story/updateprivacy`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryReport
   * @request POST:/api/Story/report
   * @secure
   */
  storyReport = (data: StoryReportPayload, params: RequestParams = {}) =>
    this.request<StoryReportData, any>({
      path: `/api/Story/report`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryReportcomment
   * @request POST:/api/Story/reportcomment
   * @secure
   */
  storyReportcomment = (data: StoryReportcommentPayload, params: RequestParams = {}) =>
    this.request<StoryReportcommentData, any>({
      path: `/api/Story/reportcomment`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryInferredlocationidforstoryandmedia
   * @request PUT:/api/Story/inferredlocationidforstoryandmedia
   * @secure
   */
  storyInferredlocationidforstoryandmedia = (
    query: StoryInferredlocationidforstoryandmediaParams,
    params: RequestParams = {}
  ) =>
    this.request<StoryInferredlocationidforstoryandmediaData, any>({
      path: `/api/Story/inferredlocationidforstoryandmedia`,
      method: "PUT",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StorySharestory
   * @request POST:/api/Story/sharestory
   * @secure
   */
  storySharestory = (data: StorySharestoryPayload, params: RequestParams = {}) =>
    this.request<StorySharestoryData, any>({
      path: `/api/Story/sharestory`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryAddassociation
   * @request POST:/api/Story/{storyId}/addassociation
   * @secure
   */
  storyAddassociation = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryAddassociationData, any>({
      path: `/api/Story/${storyId}/addassociation`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryCitation
   * @request POST:/api/Story/citation
   * @secure
   */
  storyCitation = (data: StoryCitationPayload, params: RequestParams = {}) =>
    this.request<StoryCitationData, any>({
      path: `/api/Story/citation`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryEditcitation
   * @request POST:/api/Story/editcitation
   * @secure
   */
  storyEditcitation = (data: StoryEditcitationPayload, params: RequestParams = {}) =>
    this.request<StoryEditcitationData, any>({
      path: `/api/Story/editcitation`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryRemoveCitation
   * @request POST:/api/Story/{storyId}/removeCitation
   * @secure
   */
  storyRemoveCitation = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryRemoveCitationData, any>({
      path: `/api/Story/${storyId}/removeCitation`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryPublishOldStories
   * @request POST:/api/Story/publishOldStories
   * @secure
   */
  storyPublishOldStories = (params: RequestParams = {}) =>
    this.request<StoryPublishOldStoriesData, any>({
      path: `/api/Story/publishOldStories`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryPublishOneOldStory
   * @request POST:/api/Story/publishOneOldStory
   * @secure
   */
  storyPublishOneOldStory = (params: RequestParams = {}) =>
    this.request<StoryPublishOneOldStoryData, any>({
      path: `/api/Story/publishOneOldStory`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3Story
   * @request POST:/api/v3/Story
   * @secure
   */
  v3Story = (data: V3StoryPayload, params: RequestParams = {}) =>
    this.request<V3StoryData, any>({
      path: `/api/v3/Story`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3Story2
   * @request PUT:/api/v3/Story/{storyId}
   * @originalName v3Story
   * @duplicate
   * @secure
   */
  v3Story2 = (storyId: string, data: V3Story2Payload, params: RequestParams = {}) =>
    this.request<V3Story2Data, any>({
      path: `/api/v3/Story/${storyId}`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3Story3
   * @request DELETE:/api/v3/Story/{storyId}
   * @originalName v3Story
   * @duplicate
   * @secure
   */
  v3Story3 = (storyId: string, params: RequestParams = {}) =>
    this.request<V3Story3Data, any>({
      path: `/api/v3/Story/${storyId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3Story4
   * @request GET:/api/v3/Story/{storyId}
   * @originalName v3Story
   * @duplicate
   * @secure
   */
  v3Story4 = ({ storyId, ...query }: V3Story4Params, params: RequestParams = {}) =>
    this.request<V3Story4Data, any>({
      path: `/api/v3/Story/${storyId}`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StorySave
   * @request PUT:/api/v3/Story/{storyId}/save
   * @secure
   */
  v3StorySave = ({ storyId, ...query }: V3StorySaveParams, params: RequestParams = {}) =>
    this.request<V3StorySaveData, any>({
      path: `/api/v3/Story/${storyId}/save`,
      method: "PUT",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StoryMediaCrop
   * @request PUT:/api/v3/Story/{storyId}/media/{originatingMediaId}/crop
   * @secure
   */
  v3StoryMediaCrop = (
    storyId: string,
    originatingMediaId: string,
    data: V3StoryMediaCropPayload,
    params: RequestParams = {}
  ) =>
    this.request<V3StoryMediaCropData, any>({
      path: `/api/v3/Story/${storyId}/media/${originatingMediaId}/crop`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StoryMediaOrder
   * @request PUT:/api/v3/Story/{storyId}/media/order
   * @secure
   */
  v3StoryMediaOrder = (
    storyId: string,
    data: V3StoryMediaOrderPayload,
    params: RequestParams = {}
  ) =>
    this.request<V3StoryMediaOrderData, any>({
      path: `/api/v3/Story/${storyId}/media/order`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StoryMedia
   * @request POST:/api/v3/Story/{storyId}/media
   * @secure
   */
  v3StoryMedia = (storyId: string, data: V3StoryMediaPayload, params: RequestParams = {}) =>
    this.request<V3StoryMediaData, any>({
      path: `/api/v3/Story/${storyId}/media`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StoryMedia2
   * @request DELETE:/api/v3/Story/{storyId}/media
   * @originalName v3StoryMedia
   * @duplicate
   * @secure
   */
  v3StoryMedia2 = (storyId: string, data: V3StoryMedia2Payload, params: RequestParams = {}) =>
    this.request<V3StoryMedia2Data, any>({
      path: `/api/v3/Story/${storyId}/media`,
      method: "DELETE",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StoryGroups
   * @request POST:/api/v3/Story/{storyId}/groups
   * @secure
   */
  v3StoryGroups = (storyId: string, data: V3StoryGroupsPayload, params: RequestParams = {}) =>
    this.request<V3StoryGroupsData, any>({
      path: `/api/v3/Story/${storyId}/groups`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StoryGroups2
   * @request DELETE:/api/v3/Story/{storyId}/groups
   * @originalName v3StoryGroups
   * @duplicate
   * @secure
   */
  v3StoryGroups2 = (storyId: string, data: V3StoryGroups2Payload, params: RequestParams = {}) =>
    this.request<V3StoryGroups2Data, any>({
      path: `/api/v3/Story/${storyId}/groups`,
      method: "DELETE",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StoryPeople
   * @request POST:/api/v3/Story/{storyId}/people
   * @secure
   */
  v3StoryPeople = (storyId: string, data: V3StoryPeoplePayload, params: RequestParams = {}) =>
    this.request<V3StoryPeopleData, any>({
      path: `/api/v3/Story/${storyId}/people`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StoryPeople2
   * @request DELETE:/api/v3/Story/{storyId}/people
   * @originalName v3StoryPeople
   * @duplicate
   * @secure
   */
  v3StoryPeople2 = (storyId: string, data: V3StoryPeople2Payload, params: RequestParams = {}) =>
    this.request<V3StoryPeople2Data, any>({
      path: `/api/v3/Story/${storyId}/people`,
      method: "DELETE",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StoryPets
   * @request POST:/api/v3/Story/{storyId}/pets
   * @secure
   */
  v3StoryPets = (storyId: string, data: V3StoryPetsPayload, params: RequestParams = {}) =>
    this.request<V3StoryPetsData, any>({
      path: `/api/v3/Story/${storyId}/pets`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V3StoryPets2
   * @request DELETE:/api/v3/Story/{storyId}/pets
   * @originalName v3StoryPets
   * @duplicate
   * @secure
   */
  v3StoryPets2 = (storyId: string, data: V3StoryPets2Payload, params: RequestParams = {}) =>
    this.request<V3StoryPets2Data, any>({
      path: `/api/v3/Story/${storyId}/pets`,
      method: "DELETE",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StoryCreate
   * @request POST:/api/v2/Story/create
   * @deprecated
   * @secure
   */
  v2StoryCreate = (data: V2StoryCreatePayload, params: RequestParams = {}) =>
    this.request<V2StoryCreateData, any>({
      path: `/api/v2/Story/create`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StoryEdit
   * @request PUT:/api/v2/Story/edit
   * @deprecated
   * @secure
   */
  v2StoryEdit = (data: V2StoryEditPayload, params: RequestParams = {}) =>
    this.request<V2StoryEditData, any>({
      path: `/api/v2/Story/edit`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name AdminStoryAssign
   * @request POST:/api/admin/Story/{storyId}/assign/{topicId}
   * @secure
   */
  adminStoryAssign = (storyId: string, topicId: string, params: RequestParams = {}) =>
    this.request<AdminStoryAssignData, any>({
      path: `/api/admin/Story/${storyId}/assign/${topicId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name AdminStoryRemove
   * @request POST:/api/admin/Story/{storyId}/remove/{topicId}
   * @secure
   */
  adminStoryRemove = (storyId: string, topicId: string, params: RequestParams = {}) =>
    this.request<AdminStoryRemoveData, any>({
      path: `/api/admin/Story/${storyId}/remove/${topicId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name AdminStoryCompleteadminreview
   * @request PUT:/api/admin/Story/completeadminreview
   * @secure
   */
  adminStoryCompleteadminreview = (
    data: AdminStoryCompleteadminreviewPayload,
    params: RequestParams = {}
  ) =>
    this.request<AdminStoryCompleteadminreviewData, any>({
      path: `/api/admin/Story/completeadminreview`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name AdminStoryCompleteAdminReviewForComment
   * @request PUT:/api/admin/Story/completeAdminReviewForComment
   * @secure
   */
  adminStoryCompleteAdminReviewForComment = (
    data: AdminStoryCompleteAdminReviewForCommentPayload,
    params: RequestParams = {}
  ) =>
    this.request<AdminStoryCompleteAdminReviewForCommentData, any>({
      path: `/api/admin/Story/completeAdminReviewForComment`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name Story2
   * @request GET:/api/Story/{storyId}
   * @originalName story
   * @duplicate
   * @secure
   */
  story2 = (storyId: string, params: RequestParams = {}) =>
    this.request<Story2Data, any>({
      path: `/api/Story/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryStoryPrompts
   * @request GET:/api/Story/storyPrompts
   * @secure
   */
  storyStoryPrompts = (params: RequestParams = {}) =>
    this.request<StoryStoryPromptsData, any>({
      path: `/api/Story/storyPrompts`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StorySmartPrompts
   * @request GET:/api/Story/smart-prompts
   * @secure
   */
  storySmartPrompts = (query: StorySmartPromptsParams, params: RequestParams = {}) =>
    this.request<StorySmartPromptsData, any>({
      path: `/api/Story/smart-prompts`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryReloadStoryPrompts
   * @request GET:/api/Story/reloadStoryPrompts
   * @secure
   */
  storyReloadStoryPrompts = (params: RequestParams = {}) =>
    this.request<StoryReloadStoryPromptsData, any>({
      path: `/api/Story/reloadStoryPrompts`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryCheckuserassociation
   * @request GET:/api/Story/{storyId}/checkuserassociation
   * @secure
   */
  storyCheckuserassociation = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryCheckuserassociationData, any>({
      path: `/api/Story/${storyId}/checkuserassociation`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryPreviewstory
   * @request GET:/api/Story/previewstory/{storyId}
   * @secure
   */
  storyPreviewstory = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryPreviewstoryData, any>({
      path: `/api/Story/previewstory/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryStorydetailforbookpreview
   * @request GET:/api/Story/storydetailforbookpreview/{storyId}
   * @secure
   */
  storyStorydetailforbookpreview = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryStorydetailforbookpreviewData, any>({
      path: `/api/Story/storydetailforbookpreview/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryStorylikesdetail
   * @request GET:/api/Story/storylikesdetail/{storyId}/{pageNumber}/{pageSize}
   * @secure
   */
  storyStorylikesdetail = (
    storyId: string,
    pageNumber: number,
    pageSize: number,
    params: RequestParams = {}
  ) =>
    this.request<StoryStorylikesdetailData, any>({
      path: `/api/Story/storylikesdetail/${storyId}/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryComments
   * @request GET:/api/Story/{storyId}/comments/{pageNumber}/{pageSize}
   * @secure
   */
  storyComments = (
    storyId: string,
    pageNumber: number,
    pageSize: number,
    params: RequestParams = {}
  ) =>
    this.request<StoryCommentsData, any>({
      path: `/api/Story/${storyId}/comments/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryCommentcount
   * @request GET:/api/Story/{storyId}/commentcount
   * @secure
   */
  storyCommentcount = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryCommentcountData, any>({
      path: `/api/Story/${storyId}/commentcount`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryViewstory
   * @request GET:/api/Story/viewstory/{storyId}
   * @secure
   */
  storyViewstory = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryViewstoryData, any>({
      path: `/api/Story/viewstory/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryStoryDetailForCollection
   * @request GET:/api/Story/storyDetailForCollection/{storyId}
   * @secure
   */
  storyStoryDetailForCollection = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryStoryDetailForCollectionData, any>({
      path: `/api/Story/storyDetailForCollection/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryStoryDetailForFeed
   * @request GET:/api/Story/storyDetailForFeed/{storyId}
   * @secure
   */
  storyStoryDetailForFeed = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryStoryDetailForFeedData, any>({
      path: `/api/Story/storyDetailForFeed/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryStoryDetailForGroupFeed
   * @request GET:/api/Story/storyDetailForGroupFeed/{storyId}
   * @secure
   */
  storyStoryDetailForGroupFeed = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryStoryDetailForGroupFeedData, any>({
      path: `/api/Story/storyDetailForGroupFeed/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryCheckstorypermission
   * @request GET:/api/Story/checkstorypermission/{storyId}
   * @secure
   */
  storyCheckstorypermission = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryCheckstorypermissionData, any>({
      path: `/api/Story/checkstorypermission/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryCheckstorypermissionvalues
   * @request GET:/api/Story/checkstorypermissionvalues/{storyId}
   * @secure
   */
  storyCheckstorypermissionvalues = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryCheckstorypermissionvaluesData, any>({
      path: `/api/Story/checkstorypermissionvalues/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryCitation2
   * @request GET:/api/Story/{storyId}/citation
   * @originalName storyCitation
   * @duplicate
   * @secure
   */
  storyCitation2 = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryCitation2Data, any>({
      path: `/api/Story/${storyId}/citation`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name StoryAssistant
   * @request GET:/api/Story/assistant
   * @secure
   */
  storyAssistant = (query: StoryAssistantParams, params: RequestParams = {}) =>
    this.request<StoryAssistantData, any>({
      path: `/api/Story/assistant`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2Story
   * @request GET:/api/v2/Story/{storyId}
   * @secure
   */
  v2Story = (storyId: string, params: RequestParams = {}) =>
    this.request<V2StoryData, any>({
      path: `/api/v2/Story/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StoryDetail
   * @request GET:/api/v2/Story/{storyId}/detail
   * @secure
   */
  v2StoryDetail = (storyId: string, params: RequestParams = {}) =>
    this.request<V2StoryDetailData, any>({
      path: `/api/v2/Story/${storyId}/detail`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StoryReducedDetail
   * @request GET:/api/v2/Story/{storyId}/reduced-detail
   * @secure
   */
  v2StoryReducedDetail = (storyId: string, params: RequestParams = {}) =>
    this.request<V2StoryReducedDetailData, any>({
      path: `/api/v2/Story/${storyId}/reduced-detail`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StoryPrompts
   * @request GET:/api/v2/Story/prompts
   * @secure
   */
  v2StoryPrompts = (query: V2StoryPromptsParams, params: RequestParams = {}) =>
    this.request<V2StoryPromptsData, any>({
      path: `/api/v2/Story/prompts`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StoryPromptCategories
   * @request GET:/api/v2/Story/prompt-categories
   * @secure
   */
  v2StoryPromptCategories = (params: RequestParams = {}) =>
    this.request<V2StoryPromptCategoriesData, any>({
      path: `/api/v2/Story/prompt-categories`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StoryCollectionDetail
   * @request GET:/api/v2/Story/{storyId}/collection-detail
   * @secure
   */
  v2StoryCollectionDetail = (storyId: string, params: RequestParams = {}) =>
    this.request<V2StoryCollectionDetailData, any>({
      path: `/api/v2/Story/${storyId}/collection-detail`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StoryFeedDetail
   * @request GET:/api/v2/Story/{storyId}/feed-detail
   * @secure
   */
  v2StoryFeedDetail = (storyId: string, params: RequestParams = {}) =>
    this.request<V2StoryFeedDetailData, any>({
      path: `/api/v2/Story/${storyId}/feed-detail`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StoryGroupFeedDetail
   * @request GET:/api/v2/Story/{storyId}/group-feed-detail
   * @secure
   */
  v2StoryGroupFeedDetail = (storyId: string, params: RequestParams = {}) =>
    this.request<V2StoryGroupFeedDetailData, any>({
      path: `/api/v2/Story/${storyId}/group-feed-detail`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StoryStoriesByPrompt
   * @request GET:/api/v2/Story/stories-by-prompt
   * @secure
   */
  v2StoryStoriesByPrompt = (params: RequestParams = {}) =>
    this.request<V2StoryStoriesByPromptData, any>({
      path: `/api/v2/Story/stories-by-prompt`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StorySearch
   * @request POST:/api/v2/Story/search
   * @secure
   */
  v2StorySearch = (data: V2StorySearchPayload, params: RequestParams = {}) =>
    this.request<V2StorySearchData, any>({
      path: `/api/v2/Story/search`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StorySearchDetails
   * @request POST:/api/v2/Story/search/details
   * @secure
   */
  v2StorySearchDetails = (data: V2StorySearchDetailsPayload, params: RequestParams = {}) =>
    this.request<V2StorySearchDetailsData, any>({
      path: `/api/v2/Story/search/details`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StorySearchAggregate
   * @request POST:/api/v2/Story/search/aggregate
   * @secure
   */
  v2StorySearchAggregate = (data: V2StorySearchAggregatePayload, params: RequestParams = {}) =>
    this.request<V2StorySearchAggregateData, any>({
      path: `/api/v2/Story/search/aggregate`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name V2StorySearchPeople
   * @request POST:/api/v2/Story/search/people
   * @secure
   */
  v2StorySearchPeople = (data: V2StorySearchPeoplePayload, params: RequestParams = {}) =>
    this.request<V2StorySearchPeopleData, any>({
      path: `/api/v2/Story/search/people`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name AdminStory
   * @request GET:/api/admin/Story/{storyId}
   * @secure
   */
  adminStory = (storyId: string, params: RequestParams = {}) =>
    this.request<AdminStoryData, any>({
      path: `/api/admin/Story/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name AdminStoryAllstories
   * @request GET:/api/admin/Story/allstories/{pageNumber}/{pageSize}
   * @secure
   */
  adminStoryAllstories = (pageNumber: number, pageSize: number, params: RequestParams = {}) =>
    this.request<AdminStoryAllstoriesData, any>({
      path: `/api/admin/Story/allstories/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name AdminStoryAdminstoriescount
   * @request GET:/api/admin/Story/adminstoriescount
   * @secure
   */
  adminStoryAdminstoriescount = (params: RequestParams = {}) =>
    this.request<AdminStoryAdminstoriescountData, any>({
      path: `/api/admin/Story/adminstoriescount`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name AdminStoryReported
   * @request GET:/api/admin/Story/reported/{pageNumber}/{pageSize}
   * @secure
   */
  adminStoryReported = (
    { pageNumber, pageSize, ...query }: AdminStoryReportedParams,
    params: RequestParams = {}
  ) =>
    this.request<AdminStoryReportedData, any>({
      path: `/api/admin/Story/reported/${pageNumber}/${pageSize}`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Story
   * @name AdminStoryReportedcomments
   * @request GET:/api/admin/Story/reportedcomments/{pageNumber}/{pageSize}
   * @secure
   */
  adminStoryReportedcomments = (pageNumber: number, pageSize: number, params: RequestParams = {}) =>
    this.request<AdminStoryReportedcommentsData, any>({
      path: `/api/admin/Story/reportedcomments/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

import { decodeJWTtoken, groupStoryDataLayer, loadGroupDataLayer, loadShareStoryDataLayer, shareStoryDataLayer } from "services";
import { ANNUALLY, FREE_PLAN, MONTHLY, planDetail, PLUSPLAN, ULTIMATE_PLAN, STAR_PLUS_PLAN_ID, STORIED_PLUS_FREE_TRIAL_7, BUNDLE_PLAN_ID_MONTHLY, BUNDLE_PLAN_ID_HALF_YEARLY, BUNDLE_PLAN_ID_YEARLY, EARLIER_PLAN_ID, TWO_FOR_ONE_USERTYPE, ULTIMATE_PLAN_ID_ANNUAL, ULTIMATE_PLAN_ID_SEMIANNUAL, SEMI, ULTIMATE_PLAN_ID_MONTHLY, ESSENTIAL } from "utils/constant";

export const getPlanName = (basicPlan) => {
  let planName;
  if (decodeJWTtoken()) {
    const { extension_WebsiteId, extension_UserType } = decodeJWTtoken();
    switch (basicPlan) {
      case "Storied Essential":
        planName = ESSENTIAL;
        break;
      case "Storied Plus":
        planName = PLUSPLAN;
        break;
      case "Storied Ultimate":
        planName = ULTIMATE_PLAN;
        break;
      default:
        if (extension_WebsiteId === "1" && (extension_UserType === "Free" || extension_UserType === "free" || extension_UserType === "Paid")) {
          planName = 'newspaperarchive';
        } else {
          planName = FREE_PLAN;
        }
    }
  }
  return planName;
};

export const checkWebsitePlan = (getToken, planName) => {
  let checkUserType;
  if (getToken?.extension_WebsiteId === '1') {
    checkUserType = "newspaperarchive";
  } else if (getToken?.extension_WebsiteId === '4670' || planName) {
    checkUserType = planName;
  }
  return checkUserType;
};

export const basicPlanName = (plans, planDetails) => {
  let getPlan;
  if (plans !== null) {
    plans?.forEach((basicPlan) => {
      if (basicPlan?.planid === planDetails?.planId)
        getPlan = basicPlan?.basicName;
    });
  }
  return getPlan;
};

export const registrationsDataLayer = () => {
  let checkLoadStory = loadShareStoryDataLayer();
  let checkLoadGroup = loadGroupDataLayer();

  if (checkLoadStory || checkLoadGroup) {
    shareStoryDataLayer(false);
    groupStoryDataLayer(false);
  }
};

/**
 * @param {string} value 
 * @returns {{ name: string, amount: number, duration: string, userType: string }}
 */
export const planObj = (value) => {
  switch (value) {
    case EARLIER_PLAN_ID:
      return {
        name: PLUSPLAN,
        amount: planDetail[EARLIER_PLAN_ID]?.amount,
        duration: MONTHLY,
        userType: PLUSPLAN,
      };
    case BUNDLE_PLAN_ID_YEARLY:
      return {
        name: ULTIMATE_PLAN,
        amount: planDetail[BUNDLE_PLAN_ID_YEARLY]?.amount,
        duration: ANNUALLY,
        userType: ULTIMATE_PLAN,
      };
    case BUNDLE_PLAN_ID_HALF_YEARLY:
      return {
        name: ULTIMATE_PLAN,
        amount: planDetail[BUNDLE_PLAN_ID_HALF_YEARLY]?.amount,
        duration: SEMI,
        userType: ULTIMATE_PLAN,
      };
    case BUNDLE_PLAN_ID_MONTHLY:
      return {
        name: ULTIMATE_PLAN,
        amount: planDetail[BUNDLE_PLAN_ID_MONTHLY]?.amount,
        duration: MONTHLY,
        userType: ULTIMATE_PLAN,
      };
    case STORIED_PLUS_FREE_TRIAL_7:
      return {
        name: FREE_PLAN,
        amount: "",
        duration: "",
        userType: FREE_PLAN,
      };
    case STAR_PLUS_PLAN_ID:
      return {
        name: planDetail[STAR_PLUS_PLAN_ID]?.planName,
        amount: planDetail[STAR_PLUS_PLAN_ID]?.amount,
        duration: MONTHLY,
        userType: TWO_FOR_ONE_USERTYPE,
      };
    case ULTIMATE_PLAN_ID_ANNUAL:
      return {
        name: ULTIMATE_PLAN,
        amount: planDetail[ULTIMATE_PLAN_ID_ANNUAL].amount,
        duration: ANNUALLY,
        userType: ULTIMATE_PLAN
      };
    case ULTIMATE_PLAN_ID_SEMIANNUAL:
      return {
        name: ULTIMATE_PLAN,
        amount: planDetail[ULTIMATE_PLAN_ID_SEMIANNUAL].amount,
        duration: SEMI,
        userType: ULTIMATE_PLAN
      };
    case ULTIMATE_PLAN_ID_MONTHLY:
      return {
        name: ULTIMATE_PLAN,
        amount: planDetail[ULTIMATE_PLAN_ID_MONTHLY].amount,
        duration: MONTHLY,
        userType: ULTIMATE_PLAN
      };
    default:
      return {
        userType: FREE_PLAN
      };
  }
};


export const getNameForPurchase = (basicPlan) => {
  let planName;
  switch (basicPlan) {
    case "Storied Plus":
      planName = PLUSPLAN;
      break;
    case "Storied Ultimate":
      planName = ULTIMATE_PLAN;
      break;
    case "Storied Essential":
      planName = ESSENTIAL;
      break;
    default:
      planName = "free_account";
  }
  return planName;
};
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  V2PetMediaVideoAttachData,
  V2PetMediaVideoAttachPayload,
  V2PetMediaVideoDetachData,
  V2PetMediaVideoDetachPayload,
  V2PetMediaVideoUploadCompleteData,
  V2PetMediaVideoUploadCompletePayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PetMedia extends HttpClient {
  /**
   * No description
   *
   * @tags PetMedia
   * @name V2PetMediaVideoUploadComplete
   * @request POST:/api/v2/PetMedia/video/upload-complete
   * @secure
   */
  v2PetMediaVideoUploadComplete = (
    data: V2PetMediaVideoUploadCompletePayload,
    params: RequestParams = {}
  ) =>
    this.request<V2PetMediaVideoUploadCompleteData, any>({
      path: `/api/v2/PetMedia/video/upload-complete`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PetMedia
   * @name V2PetMediaVideoAttach
   * @request POST:/api/v2/PetMedia/video/attach
   * @secure
   */
  v2PetMediaVideoAttach = (data: V2PetMediaVideoAttachPayload, params: RequestParams = {}) =>
    this.request<V2PetMediaVideoAttachData, any>({
      path: `/api/v2/PetMedia/video/attach`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PetMedia
   * @name V2PetMediaVideoDetach
   * @request POST:/api/v2/PetMedia/video/detach
   * @secure
   */
  v2PetMediaVideoDetach = (data: V2PetMediaVideoDetachPayload, params: RequestParams = {}) =>
    this.request<V2PetMediaVideoDetachData, any>({
      path: `/api/v2/PetMedia/video/detach`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    });
}

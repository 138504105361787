/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  V2PersonMediaVideoAttachData,
  V2PersonMediaVideoAttachPayload,
  V2PersonMediaVideoDetachData,
  V2PersonMediaVideoDetachPayload,
  V2PersonMediaVideoUploadCompleteData,
  V2PersonMediaVideoUploadCompletePayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PersonMedia extends HttpClient {
  /**
   * No description
   *
   * @tags PersonMedia
   * @name V2PersonMediaVideoUploadComplete
   * @request POST:/api/v2/PersonMedia/video/upload-complete
   * @secure
   */
  v2PersonMediaVideoUploadComplete = (
    data: V2PersonMediaVideoUploadCompletePayload,
    params: RequestParams = {}
  ) =>
    this.request<V2PersonMediaVideoUploadCompleteData, any>({
      path: `/api/v2/PersonMedia/video/upload-complete`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PersonMedia
   * @name V2PersonMediaVideoAttach
   * @request POST:/api/v2/PersonMedia/video/attach
   * @secure
   */
  v2PersonMediaVideoAttach = (data: V2PersonMediaVideoAttachPayload, params: RequestParams = {}) =>
    this.request<V2PersonMediaVideoAttachData, any>({
      path: `/api/v2/PersonMedia/video/attach`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PersonMedia
   * @name V2PersonMediaVideoDetach
   * @request POST:/api/v2/PersonMedia/video/detach
   * @secure
   */
  v2PersonMediaVideoDetach = (data: V2PersonMediaVideoDetachPayload, params: RequestParams = {}) =>
    this.request<V2PersonMediaVideoDetachData, any>({
      path: `/api/v2/PersonMedia/video/detach`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    });
}

import { Link, NavLink, useLocation } from "react-router-dom";
//Components
import Button from "components/Button";
import HeaderLogo from "components/Header/HeaderLogo";
import Icon from "components/Icon";
import PeoplePopover from "components/PeoplePopover";
import Translator from "components/Translator";

import { clearAppErrorState } from "redux/actions/user";

// css
import "./TopHeader.css";

const TopHeader = ({
  handleToggle,
  accessValue,
  handleSignup,
  marketingURL,
  handleVerifiedCookiedBasedModal,
  handleLogin
}) => {
  const { pathname } = useLocation();
  const hideNavMenu = pathname !== "/name-search";
  const hideStartFreeTrial = pathname.includes("/payment/bundle/trial");

  const checkActive = (_match, navLocation) => {
    if (!navLocation) return false;
    const { pathname } = navLocation;
    return pathname === "/stories";
  };

  const checkActiveGroup = (_match, groupLocation) => {
    if (!groupLocation) return false;
    const { pathname } = groupLocation;
    return pathname === "/groups";
  };

  const checkActiveStoriedBooks = (_match, bookLocation) => {
    if (!bookLocation) return false;
    const { pathname } = bookLocation;
    return pathname === "/storiedbooks";
  };

  return (
      <div className="lohp-header bg-white z-200 z-lohp">
        <div className="main-wrapper lohp-header mx-auto max-w-full-header">
          <div className="header-section h-14">
            <div className="flex my-auto ml-0">
              {hideNavMenu && (
                <div className="left-hamburger-menu">
                  <button type="button" className="lohp-hamburger" onClick={handleToggle}>
                    <Icon type={"hamburger"} color="default" size="large" />
                  </button>
                </div>
              )}
              <Link to="/" className="focus:outline-none ml-4 xl:ml-0">
                <HeaderLogo />
              </Link>
            </div>
            {hideNavMenu && (
              <nav className="main-nav">
                <NavLink
                  to={`/stories`}
                  className="nav-link"
                  isActive={checkActive}
                  activeClassName="active"
                >
                  <Translator tkey="loggedoutHomepage.nav.stories" />
                </NavLink>
                <NavLink
                  to={`/storiedbooks`}
                  className="nav-link"
                  isActive={checkActiveStoriedBooks}
                  activeClassName="active"
                >
                  <Translator tkey="loggedoutHomepage.nav.storiedbooks" />
                </NavLink>
                <NavLink
                  to={`/groups`}
                  isActive={checkActiveGroup}
                  className="nav-link"
                  activeClassName="active"
                >
                  <Translator tkey="loggedoutHomepage.nav.groups" />
                </NavLink>
                <PeoplePopover type="people" clearAppErrorState={clearAppErrorState} />
                <PeoplePopover type="search" clearAppErrorState={clearAppErrorState} />
              </nav>
            )}

            <div className="flex ml-auto absolute right-0 top-3 md:top-0 md:relative">
              <nav className="main-nav gifts-link">
                <NavLink to="/offers" className="nav-link">
                  <Translator tkey="loggedoutHomepage.nav.plans" />
                </NavLink>
              </nav>
              {hideNavMenu && (
                <div
                  className={`hidden md:flex lg:ml-4 btn-wrap-signup ${hideStartFreeTrial ? "hidden" : ""}`}
                >
                  <Button
                    id="signup-btn"
                    type="red"
                    title={
                      <div>
                        <span className="mxs:hidden">
                          <Translator tkey="buttonText.join" />
                        </span>
                        <span className="hidden mxs:block">
                          <Translator tkey="title.StartFreeTrial" />
                        </span>
                      </div>
                    }
                    fontWeight="medium"
                    handleClick={() => {
                      handleVerifiedCookiedBasedModal(handleSignup, null, marketingURL, true);
                    }}
                  />
                </div>
              )}
              <div className="ml-1 md:ml-2.5 btn-wrap-signin">
                <Button
                  id="signin-btn"
                  type="gray-3"
                  title={<Translator tkey="loggedoutHomepage.nav.signIn" />}
                  fontWeight="medium"
                  handleClick={() => handleVerifiedCookiedBasedModal(handleLogin)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TopHeader;

import { useCallback, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";


export interface NavigateOptions {
    replace?: boolean;
    state?: any;
    preventScrollReset?: boolean;
    relative?: any;
    unstable_flushSync?: boolean;
    unstable_viewTransition?: boolean;
}

export type ParamKeyValuePair = [string, string];

export type URLSearchParamsInit =
    | string
    | ParamKeyValuePair[]
    | Record<string, string | string[]>
    | URLSearchParams;


export type SetURLSearchParams = (
    nextInit?:
        | URLSearchParamsInit
        | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions
) => void;

export function createSearchParams(
    init: URLSearchParamsInit = ""
): URLSearchParams {
    return new URLSearchParams(
        typeof init === "string" ||
            Array.isArray(init) ||
            init instanceof URLSearchParams
            ? init
            : Object.keys(init).reduce((memo, key) => {
                const value = init[key];
                return memo.concat(
                    Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]
                );
            }, [] as ParamKeyValuePair[])
    );
}

export function getSearchParamsForLocation(
    locationSearch: string,
    defaultSearchParams: URLSearchParams | null
) {
    const searchParams = createSearchParams(locationSearch);

    if (defaultSearchParams) {
        // Use `defaultSearchParams.forEach(...)` here instead of iterating of
        // `defaultSearchParams.keys()` to work-around a bug in Firefox related to
        // web extensions. Relevant Bugzilla tickets:
        // https://bugzilla.mozilla.org/show_bug.cgi?id=1414602
        // https://bugzilla.mozilla.org/show_bug.cgi?id=1023984
        defaultSearchParams.forEach((_, key) => {
            if (!searchParams.has(key)) {
                defaultSearchParams.getAll(key).forEach((value) => {
                    searchParams.append(key, value);
                });
            }
        });
    }

    return searchParams;
}

/**
 * Copied verbatim from the react-router v6 source code.
 * Remove when we upgrade to v6.
 * @param defaultInit 
 * @returns 
 */
export function useSearchParams(
    defaultInit?: URLSearchParamsInit
): [URLSearchParams, SetURLSearchParams] {
    const defaultSearchParamsRef = useRef(createSearchParams(defaultInit));
    const hasSetSearchParamsRef = useRef(false);

    const location = useLocation();
    const searchParams = useMemo(
        () =>
            // Only merge in the defaults if we haven't yet called setSearchParams.
            // Once we call that we want those to take precedence, otherwise you can't
            // remove a param with setSearchParams({}) if it has an initial value
            getSearchParamsForLocation(
                location.search,
                hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current
            ),
        [location.search]
    );

    const { push } = useHistory();
    const setSearchParams = useCallback<SetURLSearchParams>(
        (nextInit, navigateOptions) => {
            const newSearchParams = createSearchParams(
                typeof nextInit === "function" ? nextInit(searchParams) : nextInit
            );
            hasSetSearchParamsRef.current = true;
            push("?" + newSearchParams, navigateOptions);
        },
        [push, searchParams]
    );

    return [searchParams, setSearchParams];
}
import { useEffect } from "react";
import { setCouponCodeCookie } from "services";
import { useSearchParams } from "utils/Routing/useSearchParams";

export function useCouponCode() {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const code = searchParams.get("couponcode");
    if (code) {
      setCouponCodeCookie(code);
    }
  }, [searchParams]);
}

import "./index.css";
import getSvg, { IconName } from "./svgs";

export type IconSize = "xsmall" | "small-medium" | "small" | "medium" | "large" | "xlarge" | "xxlarge" | "auto";
export type IconColor =
  | "default"
  | "primary"
  | "secondary"
  | "danger"
  | "white"
  | "gray-3"
  | "gray-4"
  | "gray-5"
  | "gray-6"
  | "gray-7"
  | "blue"
  | "red"
  | "orange-6"
  | "skyblue-2"
  | "maroon-2";

export type IconProps = {
  id?: string;
  type?: IconName;
  size?: IconSize;
  color?: IconColor;
  background?: boolean;
  disabled?: boolean;
  handleClick?: () => void;
};

export default function Icon({
  type = "plus",
  id = `icon-${Math.random().toString().substring(2)}`,
  size = "small",
  color = "default",
  background = false,
  disabled = false,
  handleClick = undefined
}: IconProps) {
  const getIconSize = () => {
    switch (size) {
      case "xsmall":
        return "icon-xsmall";
      case "small-medium":
        return "icon-small-medium";
      case "small":
        return "icon-small";
      case "medium":
        return "icon-medium";
      case "large":
        return "icon-large";
      case "xlarge":
        return "icon-xlarge";
      case "xxlarge":
        return "icon-xxlarge";
        case "auto":
        return "icon-auto";
      default:
        return "";
    }
  };

  const iconSize = getIconSize();

  const bgColor = background ? "icon-bgColor" : "";

  const iconDisable = disabled ? "icon-disable" : "";

  const getIconColor = () => {
    switch (color) {
      case "default":
        return "#747578";
      case "primary":
        return "#295DA1";
      case "secondary":
        return "#212122";
      case "danger":
        return "#B02A4C";
      case "gray-3":
        return "rgb(215, 216, 217)";
      case "gray-4":
        return "#9D9FA2";
      case "gray-5":
        return "#747578";
      case "gray-6":
        return "#555658";
      case "gray-7":
        return "#212122";
      case "white":
        return "#FFFFFF";
      case "blue":
        return "#397388";
      case "red":
        return "#FC4040";
      case "orange-6":
        return "#8B662B";
      case "skyblue-2":
        return "#B4DBEE";
      case "maroon-2":
        return "#EF9CB1";
      default:
        return "";
    }
  };

  const iconColor = getIconColor();

  return (
    <div
      data-testid={`icon-${type}`}
      id={id}
      className={`icon ${iconSize} ${bgColor} ${iconDisable}`}
      onClick={!disabled ? handleClick : undefined}
    >
      {getSvg(iconColor, type ?? "plus")}
    </div>
  );
}

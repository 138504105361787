/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  V2GroupMediaVideoUploadCompleteData,
  V2GroupMediaVideoUploadCompletePayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class GroupMedia extends HttpClient {
  /**
   * No description
   *
   * @tags GroupMedia
   * @name V2GroupMediaVideoUploadComplete
   * @request POST:/api/v2/GroupMedia/video/upload-complete
   * @secure
   */
  v2GroupMediaVideoUploadComplete = (
    data: V2GroupMediaVideoUploadCompletePayload,
    params: RequestParams = {}
  ) =>
    this.request<V2GroupMediaVideoUploadCompleteData, any>({
      path: `/api/v2/GroupMedia/video/upload-complete`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    });
}

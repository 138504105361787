import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
//components
import MobileMenu from "components/Header/LoggedOutHeader/components/MobileMenu";
import TopHeader from "components/Header/LoggedOutHeader/components/TopHeader";
import UnAuthenticatedTemplate from "components/UnAuthenticatedTemplate";

//Hooks
import { useLoggedOutHeader } from "components/Header/hooks/useLoggedOutHeader";

//Css
import usePathSave from "hooks/usePathSave";
import "./index.css";

const LoggedOutHeader = ({ handleSignup, handleLogin }) => {
  const location = useLocation();
  const history = useHistory();
  const { marketingURL, isActive, handleToggle } =
    useLoggedOutHeader(handleSignup);
  usePathSave(true);

  const handleVerifiedCookiedBasedModal = (
    fn,
    params = null,
    marketingURL = false,
    freeTrial = false
  ) => {
    if (freeTrial) {
      return history.push("/payment/bundle/trial");
    }
    if (marketingURL) {
      handleSignup();
    } else {
      fn(params);
    }
  };

  const scrollstick = () => {
    if (!document.querySelector(".lohp-header")) return;
    if (window.scrollY >= 10) {
      document.querySelector(".lohp-header")?.classList.add("header-shadow");
    } else {
      document.querySelector(".lohp-header")?.classList.remove("header-shadow");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      scrollstick();
    });
  });

  // Hide header on '/recipes' route, show it on all other routes
  // TODO: create a more programatic way to handle hiding header on LOHP
  if (location.pathname === "/recipes") {
    return null;
  }

  return (
    <UnAuthenticatedTemplate showLoader={false}>
      {" "}
      {/* mobile hamburger menu */}
      <MobileMenu
        isActive={isActive}
        handleToggle={handleToggle}
        handleVerifiedCookiedBasedModal={handleVerifiedCookiedBasedModal}
        handleLogin={handleLogin}
        handleSignup={handleSignup}
        marketingURL={marketingURL}
      />
      {/* Top Header */}
      <TopHeader
        handleToggle={handleToggle}
        handleSignup={handleSignup}
        marketingURL={marketingURL}
        handleVerifiedCookiedBasedModal={handleVerifiedCookiedBasedModal}
        handleLogin={handleLogin}
      />
    </UnAuthenticatedTemplate>
  );
};

export default LoggedOutHeader;

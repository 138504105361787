import axios from "axios";
import { pathToRegexp } from "path-to-regexp";
import { v4 } from "uuid";

function featureFlagWithCacheFactory(
  flag: string,
  cacheExpirationMs: number,
  reactAppClientId: string,
  reactAppApi: string
): { checkFlag: () => Promise<boolean>; getCachedValue: () => boolean | null } {
  let previouslyCheckedValue: boolean | null = null;

  const checkFlag = async () => {
    if (previouslyCheckedValue !== null) {
      return previouslyCheckedValue;
    }
    // We can't use autogenerated API here because this file is imported by the Next.js repository which would cause issues.
    const result = await axios.get<boolean>(`${reactAppApi}/api/FeatureFlag/feature/${flag}`, {
      headers: {
        "wa-clientId": reactAppClientId,
        "wa-requestId": v4()
      }
    });
    previouslyCheckedValue = result.data;
    setTimeout(() => {
      previouslyCheckedValue = null;
    }, cacheExpirationMs);

    return result.data;
  };
  const getCachedValue = () => previouslyCheckedValue;
  return { checkFlag, getCachedValue };
}

function createFeatureFlagResolver(featureFlag: {
  checkFlag: () => Promise<boolean>;
  getCachedValue: () => boolean | null;
}): () => Promise<boolean> {
  return async () => {
    const cachedValue = featureFlag.getCachedValue();
    if (cachedValue !== null) {
      return cachedValue;
    }
    return await featureFlag.checkFlag();
  };
}

function getNextJsRoutes(
  reactAppClientId: string,
  reactAppApi: string
): { path: string; canBeLoggedIn: boolean | (() => Promise<boolean>) }[] {
  const featureFlagsKeys = [
    "FF_NextJSLIHP",
    "FF_NextJSLI_MyScrapbook",
    "FF_NextJSLI_Registration",
    "FF_NextJSLI_StoriesView",
    "FF_NextJSLI_StoriesPreview",
    "FF_NextJSLI_Groups",
    "FF_NextJSLI_Settings",
    "FF_NextJSLI_StoriesAddEdit",
    "FF_NextJSLI_NewsPaperSearch",
    // "FF_NextJSLI_FamilyInfo",
    "FF_NextJSLI_NewsPaperSearch_Location",
    "FF_StoriedbookPdfPreview",
    "FF_NextJSLI_Records",
    "FF_NextJSLI_MediaViewer",
    "FF_NextJSLI_MergeRecord",
    "FF_NextJSLI_Payment", 
    'FF_NextJSLI_NewsPaperSearch_Viewer'
  ];
  const featureFlags: {
    [key: string]: { checkFlag: () => Promise<boolean>; getCachedValue: () => boolean | null };
  } = {};
  featureFlagsKeys.forEach((key) => {
    featureFlags[key] = featureFlagWithCacheFactory(key, 30_000, reactAppClientId, reactAppApi);
  });
  return [
    {
      path: "/",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLIHP)
    },
    { path: "/stories", canBeLoggedIn: false },
    {
      path: "/stories/add",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/add/:refType",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/add/:refType/pet/:petId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/add/:refType/group/:groupName",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/add/:refType/:treeId/:primaryPersonId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/add/newspaper/:recordId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/add-from-media/:refType/:mediaId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/add-from-external-media/:refType/:mediaId/:recordId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/edit/:refType/:storyId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/edit/:refType/:storyId/group/:groupName",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/edit/:refType/:storyId/:treeId/:primaryPersonId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesAddEdit)
    },
    {
      path: "/stories/:version?/view/:idunno/:storyId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesView)
    },
    {
      path: "/stories/view/:refType/:storyId/group/:groupName",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesView)
    },
    {
      path: "/stories/view/:refType/:storyId/:treeId/:primaryPersonId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesView)
    },
    {
      path: "/stories/view/:refType/:storyId?newspaper",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesView)
    },
    { path: "/storiedbooks", canBeLoggedIn: true },
    { path: "/storiedbooks/:rest+", canBeLoggedIn: true },
    { path: "/upgrade/storiedbookstudio", canBeLoggedIn: true },
    { path: "/signup-with-familysearch/plans", canBeLoggedIn: true },
    { path: "/importing-family-search-tree", canBeLoggedIn: true },
    { path: "/import-family-search-tree-existing", canBeLoggedIn: true },
    { path: "/import-family-search-welcome", canBeLoggedIn: true },
    { path: "/groups", canBeLoggedIn: true },
    // {
    //   path: "/family/info",
    //   canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_FamilyInfo)
    // },
    // {
    //   path: "/family/info/:action",
    //   canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_FamilyInfo)
    // },
    // {
    //   path: "/import-family-search-tree",
    //   canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_FamilyInfo)
    // },
    // {
    //   path: "/import-family-search-tree/:code",
    //   canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_FamilyInfo)
    // },
    // {
    //   path: "/skip-family-search-tree-import",
    //   canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_FamilyInfo)
    // },
    { path: "/family-trees", canBeLoggedIn: true },
    {
      path: "/family/pick-a-side",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_Registration)
    },
    { path: "/people", canBeLoggedIn: true },
    { path: "/lp/newspaper", canBeLoggedIn: false },
    {
      path: "/newspaper-search",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_NewsPaperSearch)
    },
    {
      path: "/newspaper-search/result",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_NewsPaperSearch)
    },
    {
      path: "/newspaper-search/location",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_NewsPaperSearch_Location)
    },

    {
      path: "/newspaper-search/location/:countryName",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_NewsPaperSearch_Location)
    },

    {
      path: "/newspaper-search/location/:countryName/:stateName",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_NewsPaperSearch_Location)
    },

    {
      path: "/newspaper-search/location/:countryName/:stateName/:cityName",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_NewsPaperSearch_Location)
    },
    {
      path: "/newspaper-search/location/:countryName/:stateName/:cityName/:publicationName",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_NewsPaperSearch_Location)
    },

    {
      path: "/newspaper-search/newspaper/:recordId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_NewsPaperSearch_Viewer)
    },

    
    { path: "/newspaper-search/:route*", canBeLoggedIn: false },
    { path: "/plans", canBeLoggedIn: false },
    { path: "/about", canBeLoggedIn: true },
    { path: "/resources", canBeLoggedIn: true },
    { path: "/privacy", canBeLoggedIn: true },
    { path: "/terms", canBeLoggedIn: true },
    { path: "/ccpa", canBeLoggedIn: true },
    { path: "/dpa", canBeLoggedIn: true },
    { path: "/getstarted", canBeLoggedIn: true },
    { path: "/getstarted_v1", canBeLoggedIn: true },
    { path: "/getstarted_v2", canBeLoggedIn: true },
    { path: "/getstarted_v3", canBeLoggedIn: true },
    { path: "/name-search", canBeLoggedIn: true },
    {
      path: "/person/profile/:id",
      canBeLoggedIn: true
    },
    { path: "/payment/bundle/trial", canBeLoggedIn: true },
    { path: "/payment/bundle/trial/grid", canBeLoggedIn: false },
    { path: "/payment/bundle/trial/ultimate", canBeLoggedIn: false },
    { path: "/payment/bundle/annual-only", canBeLoggedIn: false },
    { path: "/gifts/:rest+", canBeLoggedIn: true },
    { path: "/print", canBeLoggedIn: true },
    { path: "/storiedbook/re-order/:rest+", canBeLoggedIn: true },
    { path: "/p/:storyid", canBeLoggedIn: true },
    { path: "/book-preview/:storyid", canBeLoggedIn: true },
    { path: "/historical-record-search", canBeLoggedIn: true },
    { path: "/historical-record-search/:rest*", canBeLoggedIn: true },
    {
      path: "/records/:recordId/:partitionKey",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_Records)
    },
    {
      path: "/stories-preview/:storyid",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_StoriesPreview)
    },
    {
      path: "/storiedbook-preview/:projectId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_StoriedbookPdfPreview)
    },
    { path: "/activate", canBeLoggedIn: true },
    { path: "/activation", canBeLoggedIn: true },
    { path: "/activation/:code", canBeLoggedIn: true },
    { path: "/prompts", canBeLoggedIn: true },
    {
      path: "/my-groups",
      canBeLoggedIn: true
    },
    { path: "/projects", canBeLoggedIn: true },
    { path: "/projects/create", canBeLoggedIn: true },
    { path: "/projects/edit-cover/:projectId", canBeLoggedIn: true },
    { path: "/projects/review-cover/:projectId", canBeLoggedIn: true },
    { path: "/project/view/:projectId", canBeLoggedIn: true },
    { path: "/storied-starter/:promptId", canBeLoggedIn: true },
    {
      path: "/my-scrapbook",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_MyScrapbook)
    },
    {
      path: "/media/view-image/:mediaId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_MediaViewer)
    },
    {
      path: "/my-scrapbook/media/:mediaId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_MediaViewer)
    },
    {
      path: "/groups/storied/scrapbook/media/:groupId/:groupTreeId/:mediaId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_MediaViewer)
    },
    {
      path: "/family/person-page/:queryTab/media/:personId/:mediaId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_MediaViewer)
    },
    {
      path: "/family/pet-page/:queryTab/media/:petId/:mediaId",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_MediaViewer)
    },
    {
      path: "/my-stories",
      canBeLoggedIn: true
    },
    {
      path: "/notifications",
      canBeLoggedIn: true
    },
    {
      path: "/registration",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_Registration)
    },
    {
      path: "/settings",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_Settings)
    },
    {
      path: "/settings/:getTab",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_Settings)
    },
    { path: "/offers", canBeLoggedIn: true },
    { path: "/trial-offers", canBeLoggedIn: true },
    {
      path: "/payment",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_Payment)
    },
    {
      path: "/payment/:rest+",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_Payment)
    },
    {
      path: "/payment/bundle/trial/:rest+",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_Payment)
    },
    {
      path: "/paywal/bundle",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_Payment)
    },
    // admin routes
    { path: "/admin", canBeLoggedIn: true },
    { path: "/admin/activation-code-status", canBeLoggedIn: true },
    { path: "/admin/report", canBeLoggedIn: true },
    { path: "/admin/report/:reportType", canBeLoggedIn: true },
    { path: "/admin/book-order", canBeLoggedIn: true },
    { path: "/admin/topics", canBeLoggedIn: true },
    {
      path: "/merge-record/:params+",
      canBeLoggedIn: createFeatureFlagResolver(featureFlags.FF_NextJSLI_MergeRecord)
    }
  ];
}

export function getCompiledNextRoutes(reactAppClientId: string, reactAppApi: string) {
  return getNextJsRoutes(reactAppClientId, reactAppApi).map((route) => ({
    path: pathToRegexp(route.path),
    canBeLoggedIn: route.canBeLoggedIn
  }));
}

import usePathSave from "hooks/usePathSave";
import { Suspense, lazy, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../Footer";
import Loader from "../Loader";
const LeftNavigationDrawer = lazy(
  () => import(/* webpackChunkName: "leftnavigationdrawer" */ "../LeftNavigationDrawer")
);
const Header = lazy(() => import(/* webpackChunkName: "header" */ "../Header"));

const Layout = ({ children, clearAppErrorState }) => {
  const [openLeftNav, setOpenLeftNav] = useState(false);
  const { showLeftSideBar, showHeader, showFooter } = useSelector((state) => state.layout);
  usePathSave(false);

  return (
    <>
      <Suspense
        fallback={
          <div className="h-screen w-screen absolute top-0 left-0">
            <Loader />
          </div>
        }
      >
        {showLeftSideBar && (
          <LeftNavigationDrawer
            clearAppErrorState={clearAppErrorState}
            openLeftNav={openLeftNav}
            setOpenLeftNav={setOpenLeftNav}
          />
        )}
        {showHeader && (
          <Header
            clearAppErrorState={clearAppErrorState}
            setOpenLeftNav={setOpenLeftNav}
            openLeftNav={openLeftNav}
          />
        )}
        <div className="content-wrapper">
          {children}
        </div>
      </Suspense>
      {showFooter && <Footer />}
    </>
  );
};

export default Layout;

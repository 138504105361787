/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// Combines all controllers into a singleton static API class for convenience.

import { REACT_APP_API } from "utils/env";
import { ActivationCodes } from "./ActivationCodes";
import { Alexanders } from "./Alexanders";
import { ContentCatalog } from "./ContentCatalog";
import { ContentSearch } from "./ContentSearch";
import { Contributor } from "./Contributor";
import { CorrectiveActions } from "./CorrectiveActions";
import { DeniedGedcom } from "./DeniedGedcom";
import { FeatureFlag } from "./FeatureFlag";
import { GiftOrder } from "./GiftOrder";
import { GooglePhotos } from "./GooglePhotos";
import { Group } from "./Group";
import { GroupMedia } from "./GroupMedia";
import { Hint } from "./Hint";
import { HintGeneration } from "./HintGeneration";
import { HintInspector } from "./HintInspector";
import { HistoricalSearch } from "./HistoricalSearch";
import { Media } from "./Media";
import { Newspaper } from "./Newspaper";
import { NewsPaperSearch } from "./NewsPaperSearch";
import { PartnerHint } from "./PartnerHint";
import { PersonMedia } from "./PersonMedia";
import { Persons } from "./Persons";
import { Pet } from "./Pet";
import { PetMedia } from "./PetMedia";
import { PlaceAuthority } from "./PlaceAuthority";
import { PrintOrder } from "./PrintOrder";
import { Project } from "./Project";
import { QrCode } from "./QrCode";
import { RecordCorrections } from "./RecordCorrections";
import { RecordDetail } from "./RecordDetail";
import { RecordPerson } from "./RecordPerson";
import { Redirector } from "./Redirector";
import { SearchForm } from "./SearchForm";
import { ServiceBusTester } from "./ServiceBusTester";
import { ShareStory } from "./ShareStory";
import { Story } from "./Story";
import { StoryBook } from "./StoryBook";
import { Topic } from "./Topic";
import { TopicAuthority } from "./TopicAuthority";
import { Transcription } from "./Transcription";
import { Trees } from "./Trees";
import { User } from "./User";
import { UserCoupon } from "./UserCoupon";
import { UserMedia } from "./UserMedia";
import { UserOnBoarding } from "./UserOnBoarding";
import { Users } from "./Users";
import { UserViews } from "./UserViews";
import { Video } from "./Video";
import { Waitlist } from "./Waitlist";
import { Whitelist } from "./Whitelist";

const defaultOptions = {
  baseURL: REACT_APP_API
};

export default class API {
  public static readonly ActivationCodes = new ActivationCodes(defaultOptions);
  public static readonly Alexanders = new Alexanders(defaultOptions);
  public static readonly ContentCatalog = new ContentCatalog(defaultOptions);
  public static readonly ContentSearch = new ContentSearch(defaultOptions);
  public static readonly Contributor = new Contributor(defaultOptions);
  public static readonly CorrectiveActions = new CorrectiveActions(defaultOptions);
  public static readonly DeniedGedcom = new DeniedGedcom(defaultOptions);
  public static readonly FeatureFlag = new FeatureFlag(defaultOptions);
  public static readonly GiftOrder = new GiftOrder(defaultOptions);
  public static readonly GooglePhotos = new GooglePhotos(defaultOptions);
  public static readonly Group = new Group(defaultOptions);
  public static readonly GroupMedia = new GroupMedia(defaultOptions);
  public static readonly Hint = new Hint(defaultOptions);
  public static readonly HintGeneration = new HintGeneration(defaultOptions);
  public static readonly HintInspector = new HintInspector(defaultOptions);
  public static readonly HistoricalSearch = new HistoricalSearch(defaultOptions);
  public static readonly Media = new Media(defaultOptions);
  public static readonly Newspaper = new Newspaper(defaultOptions);
  public static readonly NewsPaperSearch = new NewsPaperSearch(defaultOptions);
  public static readonly PartnerHint = new PartnerHint(defaultOptions);
  public static readonly PersonMedia = new PersonMedia(defaultOptions);
  public static readonly Persons = new Persons(defaultOptions);
  public static readonly Pet = new Pet(defaultOptions);
  public static readonly PetMedia = new PetMedia(defaultOptions);
  public static readonly PlaceAuthority = new PlaceAuthority(defaultOptions);
  public static readonly PrintOrder = new PrintOrder(defaultOptions);
  public static readonly Project = new Project(defaultOptions);
  public static readonly QrCode = new QrCode(defaultOptions);
  public static readonly RecordCorrections = new RecordCorrections(defaultOptions);
  public static readonly RecordDetail = new RecordDetail(defaultOptions);
  public static readonly RecordPerson = new RecordPerson(defaultOptions);
  public static readonly Redirector = new Redirector(defaultOptions);
  public static readonly SearchForm = new SearchForm(defaultOptions);
  public static readonly ServiceBusTester = new ServiceBusTester(defaultOptions);
  public static readonly ShareStory = new ShareStory(defaultOptions);
  public static readonly Story = new Story(defaultOptions);
  public static readonly StoryBook = new StoryBook(defaultOptions);
  public static readonly Topic = new Topic(defaultOptions);
  public static readonly TopicAuthority = new TopicAuthority(defaultOptions);
  public static readonly Transcription = new Transcription(defaultOptions);
  public static readonly Trees = new Trees(defaultOptions);
  public static readonly User = new User(defaultOptions);
  public static readonly UserCoupon = new UserCoupon(defaultOptions);
  public static readonly UserMedia = new UserMedia(defaultOptions);
  public static readonly UserOnBoarding = new UserOnBoarding(defaultOptions);
  public static readonly Users = new Users(defaultOptions);
  public static readonly UserViews = new UserViews(defaultOptions);
  public static readonly Video = new Video(defaultOptions);
  public static readonly Waitlist = new Waitlist(defaultOptions);
  public static readonly Whitelist = new Whitelist(defaultOptions);
}
